import React, { ChangeEvent, SetStateAction, useEffect, useState } from 'react';
import BottomSheet from '../../../components/model/BottomSheet';
import closeIcon from '../../../assets/close-cta.svg';
import PrimaryButton from '../../../components-v2/buttons/PrimaryButton';

import './CourseDetailsModal.css';
import TextInput from '../../../components/textInput/TextInput';
import PhoneInput from '../../../components/phoneInput/PhoneInput';
import { Mixpanel } from '../../../analytics/Mixpanel';

interface CourseDetailsModalProps {
	showCourseDetailsModal: boolean;
	onHideCourseDetailsModal: () => void;
	handleDownloadClick: () => void;
	name: string;
	phone: string;
	courseName:string;
	setName: React.Dispatch<React.SetStateAction<string>>;
	setPhone: React.Dispatch<React.SetStateAction<string>>;
}

function CourseDetailsModal({
	showCourseDetailsModal,
	onHideCourseDetailsModal,
	handleDownloadClick,
	name,
	phone,
	setName,
	setPhone, courseName,
}: CourseDetailsModalProps) {
	const [isDisabled, setIsDisabled] = useState(true);

	const handleNameChange = (e: any) => {
		setName(e.target.value);
	};

	const onPhoneChange = (
		value: string,
		country: string,
		e: ChangeEvent<HTMLInputElement>,
		formattedValue: string
	) => {
		setPhone(value);
	};

	useEffect(() => {
		if (!name.length || !phone.length) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [name, phone]);

	const handleCtaClick = () => {
		Mixpanel.track('click_download_now_lead_magnet', {
			event_page: 'course_landing_page_bottom_sheet',
			name: name,
			phone: phone,
			course_name:courseName,
			event_action: 'clicked',
		});

		handleDownloadClick();
		onHideCourseDetailsModal();
	};

	const handleCrossClick = () => {
		Mixpanel.track('click_cross_button_lead_magnet', {
			event_page: 'course_landing_page_bottom_sheet',
			event_action: 'clicked',
		});

		onHideCourseDetailsModal();
	};

	return (
		<BottomSheet
			customStyle={{ height: 'max-content' }}
			show={showCourseDetailsModal}
			showHeader={false}
			title=''
			hideOnOverlayClick={false}
			onHide={onHideCourseDetailsModal}
			content={
				<div className='course-details-modal'>
					<img
						onClick={handleCrossClick}
						className='course-details-modal-close'
						src={closeIcon}
						alt=''
					/>
					<div className='course-details-modal-text'>
						<p className='course-details-modal-title'>
							Fill the details to claim your free meditation
						</p>
					</div>
					<p className='course-details-modal-input-label'>Full name</p>
					<TextInput
						placeholder='Enter your full name'
						value={name}
						onChange={(e) => handleNameChange(e)}
					/>
					<p className='course-details-modal-input-label'>Whatsapp Number</p>
					<PhoneInput
						phoneNumber={phone}
						defaultCountry='in'
						onPhoneChange={onPhoneChange}
					/>
					<PrimaryButton
						disabled={isDisabled}
						onClick={handleCtaClick}
						style={{
							width: '100%',
							marginBottom: '12px',
							marginTop: '54px',
							borderRadius: '8px',
							fontSize: '14px',
							height: '44px',
						}}>
						Download Now
					</PrimaryButton>
				</div>
			}
		/>
	);
}

export default CourseDetailsModal;
