import React, { CSSProperties, ReactNode, useEffect } from 'react';
import './Button.css';
import { Mixpanel } from '../../analytics/Mixpanel';

interface ButtonProps {
	onClick: () => void;
	label: ReactNode;
	style?: CSSProperties;
	disabled?: boolean;
	isSticky?: boolean;
	page?: string;
	plan?: any;
	phone?: any;
}

const Button: React.FC<ButtonProps> = ({
	onClick,
	label,
	style,
	disabled,
	isSticky,
	page,
	plan,
	phone,
}) => {
	const buttonStyle: CSSProperties = {
		display: 'flex',
		width: '250px',
		height: '50px',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '12px',
		fontFamily: 'Gordita-Bold',
		fontSize: '16px',
		background: disabled
			? '#D6D6D6'
			: 'linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%)',
		fontWeight: '700',
	};

	const combinedStyle: CSSProperties = {
		...buttonStyle,
		...style,
	};

	if (isSticky) {
		combinedStyle.position = 'fixed';
		combinedStyle.bottom = '10px';
		combinedStyle.left = '50%';
		combinedStyle.transform = 'translateX(-50%)';
	}

	useEffect(() => {
		if (page === 'payment_successful_screen' && phone && plan) {
			Mixpanel.track('view_continue_cta_payment_successful', {
				event_action: 'viewed',
				plan_duration: plan?.duration,
				phone: phone,
				event_page: 'payment_successful_screen',
			});
		}
	}, [page, phone, plan]);

	return (
		<button
			className={`old-button ${disabled ? 'disabled' : ''}`}
			disabled={disabled}
			onClick={onClick}
			style={combinedStyle}>
			<span className={'old-button-title'}>{label}</span>
		</button>
	);
};

export default Button;
