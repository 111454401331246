import React, { ReactNode } from 'react';
import './CustomComponent.css'; // Assume you have a CSS file for styling

interface CustomComponentProps {
    imageUrl: string;
    children: ReactNode;
    onClick?:()=>void;
    classname?:string;
    style?:React.CSSProperties;
}

const CustomComponent: React.FC<CustomComponentProps> = ({ imageUrl, children, onClick,classname,style }) => {
    const backgroundImageStyle: React.CSSProperties = {
        backgroundImage: `url(${imageUrl})`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius:'16px',
        color: '#fff',
        ...style,
    };

    return (
        <div onClick={onClick} className={`custom-component ${classname}`} style={backgroundImageStyle}>
            {children}
        </div>
    );
};

export default CustomComponent;
