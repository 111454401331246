import React, {memo, ReactElement} from 'react';
import './ReferralRow.css';

interface ReferralRowProps {
    icon: ReactElement;
    title: string;
}

const ReferralRow: React.FC<ReferralRowProps> = ({ icon, title }) => {
    return (
        <div className={"referral-row-points-container"}>
            {icon}
            <div className={"referral-row-points-value"}>{title}</div>
        </div>
    );
};

export default memo(ReferralRow);
