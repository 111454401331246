import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

interface TextInputProps {
	readOnly?: boolean;
	multiline?: boolean;
	value: string;
	onChange: (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	placeholder?: string;
	style?: React.CSSProperties;
	isMasked?: boolean;
	preventCopy?: boolean;
	onFocus?: (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	onBlur?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const TextInput: React.FC<TextInputProps> = ({
	preventCopy,
	isMasked,
	multiline = false,
	value,
	onChange,
	onFocus,
	onBlur,
	placeholder,
	style,
	readOnly = false,
}) => {
	const InputComponent = multiline ? 'textarea' : 'input';
	const [opacity, setOpacity] = useState<number>(0.5);

	useEffect(() => {
		if (value) {
			setOpacity(1);
		} else {
			setOpacity(0.5);
		}
	}, [value]);

	const handleChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		onChange(event);
	};

	const handlePaste = (event: any) => {
		if (preventCopy) {
			event.preventDefault(); // Prevent the default paste behavior
		}
	};

	return (
		<Form.Control
			className={`text-input`}
			as={InputComponent}
			type={isMasked ? 'password' : 'text'} // Use 'password' type when masked, 'text' otherwise
			value={value}
			onFocus={onFocus}
			onBlur={onBlur}
			readOnly={readOnly}
			onChange={handleChange}
			onPaste={handlePaste}
			placeholder={placeholder}
			style={{
				fontFamily: 'Gordita-Regular',
				fontWeight: '400',
				padding: '8px 16px',
				marginTop: '8px',
				fontSize: '16px',
				border: '1px solid #D6D6D6',
				borderRadius: '12px',
				height: '52px',
				width: '100%',
				color: '#1B1625',
				opacity: opacity,
				background: '#ffffff',
				...style, // merge with custom styles
			}}
		/>
	);
};

export default TextInput;
