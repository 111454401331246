import React, {forwardRef, Ref, useEffect, useImperativeHandle, useState} from 'react';
import '../../styles.css';
import './WeekRangeSelector.css'
import { ReactComponent as LeftArrow } from "../../assets/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../assets/right-slide.svg";
import dayjs from "dayjs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


interface WeekRangeSelectorProps {
    onDateChange:(parsedStartDate:string,parsedEndDate:string)=>void;
}

const WeekRangeSelector:React.FC<WeekRangeSelectorProps> = ({onDateChange}) => {
    const [today, setToday] = useState<any>(new Date());
    const [weekStartDate, setWeekStartDate] = useState<Date | null>(null);
    const [weekEndDate, setWeekEndDate] = useState<Date | null>(null);
    const [loader,setLoader] = useState(false);



    useEffect(() => {
        const currentDay = today.getDay();
        const diff = today.getDate() - currentDay + (currentDay === 0 ? -6 : 1);

        // Calculate the start date
        const startDate = new Date(today);
        startDate.setDate(diff);
        startDate.setHours(0, 0, 0, 0); // Set the start time to 5:30 AM

        // Reset today to the original value
        today.setDate(today.getDate() - 7);

        // Calculate the end date by adding 6 days to the start date
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        setWeekStartDate(startDate);
        setWeekEndDate(endDate);
    }, [today]);



    useEffect(() => {
            // Make API call or any other action when weekStartDate or weekEndDate changes

          //  console.log("weekStartDate",weekStartDate)
          // console.log("weekEndDate",weekEndDate);
          const daysDifference = dayjs(weekEndDate).diff(dayjs(weekStartDate), 'day');
           // console.log("daysDifference",daysDifference);
            if (weekStartDate && weekEndDate && weekStartDate < weekEndDate && daysDifference===6) {
                const formattedStartDate = weekStartDate.toLocaleDateString('en-GB'); // Adjust the locale as needed
                const formattedEndDate = weekEndDate.toLocaleDateString('en-GB');

                let [day, month, year] = formattedStartDate.split('/');

                let parsedStartDate = `${year}-${month}-${day}`;

                [day, month, year] = formattedEndDate.split('/');

                let parsedEndDate = `${year}-${month}-${day}`;

                // console.log("weekStartDate child",parsedStartDate)
                // console.log("weekEndDate child",parsedEndDate)

                onDateChange(parsedStartDate,parsedEndDate)

            }
    }, [weekStartDate, weekEndDate]);


    const formatDate = (date: Date | null) => {
        if (!date) return '';
        const options = { month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options as any);
    };

    const onPrevWeekClickHandler = () => {
        const prevWeekStartDate = new Date(weekStartDate!);
        prevWeekStartDate.setDate(weekStartDate!.getDate() - 7);

        const prevWeekEndDate = new Date(weekEndDate!);
        prevWeekEndDate.setDate(weekEndDate!.getDate() - 7);

        setToday(prevWeekStartDate);
        setWeekStartDate(prevWeekStartDate);
        setWeekEndDate(prevWeekEndDate);
    }

    const onNextWeekClickHandler = () => {
        const nextWeekStartDate = new Date(weekStartDate!);
        nextWeekStartDate.setDate(weekStartDate!.getDate() + 7);

        const nextWeekEndDate = new Date(weekEndDate!);
        nextWeekEndDate.setDate(weekEndDate!.getDate() + 7);

        // Check if next week's start date is in the future
        const now = new Date();

        if (nextWeekStartDate > now) {
            toast.warning('you can not go to future dates', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true,
            });
            return;
        }

        setToday(nextWeekStartDate);
        setWeekStartDate(nextWeekStartDate);
        setWeekEndDate(nextWeekEndDate);
    }

    return (
        <div style={{display:"flex",width:'100%',justifyContent:'center',flexDirection:'row',alignItems:'center',alignSelf:'center'}}>
                    <LeftArrow onClick={onPrevWeekClickHandler} width={32} height={32} />
                    <p style={{
                        color: '#1A1A1A',
                        fontFamily: 'Poppins-Medium',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                        textAlign: 'center',
                        marginTop: '16px',
                        alignSelf: 'center',
                    }}>{formatDate(weekStartDate)} - {formatDate(weekEndDate)}</p>
                    <RightArrow onClick={onNextWeekClickHandler} width={32} height={32} />
            <ToastContainer />
            </div>
    );
};

export default WeekRangeSelector;
