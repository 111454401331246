import React, { CSSProperties } from 'react';
import {
	CarouselProvider,
	Slider,
	Slide,
	ButtonBack,
	ButtonNext,
	CarouselContext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { LANDING_COMPONENT } from '../utils/utils';
import InstructorCard from '../components/slidersComponent/InstructorCard';
import { Mixpanel } from '../analytics/Mixpanel';
import { events_action } from '../analytics/Events';
import { ReactComponent as LeftDisabledArrow } from '../assets/new-icons/chevron-left-disabled.svg';
import { ReactComponent as LeftEnabledArrow } from '../assets/new-icons/chevron-left-enabled.svg';
import { ReactComponent as RightDisabledArrow } from '../assets/new-icons/chevron-right-disabled.svg';
import { ReactComponent as RightEnabledArrow } from '../assets/new-icons/chevron-right-enabled.svg';

interface CarouselItem {
	imageUrl: string;
	title?: string;
	subtitle?: string;
	description?: string;
}

interface CustomCarouselProps {
	items: CarouselItem[];
	component_name: string;
	naturalSlideWidth: number;
	naturalSlideHeight: number;
	style?: CSSProperties;
	width?: any;
	margin?: any;
}

interface CustomCarouselState {
	showReadMore: boolean;
	selectedImageIndex: number;
}

class CustomCarousel extends React.Component<
	CustomCarouselProps,
	CustomCarouselState
> {
	constructor(props: CustomCarouselProps) {
		super(props);
		this.state = {
			selectedImageIndex: 0,
			showReadMore: false,
		};
	}

	onNextClick = () => {
		const { selectedImageIndex } = this.state;
		const { items } = this.props;
		const nextIndex =
			selectedImageIndex < items.length - 1
				? selectedImageIndex + 1
				: selectedImageIndex;

		this.setState({ selectedImageIndex: nextIndex }, () => {
			let event_section = '';
			if (this.props.component_name === LANDING_COMPONENT.HABIT) {
				event_section = 'Make Mindfulness a Habit.';
			} else if (this.props.component_name === LANDING_COMPONENT.INSTRUCTOR) {
				event_section = 'Learn from the Best.';
			} else if (this.props.component_name === LANDING_COMPONENT.INSTRUCTIONS) {
				event_section = 'Useful Instructions.';
			} else if (this.props.component_name === LANDING_COMPONENT.TESTIMONIAL) {
				event_section = 'What do they think?';
			} else if (this.props.component_name === LANDING_COMPONENT.MINDFULNESS) {
				event_section = 'Mindful and Happier Everyday!';
			}

			Mixpanel.track('scroll_section', {
				event_page: 'landing_page',
				event_section: event_section,
				event_action: events_action.clicked,
				scroll_direction: 'right',
			});
		});
	};

	onBackClick = () => {
		const { selectedImageIndex } = this.state;
		const { items } = this.props;
		const prevIndex =
			selectedImageIndex > 0 ? selectedImageIndex - 1 : selectedImageIndex;

		this.setState({ selectedImageIndex: prevIndex }, () => {
			let event_section = '';
			if (this.props.component_name === LANDING_COMPONENT.HABIT) {
				event_section = 'Make Mindfulness a Habit.';
			} else if (this.props.component_name === LANDING_COMPONENT.INSTRUCTOR) {
				event_section = 'Learn from the Best.';
			} else if (this.props.component_name === LANDING_COMPONENT.INSTRUCTIONS) {
				event_section = 'Useful Instructions.';
			} else if (this.props.component_name === LANDING_COMPONENT.TESTIMONIAL) {
				event_section = 'What do they think?';
			} else if (this.props.component_name === LANDING_COMPONENT.MINDFULNESS) {
				event_section = 'Mindful and Happier Everyday!';
			}

			Mixpanel.track('scroll_section', {
				event_page: 'landing_page',
				event_section: event_section,
				event_action: events_action.clicked,
				scroll_direction: 'left',
			});
		});
	};

	readMoreHandler = (readValue: boolean) => {
		this.setState((prevState: any) => ({ showReadMore: readValue }));
	};

	render() {
		const { items, component_name, naturalSlideWidth, naturalSlideHeight } =
			this.props;
		const { selectedImageIndex } = this.state;
		const mergedStyles: CSSProperties = {
			width: '375px',
			...this.props?.style,
			height: this.state.showReadMore ? '512px' : '444px',
		};

		return (
			<CarouselProvider
				naturalSlideWidth={naturalSlideWidth}
				naturalSlideHeight={
					this.state.showReadMore
						? naturalSlideHeight + 102
						: naturalSlideHeight
				}
				totalSlides={items.length}
				visibleSlides={1.14}>
				<CarouselContext.Consumer>
					{(context) => {
						context.subscribe(() =>
							this.setState({ selectedImageIndex: context.state.currentSlide })
						);
						return (
							<Slider style={mergedStyles}>
								{items.map((item, index) => (
									<Slide
										style={{
											border: 'none',
											borderRadius:
												component_name === LANDING_COMPONENT.TESTIMONIAL
													? '16px'
													: '0',
											width: this.props.width,
											marginLeft: index === 0 ? this.props.margin : '0px',
											marginRight:
												index === items.length - 1 ? '0px' : this.props.margin,
										}}
										key={index}
										index={index}>
										{component_name === LANDING_COMPONENT.INSTRUCTOR && (
											<InstructorCard
												readMoreHandler={this.readMoreHandler}
												imageUrl={item.imageUrl}
												name={item.title}
												expertise={item.subtitle}
												description={item.description}
											/>
										)}
									</Slide>
								))}
							</Slider>
						);
					}}
				</CarouselContext.Consumer>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						marginTop: '16px',
						alignItems: 'center',
					}}>
					<ButtonBack
						onClick={this.onBackClick}
						style={{
							width: '48px',
							marginRight: '12px',
							height: '48px',
							display: 'flex',
							border:
								selectedImageIndex === 0
									? '1px solid #D6D6D6'
									: '1px solid #4462EF',
							justifyContent: 'center',
							alignItems: 'center',
							borderRadius: '8px',
							backgroundColor: '#F4F4F4',
							// color: component_name === LANDING_COMPONENT.MINDFULNESS ? 'white' : 'blue',
							// border: component_name === LANDING_COMPONENT.MINDFULNESS ? '1px solid #fff' : '1px solid #534DE0'
						}}>
						{selectedImageIndex === 0 ? (
							<LeftDisabledArrow width={24} height={24} />
						) : (
							<LeftEnabledArrow width={24} height={24} />
						)}
					</ButtonBack>
					<ButtonNext
						onClick={this.onNextClick}
						style={{
							width: '48px',
							marginLeft: '12px',
							height: '48px',
							display: 'flex',
							border:
								selectedImageIndex >= 2.86
									? '1px solid #D6D6D6'
									: '1px solid #4462EF',
							justifyContent: 'center',
							alignItems: 'center',
							borderRadius: '8px',
							backgroundColor: '#F4F4F4',
						}}>
						{selectedImageIndex >= 2.86 ? (
							<RightDisabledArrow width={24} height={24} />
						) : (
							<RightEnabledArrow width={24} height={24} />
						)}
					</ButtonNext>
				</div>
			</CarouselProvider>
		);
	}
}

export default CustomCarousel;
