import React, {useEffect} from 'react'
import BottomNavigation from "../../components-v2/bottom-nav/BottomNavigation";
import useAuthenticatedUsers from "../../hooks/useAuthenticatedUsers";
import {getBetaUsers} from "../../services/Firebase";
import {updateBetaUsers} from "../../store/features/BetaUsers";
import {useDispatch} from "react-redux";

function HomeContainer() {
    const dispatch = useDispatch();
    useEffect(() => {
        getBetaUsers().then((result: any) => {
            dispatch(updateBetaUsers(result.data));
        }).catch((err: any) => {
        });
    }, []);

    useAuthenticatedUsers(false)
    return <BottomNavigation/>
}

export default HomeContainer
