import React, {useEffect} from 'react';
import { Container } from 'react-bootstrap';
import './ExperienceDetailsPage.css'
import Button from "../../../components/button/Button";
import {ReactComponent as BackArrow} from "../../../assets/left_arrow_black.svg";
import signup_ques_zero from "../../../assets/webp-images/signup_ques_zero.webp"
interface ExperienceDetailsPageProps {
    onExperienceDetailsPageContinueHandler:() =>void;
    onBackArrowClick:() =>void;
}
const ExperienceDetailsPage:React.FC<ExperienceDetailsPageProps> = ({onExperienceDetailsPageContinueHandler,onBackArrowClick}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container className={'exp-page-container'}>
            <div className={'exp-header-container'}>
                <BackArrow onClick={onBackArrowClick} width={48} height={48} />
                <div/>
                {/*<SeekLogo style={{ alignSelf: 'center' }} width={108} height={64} />*/}
                <div/>
            </div>
            <p className={'exp-title'}>We understand your needs.</p>
            <img src={signup_ques_zero} alt="Your Image" style={{ width: '240px', height: '240px',marginTop:'24px'}} />
            <div style={{position:'relative',display:'flex',flexDirection:'column',marginTop:'18px'}}>
                <span className={'exp-subtitle'}><span>10,000+</span> people have achieved their goals and are living a more peaceful life with Seek!</span>
                <svg style={{position:'absolute',top:'-12px'}} xmlns="http://www.w3.org/2000/svg" width="76" height="45" viewBox="0 0 76 45" fill="none">
                    <g>
                        <path d="M42.7805 10.3774H49.8166C49.635 10.3226 49.1622 10.178 48.6893 10.0333C48.7274 9.90816 48.7705 9.78304 48.8086 9.66183C53.4659 10.569 58.1421 11.3979 62.761 12.4106C65.0013 12.9032 67.1794 13.6461 69.2858 14.4437C73.2503 15.9491 74.7646 18.8737 75.5479 22.1503C75.6911 22.7485 75.7627 23.3585 75.8631 23.9645C76.7037 28.9302 73.6373 31.4366 67.6569 34.498C67.2795 34.6896 66.7877 34.7443 66.4484 34.9633C61.0415 38.4862 54.5117 39.4246 47.9776 40.2926C36.9531 41.7549 26.1534 40.9807 15.7117 37.6847C12.6165 36.7072 9.56908 35.5498 6.70313 34.189C2.3469 32.1285 0.441055 28.723 0.0732579 24.7348C-0.609792 17.3176 3.50282 11.4995 11.6087 8.12914C16.9776 5.90044 22.7238 4.83302 28.6707 4.52413C34.4215 4.22697 40.2012 3.90244 45.9523 4.02756C52.0663 4.1605 58.1276 4.97769 63.7783 7.09299C64.5666 7.38623 65.173 8.00011 65.6461 8.67653C61.9488 8.22687 58.2614 7.74596 54.5597 7.3354C52.7015 7.12818 50.8196 7.00306 48.9471 6.92094C39.8956 6.52213 30.9442 7.08125 22.1983 9.10271C19.2512 9.78304 16.3423 10.6902 13.5957 11.7967C6.79388 14.5337 3.10161 20.1875 3.88018 26.2402C4.13334 28.1912 5.21285 29.7082 7.31932 30.6974C17.5269 35.4951 28.5034 37.5399 40.2442 36.8713C46.1577 36.5351 52.0281 36.0033 57.6358 34.2907C61.8534 33.0006 65.8658 31.4092 69.262 28.9342C72.9779 26.2245 73.7136 23.1552 71.4162 19.5111C70.9861 18.8268 70.1789 18.2403 69.3909 17.779C66.3576 15.9921 62.6367 15.4486 59.3361 14.1857C54.3016 12.262 48.8706 12.0313 43.6261 11.0186C43.3156 10.96 43.029 10.8192 42.7328 10.7175C42.747 10.6041 42.7662 10.4908 42.7805 10.3774Z" fill="#FAA53D"/>
                    </g>
                </svg>
            </div>
            {/*<img src={description} alt="Your Image" style={{ width: '100%', maxWidth:'327px', height: 'auto',marginTop:'24px',marginBottom:'12px'}} />*/}
            <Button isSticky={true} style={{ alignSelf: 'center', marginBottom:'24px' }} onClick={onExperienceDetailsPageContinueHandler} label={'Continue'}></Button>
        </Container>
    );
};

export default ExperienceDetailsPage;
