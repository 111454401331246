import {createContext, useContext, useEffect, useState} from "react";
import {auth} from "../services/Firebase";

const LoginContext = createContext({
    user: {
        // email:'',
        displayName:'',
        firstName:'',
        description:'',
        helpQuestion:'',
        homeworkPreCount:'',
        id:'',
        isNewUser:'',
        isOnboarded:'',
        lastName:'',
        name:'',
        phone:'',
        photo:'',
        token:'',
        userType:'',
    },
    isLoggedIn: false,
    login: (user:any) => {},
    logout: () => {},
});

export const LoginProvider = ({children}:any) =>{
    const [user,setUser] = useState<any>(null);
    const [isLoggedIn,setIsLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    const login = (user:any) =>{
        // console.log("login context",user)
        setUser(user);
        setIsLoggedIn(true)
    }

    const logout = () =>{
        // console.log("logout context")
        setIsLoggedIn(false);
        setUser(null);
    }

    return (
        <LoginContext.Provider value={{user,isLoggedIn,login,logout}}>
            {children}
        </LoginContext.Provider>
    )
}

export const useLogin = () =>{
    return useContext(LoginContext);
}
