import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	utm_source: null,
	utm_medium: null,
	utm_campaign: null,
	utm_term: null,
	utm_content: null,
};

export const UtmSlice = createSlice({
	name: 'utmSlice',
	initialState,
	reducers: {
		setUtmDetails: (state, action) => {
			console.log('payload', action.payload);
			if (!state.utm_campaign) {
				state.utm_source = action.payload.utm_source;
				state.utm_medium = action.payload.utm_medium;
				state.utm_campaign = action.payload.utm_campaign;
				state.utm_term = action.payload.utm_term;
				state.utm_content = action.payload.utm_content;
			}
		},
	},
});

export const { setUtmDetails } = UtmSlice.actions;

export default UtmSlice.reducer;
