import React, { memo, Suspense, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addUsers } from '../../store/features/AuthSlice';
import '../home/components/AccountCreated.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { getUpcomingCourses, getUserByUserId } from '../../services/Firebase';
import { useNavigate } from 'react-router-dom';
import './CourseContainer.css';
import seek_logo_url from '../../assets/new-icons/logo-white.svg';
import { isEmpty } from '../../utils/utils';
import { Mixpanel } from '../../analytics/Mixpanel';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import Header from '../../components-v2/header/Header';
import ShimmerLoader from '../../components-v2/shimmer/Shimmer';
import moment from 'moment-timezone';
import {
	setForceRefreshOnCoursePurchase,
	setUpcomingCourses,
} from '../../store/features/UpcomingClassesSlice';
import divider from '../../assets/courses-divider.png';
import CourseCover from './components/CourseCover';
import AppDownloadNudge from "../../components-v2/app-download-nudge/AppDownloadNudge";


const CourseContainer = memo(() => {
	const userData = useAuthenticatedUsers(false);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector((state: any) => state?.auth?.user?.data);
	const upcomingCourses = useSelector(
		(state: any) => state.upcomingClasses?.upcomingCourse?.data
	);

	const force_refresh_on_course_purchase = useSelector(
		(state: any) => state.upcomingClasses?.force_refresh_on_course_purchase
	); // Assuming upcomingClasses state is stored in Redux

	useEffect(() => {
		if (isEmpty(user) && userData?.uid) {
			getUserByUserId(userData?.uid).then((userLoginResult) => {
				dispatch(addUsers(userLoginResult?.userData));
				Mixpanel.identify(userLoginResult?.userData?.uid);
			});
		}
	}, [userData, user]);

	useEffect(() => {
		if (
			(isEmpty(upcomingCourses) || force_refresh_on_course_purchase) &&
			(userData?.uid || user.uid)
		) {
			// setLoader(true)
			getUpcomingCourses(userData?.uid)
				.then((result: any) => {
					dispatch(setUpcomingCourses(result.data));
					dispatch(setForceRefreshOnCoursePurchase(false));
				})
				.catch((err: any) => {
					console.log('getUpcomingClasses err', err);
				});
		}
	}, [userData?.uid, user.uid, force_refresh_on_course_purchase]);

	const onKarmaPointsClickHandler = () => {
		Mixpanel.track('click_karma_points_badge', {
			event_action: 'clicked',
			context_user_id: user?.uid,
			total_karma_points: user?.total_karma || 0,
			event_page: 'home_screen',
		});
		navigate('/karma', {
			state: { email: user?.email, total_karma: user?.total_karma },
			replace: false,
		});
	};

	const onCourseClickHandler = (courseItem: any) => {
		Mixpanel.track('click_upcoming_course', {
			event_action: 'clicked',
			course_name: courseItem?.title || upcomingCourses[0]?.title,
			course_date: moment(
				new Date(
					upcomingCourses[0]?.streaming_date._seconds * 1000 +
						upcomingCourses[0]?.streaming_date._nanoseconds / 1000000
				)
			).format('DD MMM YYYY'),
			event_page: 'courses',
		});
		navigate(`/upcoming-course/${courseItem?.courseId}`, {
			state: {
				courseItem,
				user,
			},
		});
	};

	return (
		<div className={'course-list-container'}>
			<div className='dark-background-image' />
			<Header
				onClick={onKarmaPointsClickHandler}
				logoUrl={seek_logo_url}
				karma_points={user?.total_karma}
				isDark
			/>
			<p className='course-list-title'>
				Hi <span>Seekers</span>,
			</p>
			<p className='course-list-subtitle'>
				In-depth learning, created by experts for you
			</p>
			<img className='course-list-divider' src={divider} alt='' />
			<Suspense fallback={<ShimmerLoader isDark />}>
				{upcomingCourses?.length > 0 ? (
					upcomingCourses?.map((course: any, index: number) => (
						<CourseCover
							course={course}
							onClick={() => onCourseClickHandler(course)}
						/>
					))
				) : (
					<>
						<ShimmerLoader isDark style={{ height: '350px' }} />
						<ShimmerLoader isDark style={{ height: '350px' }} />
					</>
				)}
			</Suspense>
			<AppDownloadNudge/>
		</div>
	);
});

export default CourseContainer;
