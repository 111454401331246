import React, {useEffect, useState} from 'react'
import './AccountCreated.css'
import accountCreatedGif from "../../../assets/animations/AccountCreated.gif";
import {Mixpanel} from "../../../analytics/Mixpanel";
import {events, events_action, events_page} from "../../../analytics/Events";
import {useLogin} from "../../../context/LoginContext";
import {useNavigate} from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import {ToastContainer} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {updateEventsStatus} from "../../../store/features/EventsSlices";
import {isMobile} from "react-device-detect";
interface AccountCreatedProps {
    onProceed: (isUserPartOfEnrolledCourse: boolean) => void
}

export enum STATES {
    ACCOUNT_CREATED,
}

const AccountCreated: React.FC<AccountCreatedProps> = ({onProceed}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [state, setState] = useState<STATES>(STATES.ACCOUNT_CREATED);

    const {user} = useLogin()
    // console.log("user",user);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const dispatch = useDispatch();
    const view_account_created_screen = useSelector((state: any) => state?.events?.eventsLog?.view_account_created_screen);
    useEffect(() => {
        if (!view_account_created_screen) {
            Mixpanel.track(events.view_account_created_screen, {
                event_page: events_page.account_created,
                event_action: events_action.viewed,
            })
            dispatch(updateEventsStatus({key:'view_account_created_screen',value:true}))
        }
    }, [view_account_created_screen])
    useEffect(() => {
        setTimeout(() => proceedHandler(), 4000);
    }, []);
    const proceedHandler = () => {
        try {
            Mixpanel.track(events.click_choose_your_plan_from_account_created, {
                event_page: events_page.account_created,
                event_action: 'redirect',
            })
        } catch (e) {
        }
        navigate('/plan',{state:{screenName:'AccountCreated',user:user},replace:false});
    }

    return (
        <div style={{display:'flex',alignSelf:'center',alignItems:'center',justifyContent:'center',marginTop:isMobile?'45%':'8%'}}>
            {loader && <Loader/>}
            {state === STATES.ACCOUNT_CREATED && <div className={'account-created-container'}>
                <img src={accountCreatedGif} style={{alignSelf: 'center', width: 84, height: 80}}
                     alt="Landing Banner"/>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '343px',
                    alignSelf: 'center',
                    marginTop: '24px',
                }}>
                    <span className={'account-title'}>Welcome aboard! 🎉 </span>
                </div>
                <span className={'account-description'}> Your account has been created successfully </span>
                {/*<Button isSticky={true} style={{*/}
                {/*    marginTop: '32px',*/}
                {/*    marginBottom: '32px',*/}
                {/*    alignSelf: 'center',*/}
                {/*    backgroundColor: COLORS.PRIMARY*/}
                {/*}} onClick={proceedHandler} label={'Choose your plan'}/>*/}
            </div>}
            {/*{state === STATES.HOW_TO_JOIN_LIVE_CLASS && <HowToJoinLiveClass parent={'AccountCreated'} continueHandler={continueHandler}/>}*/}
            <ToastContainer/>
        </div>
    )
}

export default AccountCreated
