import React from 'react';

interface TestimonialCardProps {
    imageUrl: string;
    name?: string;
    place?: string;
    description?: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ imageUrl,name,place,description }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'flex-start', paddingLeft:'16px',paddingRight:'12px',  borderRadius: '12px',flexDirection:'column' }}>
            {/* Circular Profile Image */}
            <div style={{ width: '56px', height: '56px', borderRadius: '50%', overflow: 'hidden', marginTop: '16px' }}>
                <img src={imageUrl} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
            <div style={{  color: '#4A4A4A',
                textAlign: 'center',
                fontFamily: 'Gordita-Medium',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 500,
                marginTop:'16px',
                lineHeight: 'normal' }}>{name}</div>
            <div style={{  color: '#A4A4A4',
                textAlign: 'center',
                fontFamily: 'Gordita-Regular',
                fontSize: '14px',
                fontStyle: 'italic',
                fontWeight: 400,
                marginTop:'8px',
                lineHeight: 'normal' }}>{place}</div>
            <div style={{ color: '#212121',
                fontFamily: 'Gordita-Light',
                fontSize: '14px',
                marginTop:'12px',
                textAlign:'left',
                fontStyle: 'italic',
                fontWeight: 300,
                lineHeight: '20px' }}>{`“${description}”`}</div>
        </div>
    );
};

export default TestimonialCard;
