import React, {useEffect, useState} from 'react'
import {ReactComponent as SeekLogo} from "../../assets/seek-logo.svg";
import {ReactComponent as SpritualMastersHeading} from "../../assets/new-icons/spritual-masters-heading.svg";
import research_banner from '../../assets/webp-images/poc-reasearch-banner.webp';
import {ReactComponent as HealYourself} from "../../assets/new-icons/heal-yourself.svg";
import './PocLandingPage.css'
import {useNavigate} from "react-router-dom";
import {Mixpanel} from "../../analytics/Mixpanel";
import {
    CounsellingTechniques,
    getRandomDarkColor,
    getRandomLightColor, insertEmptyObjects,
    InstructorCarouselItems, seekBackedInByImages, STREAMING_PLAN_RECURRENCE_TYPE,
    TestimonialCounsellingItems
} from "../../utils/utils";
import BottomContainer from "../../components-v2/buttons/BottomContainer";
import PrimaryButton from "../../components-v2/buttons/PrimaryButton";
import SeekGurusCard from "../../components-v2/seek-gurus/SeekGurusCard";
import AnimatedText from "../../components-v2/animated-text/AnimatedText";
import PocForm from "./PocForm";
import BackButton from "../../components-v2/buttons/BackButton";
import PocCheckout from "./PocCheckout";
import moment from "moment-timezone";
import {toast, ToastContainer} from "react-toastify";
import {
    enrollUserForPocCounselling,
} from "../../services/Firebase";
import {COLORS} from "../../utils/colors";
import Modal from "../../components/model/Model";
import {ReactComponent as PaymentSuccess} from "../../assets/payment-success.svg";
import {ReactComponent as PaymentFailed} from "../../assets/alert.svg";
import Button from "../../components/button/Button";
import PocBookSlotPage from "./PocBookSlotPage";
import {useSelector} from "react-redux";
import ImageSlider from "./ImageSlider";


interface ProfileCardProps {
    comment: string;
    imageUrl: string;
    name: string;
    date: string;
    index: number;
}

const ProfileCard: React.FC<ProfileCardProps> = ({comment, imageUrl, name, date, index}) => {
    const firstChar = name.charAt(0).toUpperCase();
    return (
        <div className="testimonial-profile-card">
            <div className="testimonial-profile-top-half">
                <span className={'testimonial-profile-comment-content'}>{comment}
                    <svg style={{position:'absolute'}} xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                         viewBox="0 0 40 40" fill="none">
                        <path
                            d="M8.25943 20.44C3.32515 20.44 0 24.4109 0 30.2945C0 35.5891 3.82172 40 8.998 40C14.6677 40 18.8571 34.7055 18.8571 26.6182C18.8571 8.23636 7.27257 0.883636 0 0V8.08727C4.93114 9.11636 10.4751 14.8509 10.7234 21.1782C10.4751 21.0327 9.49143 20.44 8.25943 20.44ZM29.3991 20.44C24.4711 20.44 21.1429 24.4109 21.1429 30.2945C21.1429 35.5891 24.9646 40 30.1409 40C35.8106 40 40 34.7055 40 26.6182C40 8.23636 28.4154 0.883636 21.1429 0V8.08727C26.074 9.11636 31.618 14.8509 31.8663 21.1782C31.618 21.0327 30.6343 20.44 29.3991 20.44Z"
                            fill="#F4F2FF"/>
                    </svg>
                </span>
            </div>
            <div className="testimonial-profile-bottom-half">
                <div style={{display: 'flex', alignSelf: 'start'}}>
                    <div style={{
                        display: 'flex',
                        width: '42px',
                        height: '40px',
                        borderRadius: '12px',
                        backgroundColor: getRandomLightColor(index),
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                <span style={{
                    color: getRandomDarkColor(index),
                    textAlign: 'center',
                    fontFamily: 'Manrope',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: '24px',
                }}>{firstChar}</span>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '84%',
                        marginLeft: '8px',
                        alignItems: 'center',
                        alignSelf: "center"
                    }}>
                        <span className="testimonial-profile-name">{name}</span>
                        <span className="testimonial-profile-date">{date}</span>
                    </div>
                </div>

                <div className="testimonial-profile-rating">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M7.84367 1.9593C8.00698 1.45669 8.71803 1.45669 8.88134 1.9593L9.95475 5.26291C10.0278 5.48769 10.2372 5.63987 10.4736 5.63987L13.9472 5.63987C14.4757 5.63987 14.6954 6.31612 14.2679 6.62675L11.4576 8.6685C11.2664 8.80742 11.1864 9.05365 11.2595 9.27843L12.3329 12.582C12.4962 13.0846 11.9209 13.5026 11.4934 13.192L8.68316 11.1502C8.49196 11.0113 8.23305 11.0113 8.04185 11.1502L5.23162 13.192C4.80408 13.5026 4.22882 13.0846 4.39213 12.582L5.46554 9.27843C5.53857 9.05365 5.45857 8.80742 5.26736 8.6685L2.45714 6.62675C2.0296 6.31612 2.24932 5.63987 2.7778 5.63987L6.25142 5.63987C6.48776 5.63987 6.69723 5.48769 6.77026 5.26291L7.84367 1.9593Z"
                            fill="#EF9E3A"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M7.84367 1.9593C8.00698 1.45669 8.71803 1.45669 8.88134 1.9593L9.95475 5.26291C10.0278 5.48769 10.2372 5.63987 10.4736 5.63987L13.9472 5.63987C14.4757 5.63987 14.6954 6.31612 14.2679 6.62675L11.4576 8.6685C11.2664 8.80742 11.1864 9.05365 11.2595 9.27843L12.3329 12.582C12.4962 13.0846 11.9209 13.5026 11.4934 13.192L8.68316 11.1502C8.49196 11.0113 8.23305 11.0113 8.04185 11.1502L5.23162 13.192C4.80408 13.5026 4.22882 13.0846 4.39213 12.582L5.46554 9.27843C5.53857 9.05365 5.45857 8.80742 5.26736 8.6685L2.45714 6.62675C2.0296 6.31612 2.24932 5.63987 2.7778 5.63987L6.25142 5.63987C6.48776 5.63987 6.69723 5.48769 6.77026 5.26291L7.84367 1.9593Z"
                            fill="#EF9E3A"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M7.84367 1.9593C8.00698 1.45669 8.71803 1.45669 8.88134 1.9593L9.95475 5.26291C10.0278 5.48769 10.2372 5.63987 10.4736 5.63987L13.9472 5.63987C14.4757 5.63987 14.6954 6.31612 14.2679 6.62675L11.4576 8.6685C11.2664 8.80742 11.1864 9.05365 11.2595 9.27843L12.3329 12.582C12.4962 13.0846 11.9209 13.5026 11.4934 13.192L8.68316 11.1502C8.49196 11.0113 8.23305 11.0113 8.04185 11.1502L5.23162 13.192C4.80408 13.5026 4.22882 13.0846 4.39213 12.582L5.46554 9.27843C5.53857 9.05365 5.45857 8.80742 5.26736 8.6685L2.45714 6.62675C2.0296 6.31612 2.24932 5.63987 2.7778 5.63987L6.25142 5.63987C6.48776 5.63987 6.69723 5.48769 6.77026 5.26291L7.84367 1.9593Z"
                            fill="#EF9E3A"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M7.84367 1.9593C8.00698 1.45669 8.71803 1.45669 8.88134 1.9593L9.95475 5.26291C10.0278 5.48769 10.2372 5.63987 10.4736 5.63987L13.9472 5.63987C14.4757 5.63987 14.6954 6.31612 14.2679 6.62675L11.4576 8.6685C11.2664 8.80742 11.1864 9.05365 11.2595 9.27843L12.3329 12.582C12.4962 13.0846 11.9209 13.5026 11.4934 13.192L8.68316 11.1502C8.49196 11.0113 8.23305 11.0113 8.04185 11.1502L5.23162 13.192C4.80408 13.5026 4.22882 13.0846 4.39213 12.582L5.46554 9.27843C5.53857 9.05365 5.45857 8.80742 5.26736 8.6685L2.45714 6.62675C2.0296 6.31612 2.24932 5.63987 2.7778 5.63987L6.25142 5.63987C6.48776 5.63987 6.69723 5.48769 6.77026 5.26291L7.84367 1.9593Z"
                            fill="#EF9E3A"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M7.84367 1.9593C8.00698 1.45669 8.71803 1.45669 8.88134 1.9593L9.95475 5.26291C10.0278 5.48769 10.2372 5.63987 10.4736 5.63987L13.9472 5.63987C14.4757 5.63987 14.6954 6.31612 14.2679 6.62675L11.4576 8.6685C11.2664 8.80742 11.1864 9.05365 11.2595 9.27843L12.3329 12.582C12.4962 13.0846 11.9209 13.5026 11.4934 13.192L8.68316 11.1502C8.49196 11.0113 8.23305 11.0113 8.04185 11.1502L5.23162 13.192C4.80408 13.5026 4.22882 13.0846 4.39213 12.582L5.46554 9.27843C5.53857 9.05365 5.45857 8.80742 5.26736 8.6685L2.45714 6.62675C2.0296 6.31612 2.24932 5.63987 2.7778 5.63987L6.25142 5.63987C6.48776 5.63987 6.69723 5.48769 6.77026 5.26291L7.84367 1.9593Z"
                            fill="#EF9E3A"/>
                    </svg>
                </div>
            </div>
        </div>
    );
};

interface TriggeredEvents {
    [key: string]: boolean;
}

const sectionRanges = {
    // section1: {start: 0, end: 400, section_heading: 'Guided by the Best.'},
    section2: {start: 100, end: 200, section_heading: 'Heal your Anger'},
    section3: {start: 200, end: 300, section_heading: 'Research Adapted By'},
    section4: {start: 300, end: 600, section_heading: 'Spiritual Masters at your Finger Tips'},
    section5: {start: 600, end: 1200, section_heading: 'Customer testimonials'},
    section8: {start: 1200, end: 1500, section_heading: 'Backed By'},
    section6: {start: 1500, end: 1700, section_heading: 'Heal Yourself'},
    // Add other sections as needed
} as { [key: string]: { start: number; end: number; section_heading: string } };


export enum STATES {
    POC_LANDING_PAGE,
    POC_FORM_PAGE,
    POC_CHECKOUT_PAGE,
    POC_BOOK_SLOT_PAGE
}


function PocLandingPage() {

    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [state, setState] = useState<STATES>(STATES.POC_LANDING_PAGE);
    const [paymentSuccessPopup, setPaymentSuccessPopup] = useState(false);
    const [paymentFailedPopup, setPaymentFailedPopup] = useState(false);
    const [pocCounsellingTimeWindow, setPocCounsellingTimeWindow] = useState(true);
    const [counsellorNumber,setCounsellorNumber] = useState(null);

    const fullName = useSelector((state: any) => state.pocFormReducer?.pocForm?.fullName);
    const whatDoYouWantToHeal = useSelector((state: any) => state.pocFormReducer?.pocForm?.whatDoYouWantToHeal);
    const phone = useSelector((state: any) => state.pocFormReducer?.pocForm?.phone);
    const selectedTechniqueOptionIndex = useSelector((state: any) => state.pocFormReducer?.pocForm?.selectedTechniqueOptionIndex);
    const timeSlot = useSelector((state: any) => state.pocFormReducer?.pocForm?.selectedTime);
    const selectedDateOptionIndex = useSelector((state: any) => state.pocFormReducer?.pocForm?.selectedDateOptionIndex);

    // console.log("poc Landing page", fullName, whatDoYouWantToHeal, phone, CounsellingTechniques[selectedTechniqueOptionIndex - 1], timeSlot, selectedDateOptionIndex === 1 ? moment().toString() : moment().add(1, 'days').toString())


    const [triggeredEvents, setTriggeredEvents] = useState<TriggeredEvents>({
        section1: false,
        section2: false,
        section3: false,
        section4: false,
        section5: false,
        section6: false,
    });


    const markMixPanelEvent = (position: number) => {
        const checkAndTriggerEvent = (sectionKey: string, start: number, end: number) => {
            if (!triggeredEvents[sectionKey] && position >= start - 10 && position <= end + 10) {
                Mixpanel.track('view_counselling_landing_page_sections', {
                    event_action: 'viewed',
                    event_section: sectionKey,
                    section_heading: sectionRanges[sectionKey].section_heading,
                    event_page: 'counselling_landing_page',
                });
                setTriggeredEvents((prevEvents) => ({...prevEvents, [sectionKey]: true}));
            }
        };

        Object.entries(sectionRanges).forEach(([sectionKey, {start, end}]) => {
            checkAndTriggerEvent(sectionKey, start, end);
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            console.log("window.scrollY",window.scrollY);
            markMixPanelEvent(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [triggeredEvents]);


    useEffect(() => {
        Mixpanel.track('view_counselling_landing_page', {
            event_action: 'viewed',
            event_page: 'counselling_landing_page',
        });
        // (async () => {
        //     try {
        //         const pocCounsellingTimeWindow = await getPocCounsellingTimeWindow();
        //         setPocCounsellingTimeWindow(pocCounsellingTimeWindow?.isCounsellingOpen)
        //     } catch (error) {
        //         console.error("Error fetching YouTube link:", error);
        //     }
        // })();
    }, [])




    function loadScript(src: any) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }


    async function displayRazorpay(price: number) {
        setPaymentFailedPopup(false)
        // console.log("displayRazorpay", fullName, whatDoYouWantToHeal, phone,date,timeSlot,selectedTechnique)

        Mixpanel.track('click_pay_now', {
            event_action: 'clicked',
            event_page: 'counselling_checkout_page',
        });


        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            toast.error(`Razorpay SDK failed to load. Are you online?`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true,
            });
            return;
        }

        // price = 1;
        // price = parseFloat(price);

        enrollUserForPocCounselling({
            phone,
            fullName,
            price,
            whatDoYouWantToHeal,
            selectedTechnique: CounsellingTechniques[selectedTechniqueOptionIndex - 1],
            date: selectedDateOptionIndex === 1 ? moment().toString() : moment().add(1, 'days').toString(),
            timeSlot
        }).then((bookedSlot) => {
            // console.log("enrollUserForPocCounselling bookSlot", bookedSlot?.counsellorNumber);
            setCounsellorNumber(bookedSlot?.counsellorNumber);
        });

        const options = {
            key: "rzp_live_cJ3YCKf6Ayf4ab", // Enter the Key ID generated from the Dashboard
            amount: price * 100,
            currency: "INR",
            name: "Seek Spiritual Counselling",
            description: 'Poc Counselling',
            image: 'https://firebasestorage.googleapis.com/v0/b/seek-c3955.appspot.com/o/Frame.svg?alt=media&token=04498e42-c418-4652-af17-1e2b4b228dd7&_gl=1*vvkkh7*_ga*MTcxMzk5NzQ5Ny4xNjg2NzI1ODcy*_ga_CW55HF8NVT*MTY5OTMzNzEyNi4yOTEuMS4xNjk5MzM3NDYyLjQ1LjAuMA..',
            handler: async function (response: any) {
                // console.log("payment response",response)
                if (response.razorpay_payment_id) {
                    setPaymentSuccessPopup(true);
                    Mixpanel.track('view_counselling_payment_successful', {
                        event_action: 'viewed',
                        event_page: 'counselling_checkout_page',
                        full_name:fullName,
                        phone_number:phone,
                        problem_to_heal:whatDoYouWantToHeal,
                        technique:CounsellingTechniques[selectedTechniqueOptionIndex - 1],


                    });

                } else {
                    setPaymentFailedPopup(true);
                    Mixpanel.track('payment_failure', {
                        event_action: 'viewed',
                        event_page: 'counselling_checkout_page',
                        full_name:fullName,
                        phone_number:phone,
                        problem_to_heal:whatDoYouWantToHeal,
                        technique:CounsellingTechniques[selectedTechniqueOptionIndex - 1],
                    });
                }
            },
            prefill: {
                name: fullName,
                email: 'contact@seekapp.co',
                contact: phone,
            },
            theme: {color: COLORS.PRIMARY},
            modal: {
                ondismiss: function (response: any) {
                    setPaymentFailedPopup(true);
                    Mixpanel.track('payment_failure', {
                        event_action: 'viewed',
                        event_page: 'counselling_checkout_page',
                        full_name:fullName,
                        phone_number:phone,
                        problem_to_heal:whatDoYouWantToHeal,
                        technique:CounsellingTechniques[selectedTechniqueOptionIndex - 1],
                    });
                }
            }
        };

        const paymentObject = (window as any).Razorpay(options);
        paymentObject.open();
    }

    const onChatClickHandler = () =>{
        Mixpanel.track('click_chat_now', {
            event_action: 'clicked',
            event_page: 'counselling_landing_page',
        });
        setState(STATES.POC_FORM_PAGE)
    }


    const onClickChatNowHandler = (name: string, mobile: string, problem: string, selectedOptionIndex: number) => {
        Mixpanel.track('click_chat_now', {
            event_action: 'clicked',
            full_name:fullName || name,
            phone_number:phone || mobile,
            problem_to_heal:whatDoYouWantToHeal||problem,
            technique:CounsellingTechniques[selectedTechniqueOptionIndex - 1],
            event_page: 'registeration_page',
        });
        setState(STATES.POC_CHECKOUT_PAGE)

    }

    const onContinueFromSlotHandler = (slot: string, dateIndex: number) => {
        Mixpanel.track('click_continue', {
            event_action: 'clicked',
            full_name:fullName,
            date:selectedDateOptionIndex === 1 ? moment().toString() : moment().add(1, 'days').toString(),
            time:timeSlot,
            event_page: 'slot_booking_page',
        });
        setState(STATES.POC_CHECKOUT_PAGE)

    }

    const onClickChatLaterHandler = (fullName: string, phone: string, whatDoYouWantToHeal: string, selectedOption: number) => {
        console.log("onClickChatLaterHandler", fullName, phone, whatDoYouWantToHeal, selectedOption)

        if(pocCounsellingTimeWindow){
            Mixpanel.track('click_chat_later', {
                event_action: 'clicked',
                full_name:fullName,
                phone_number:phone,
                problem_to_heal:whatDoYouWantToHeal,
                technique:CounsellingTechniques[selectedTechniqueOptionIndex - 1],
                event_page: 'registeration_page',
            });
        }else{
            Mixpanel.track('click_book_your_slot', {
                event_action: 'clicked',
                full_name:fullName,
                phone_number:phone,
                problem_to_heal:whatDoYouWantToHeal,
                technique:CounsellingTechniques[selectedTechniqueOptionIndex - 1],
                event_page: 'registeration_page',
            });
        }

        setState(STATES.POC_BOOK_SLOT_PAGE)
    }

    const onBackArrowClick = () => {
        try {
            setState(prevState => {
                switch (prevState) {
                    case STATES.POC_FORM_PAGE:
                        return STATES.POC_LANDING_PAGE;
                    case STATES.POC_CHECKOUT_PAGE:
                        return STATES.POC_FORM_PAGE;
                    case STATES.POC_BOOK_SLOT_PAGE:
                        return STATES.POC_FORM_PAGE;
                    default:
                        return prevState;
                }
            });
        } catch (e) {

        }
    }

    const chatWhatsAppsHandler = async () => {
        try {
            Mixpanel.track('click_go_to_whatsapp', {
                event_action: 'clicked',
                event_page: 'counselling_checkout_page',
            });
            const whatsappGroupLink = `https://wa.me/9959637700`;
            setPaymentSuccessPopup(false);
            // window.localStorage.setItem('JoinedWhatsAppCommunity', 'true');
            window.open(whatsappGroupLink, '_blank');
            navigate('/')
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <div className={"poc-page-container"}>
            {state !== STATES.POC_LANDING_PAGE && <div style={{width: '90%', maxWidth: '400px'}}>
                <BackButton onClick={onBackArrowClick}/>
            </div>}
            {state === STATES.POC_LANDING_PAGE && <div className="poc-landing-container">
                <div style={{
                    width: '100%',
                    maxWidth: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: '#FFF',
                    justifyContent: 'space-between',
                }}>
                    <SeekLogo style={{alignSelf: 'center'}}/>
                    <span className={"poc-landing-subtitle"}>India’s Largest Spiritual Healing
                    Platform</span>
                </div>


                <div className={'poc-landing-greetings-container'}>
                    <span className={'poc-landing-greetings-title'}>Heal your</span>
                    <div style={{width: '100%', height: '36px'}}>
                        <AnimatedText colorCodes={['#EF3A3A', '#EF3AE8', '#EF9E3A', '#447EEF']}
                                      words={['Anger', 'Relationship', 'Grief', 'Gut']}/>
                    </div>
                    <span
                        className={'poc-landing-greetings-description'}>Get instant traditional remedy from the Masters in <span>Chakra, Yoga, Reiki, Hypnosis</span> and <span>Meditation.</span></span>
                    <PrimaryButton style={{marginTop: '26px',width:'90%', maxWidth: '400px',height:'40px'}} onClick={onChatClickHandler}>
                        {'Chat Now @INR 15/min'}
                    </PrimaryButton>
                </div>


                <div className={'poc-landing-greetings-container'} style={{ maxWidth: '400px',background:'white',marginTop:'0px'}}>
                    <span className={"poc-landing-greetings-description"}>Research Adapted By</span>
                    <img src={research_banner} alt="Timing Banner"
                         style={{ width: '100%', maxWidth: '400px',objectFit:'contain'}}/>
                </div>


                <div className={'poc-landing-greetings-container'} style={{marginTop:'0px', maxWidth: '400px',}}>
                    <SpritualMastersHeading style={{alignSelf: 'center'}}/>
                    {InstructorCarouselItems?.map((instructor: any, index: number) => <SeekGurusCard
                        conversations={instructor.conversations}
                        title={instructor.title}
                        subtitle={instructor.subtitle}
                        imageUrl={instructor.imageUrl}
                    />)}
                </div>

                <div className={'poc-landing-greetings-container'} style={{background:"white", maxWidth: '400px',}}>
                    {TestimonialCounsellingItems?.map((testimonial: any, index: number) => <ProfileCard index={index}
                                                                                                        comment={testimonial.description}
                                                                                                        name={testimonial.title}
                                                                                                        date={testimonial.date}
                                                                                                        imageUrl={testimonial.imageUrl}
                    />)}
                </div>



                <div className={'poc-landing-greetings-container'} style={{width:'100%', maxWidth: '400px',background:'white',marginTop:'0px'}}>
                    <span className={"poc-landing-greetings-description"}>Backed by leaders of</span>
                    <ImageSlider images={seekBackedInByImages} interval={3000} />
                </div>

                <div className={'poc-landing-greetings-container'} style={{background: 'background: #F4F4F4',marginTop:'0px', maxWidth: '420px',}}>
                    <HealYourself style={{alignSelf: 'center'}}/>
                    <span className={"poc-landing-subtitle"}>Made with ❤️ in India.</span>
                </div>

                <div style={{marginBottom: '132px'}}></div>
                <BottomContainer
                    onButtonClick={onChatClickHandler}
                    label="Chat Now @INR 15/min"
                />
            </div>}
            {state === STATES.POC_FORM_PAGE &&
                <PocForm pocCounsellingTimeWindow={pocCounsellingTimeWindow}
                         onClickChatNowHandler={onClickChatNowHandler}
                         onClickChatLaterHandler={onClickChatLaterHandler}/>}
            {state === STATES.POC_CHECKOUT_PAGE && <PocCheckout payNowClickHandler={displayRazorpay}/>}
            {state === STATES.POC_BOOK_SLOT_PAGE &&
                <PocBookSlotPage onContinueClickHandler={onContinueFromSlotHandler}/>}


            <Modal show={paymentSuccessPopup} showHeader={false} onHide={()=>{}}
                   title={''} content={<div
                style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <PaymentSuccess width={120} height={120}/>
                <span className={'payment-success-hurray'}>Payment Successful 🎉</span>
                <Button style={{alignSelf: 'center', backgroundColor: COLORS.PRIMARY, marginTop: '20px'}}
                        onClick={chatWhatsAppsHandler}
                        label={'Go to WhatsApp'}/>
            </div>}/>

            <Modal show={paymentFailedPopup} showHeader={false} onHide={setPaymentFailedPopup.bind(null, false)}
                   title={''} content={<div
                style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <PaymentFailed width={120} height={120}/>
                <span className={'payment-success-hurray'}>Payment Failed ❌</span>
                <span
                    className={'payment-success-plan-description'}>{`Your payment of INR 75 has been failed due to some technical reasons.`}</span>
                <Button style={{alignSelf: 'center', backgroundColor: COLORS.PRIMARY, marginTop: '20px'}}
                        onClick={()=>{
                            Mixpanel.track('click_retry_payment', {
                                event_action: 'clicked',
                                event_page: 'counselling_checkout_page',
                            });
                            displayRazorpay(75).then();
                        }}
                        label={'Retry Payment'}/>
            </div>}/>

            <ToastContainer/>
        </div>

    )
}

export default PocLandingPage;
