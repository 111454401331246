import React from 'react';
import { TermsAndCondition } from '../../utils/utils';

function TermsConditions() {
	return (
		<div
			style={{
				paddingTop: '32px',
				paddingLeft: '16px',
				paddingRight: '16px',
				display: 'flex',
				flexDirection: 'column',
			}}>
			<h2
				style={{
					marginTop: '12px',
					color: '#1A1A1A',
					fontFamily: 'Poppins',
					fontSize: '24px',
					fontStyle: 'normal',
					fontWeight: 500,
				}}>
				{'Terms & Conditions'}
			</h2>
			<p
				style={{
					marginTop: '12px',
					color: '#757575',
					fontFamily: 'Poppins',
					fontSize: '16px',
					fontStyle: 'normal',
				}}>
				{TermsAndCondition.body}{' '}
			</p>
		</div>
	);
}

export default TermsConditions;
