import React from 'react';
import './SecondaryButton.css';

interface SecondaryButtonProps {
	onClick?: () => void;
	className?: string;
	children: any;
	style?: any;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
	onClick,
	className,
	children,
	style,
}) => {
	return (
		<button
			style={style}
			className={`secondary-button ${className}`}
			onClick={onClick}>
			<span className={'secondary-button-title'}>{children}</span>
		</button>
	);
};

export default SecondaryButton;
