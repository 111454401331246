import React, { useEffect, useState } from 'react';
import Loader from '../components/loader/Loader';
import LandingPageOne from './LandingPageOne';
import { useNavigate } from 'react-router-dom';
import useAuthenticatedUsers from '../hooks/useAuthenticatedUsers';
import { getUserByUserId } from '../services/Firebase';

function Landing() {
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const userData = useAuthenticatedUsers();
	useEffect(() => {
		if (userData?.uid) {
			getUserByUserId(userData?.uid).then((userLoginResult) => {
				// TODO update user with latest userdata in redux
				setLoading(false);
				navigate('/home');
			});
		} else {
			navigate('/landing-page-one');
			setLoading(false);

		}
	}, [userData]);

	return (
		<div className={'App'}>
			{!loading && <LandingPageOne />}
			{loading && <Loader />}
		</div>
	);
}

export default Landing;
