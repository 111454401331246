import React, { useEffect, useState } from 'react';
import './ToggleComponent.css';
import { toast, ToastContainer } from 'react-toastify';

interface ToggleComponentProps {
	slotValue: string;
	title: string;
	subtitle: string;
	userManagedReminders: any;
	onToggleSlot: (slotValue: string, isChosen: boolean) => void;
	selectedSlotCount: number;
	index?: number;
}

const ToggleComponent: React.FC<ToggleComponentProps> = ({
	index,
	selectedSlotCount,
	title,
	subtitle,
	userManagedReminders,
	slotValue,
	onToggleSlot,
}) => {
	const isTitleInSlots = userManagedReminders?.includes(slotValue);
	const [isToggled, setIsToggled] = useState<boolean>(isTitleInSlots);

	useEffect(() => {
		setIsToggled(isTitleInSlots);
	}, [isTitleInSlots]);

	const handleToggle = () => {
		onToggleSlot(slotValue, !isToggled);
		if (selectedSlotCount === 4 && isToggled) {
			setIsToggled(false);
		} else if (selectedSlotCount === 4 && !isToggled) {
			toast.warning('You can select a maximum of 4 time slots for reminders.', {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
				hideProgressBar: true,
			});
		} else if (selectedSlotCount <= 4) {
			setIsToggled((prevState) => !prevState);
		}
	};

	return (
		<div
			className={`toggle-component ${isTitleInSlots ? 'with-title' : ''} ${
				selectedSlotCount === 4 && !isTitleInSlots ? 'selected-four' : ''
			}`}
			style={{ marginTop: index === 0 ? '24px' : '12px' }}>
			<div className='left-content'>
				<h2
					className={`${
						isTitleInSlots ? 'selected-toggle-title' : 'toggle-title'
					}`}
					style={{ marginTop: !subtitle ? '6px' : '0px' }}>
					{title}
				</h2>
				{subtitle && <p className={'toggle-subtitle'}>{subtitle}</p>}
			</div>
			<div className='right-content'>
				<label className='switch'>
					<input type='checkbox' checked={isToggled} onChange={handleToggle} />
					<span className='slider round'></span>
				</label>
			</div>
			<ToastContainer />
		</div>
	);
};

export default ToggleComponent;
