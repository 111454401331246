import React, { useEffect, useState } from 'react';
import PaymentSuccess from '../../assets/animations/payment-suceess.gif';
import './PaymentSuccessScreen.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Mixpanel } from '../../analytics/Mixpanel';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import AppDownloadNudge from '../../components-v2/app-download-nudge/AppDownloadNudge';
import accountCreatedGif from '../../assets/animations/AccountCreated.gif';
import { useSelector } from 'react-redux';
import { paidUserNumbers, unPaidUserNumbers } from '../../hooks/usePaidUsers';

export enum STATES {
	PAYMENT_SUCCESSFUL_ONE,
}

interface PaymentSuccessModalProps {}

const PlanPaymentSuccess: React.FC<PaymentSuccessModalProps> = ({}) => {
	const [state, setState] = useState(STATES.PAYMENT_SUCCESSFUL_ONE);
	const userData = useAuthenticatedUsers(true);
	const location = useLocation();
	const selectedPlanVariant =
		location.state && location.state?.selectedPlanVariant;
	const prevScreenName = location.state && location.state?.prevScreenName;
	const currently_active_variant =
		location.state && location.state?.currently_active_variant;
	const user = useSelector((state: any) => state?.auth?.user?.data);
	const navigate = useNavigate();


	const betaUnpaidUsers = useSelector(
		(state: any) => state.betaUsers?.betaUsers?.betaUnpaidUsers
	); // Assuming upcomingClasses state is stored in Redux


	console.log("betaPaidUsers",betaUnpaidUsers);




	useEffect(() => {
		document.body.style.overflow = 'hidden';
		Mixpanel.track('view_payment_successful_screen', {
			event_action: 'viewed',
			plan_duration: selectedPlanVariant?.duration,
			phone: userData?.phoneNumber || userData?.phone,
		});

		return () => {
			document.body.style.overflow = 'initial';
		};
	}, [selectedPlanVariant, userData]);

	useEffect(() => {
		if (
			(userData?.phone || userData?.phoneNumber) &&
			!betaUnpaidUsers.includes(userData?.phone || userData?.phoneNumber)
		) {
			setTimeout(() => {
				onContinueFromPaymentSuccess().then();
			}, 5000);
		}
	}, [user]);

	useEffect(() => {
		const handlePopState = (event: any) => {
			window.history.pushState(null, document.title, window.location.href);
		};

		window.history.pushState(null, document.title, window.location.href);
		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, []);

	const onContinueFromPaymentSuccess = async () => {
		try {
			Mixpanel.track('click_continue', {
				event_action: 'redirect',
				event_page: 'payment_successful_screen',
				context_user_id: user?.uid,
				phone: userData?.phoneNumber || userData?.phone,
			});
			if (
				prevScreenName === 'AccountCreated' ||
				currently_active_variant === 'FREE'
			) {
				navigate('/get-started');
			} else {
				navigate('/home');
			}
		} catch (error) {
			console.error('Error:', error);
			// Handle the error as needed
		}
	};

	if (state === STATES.PAYMENT_SUCCESSFUL_ONE) {
		return (
			<div className='payment-success-container'>
				<img
					src={PaymentSuccess}
					style={{ alignSelf: 'center', width: 84, height: 84 }}
					alt='Landing Banner'
				/>
				<span className='payment-success-hurray'>Payment Successful 🎉</span>
				<span className='payment-success-plan-description'>
					You have successfully bought{' '}
					<span>{selectedPlanVariant?.name} plan</span>
				</span>
				{betaUnpaidUsers.includes(
					userData?.phone || userData?.phoneNumber
				) && <AppDownloadNudge screenName={'Payment'} />}
			</div>
		);
	}

	return null;
};

export default PlanPaymentSuccess;
