// AppLayout.js

import React from 'react';
import './GoldenThemeAppLayout.css'; // Import the stylesheet for styling

const AppLayout = ({ children }) => {
    return (
        <div className="golden-app-layout">
            <div className="golden-background-image" />
            {children}
        </div>
    );
};

export default AppLayout;
