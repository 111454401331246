import React, {useEffect, useState} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import './BottomNavigation.css';
import {ReactComponent as LiveClass} from '../../assets/new-icons/live-class.svg';
import {ReactComponent as HomeIcon} from '../../assets/new-icons/home.svg';
import {ReactComponent as Profile} from '../../assets/new-icons/profile.svg';
import {ReactComponent as SelectedHome} from '../../assets/new-icons/selected-home.svg';
import {ReactComponent as SelectedLiveClass} from '../../assets/new-icons/selected-live-class.svg';
import {ReactComponent as SelectedProfile} from '../../assets/new-icons/selected-profile.svg';
import {ReactComponent as SelectedCourse} from '../../assets/new-icons/course-selected.svg';
import {ReactComponent as Course} from '../../assets/new-icons/course.svg';
import Home from '../../screens/home-v2/Home';
import LiveClassContainer from '../../screens/live-class/LiveClassContainer';
import {updateCurrentTab} from '../../store/features/BottomNavSlice';
import {useDispatch, useSelector} from 'react-redux';
import ProfileContainer from '../../screens/profile/ProfileConatiner';
import {Mixpanel} from '../../analytics/Mixpanel';
import {updateEventsStatus} from '../../store/features/EventsSlices';
import {useNavigate} from 'react-router-dom';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import CourseContainer from '../../screens/course/CourseContainer';

const BottomNavigation = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const dispatch = useDispatch();
    const currentSelectedTab = useSelector(
        (state: any) => state?.bottomNav?.bottomNavBar?.currentTabIndex
    );
    const view_bottom_nav = useSelector(
        (state: any) => state?.events?.eventsLog?.view_bottom_nav
    );
    const view_main_header = useSelector(
        (state: any) => state?.events?.eventsLog?.view_main_header
    );

    const handleTabSelect = (index: number) => {
        setActiveTab(index);
        dispatch(updateCurrentTab(index));
        updateUrl(index);
    };

    const updateUrl = (index: number) => {
        switch (index) {
            case 0:
                navigate('/home');
                break;
            case 1:
                navigate('/live-classes');
                break;
            case 2:
                navigate('/courses');
                break;
            case 3:
                navigate('/profile');
                break;
            default:
                navigate('/home');
        }
    };

    useEffect(() => {
        updateUrl(activeTab);
    }, [activeTab]);


    useEffect(() => {
        if (!view_bottom_nav) {
            Mixpanel.track('view_bottom_nav', {
                event_action: 'viewed',
            });
            dispatch(updateEventsStatus({key: 'view_bottom_nav', value: true}));
        }
    }, [view_bottom_nav]);


    useEffect(() => {
        if (!view_main_header) {
            Mixpanel.track('view_main_header', {
                event_action: 'viewed',
            });
            dispatch(updateEventsStatus({key: 'view_main_header', value: true}));
        }
    }, [view_main_header]);

    useEffect(() => {
        if (localStorage.getItem('isAngerVisited') !== 'true') {
            Mixpanel.track('view_new_course_tag', {
                event_action: 'viewed',
            });
        }
    }, []);

    return (
        <div className={'bottom-nav-container'}>
            <Tabs selectedIndex={currentSelectedTab} onSelect={handleTabSelect}>
                <TabPanel>
                    {currentSelectedTab === 0 && <Home/>}
                    {currentSelectedTab === 1 && <LiveClassContainer/>}
                    {currentSelectedTab === 2 && <CourseContainer/>}
                    {currentSelectedTab === 3 && <ProfileContainer/>}
                </TabPanel>
                <TabPanel>
                    {currentSelectedTab === 0 && <Home/>}
                    {currentSelectedTab === 1 && <LiveClassContainer/>}
                    {currentSelectedTab === 2 && <CourseContainer/>}
                    {currentSelectedTab === 3 && <ProfileContainer/>}
                </TabPanel>
                <TabPanel>
                    {currentSelectedTab === 0 && <Home/>}
                    {currentSelectedTab === 1 && <LiveClassContainer/>}
                    {currentSelectedTab === 2 && <CourseContainer/>}
                    {currentSelectedTab === 3 && <ProfileContainer/>}
                </TabPanel>
                <TabPanel>
                    {currentSelectedTab === 0 && <Home/>}
                    {currentSelectedTab === 1 && <LiveClassContainer/>}
                    {currentSelectedTab === 2 && <CourseContainer/>}
                    {currentSelectedTab === 3 && <ProfileContainer/>}
                </TabPanel>
                {
                    <TabList
                        className={
                            currentSelectedTab !== 2
                                ? 'bottom-nav-tab-list-three'
                                : 'bottom-nav-tab-list-three dark'
                        }>
                        <Tab>
                            <div
                                className='bottom-nav-tab-list-item-box'
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                {currentSelectedTab === 0 ? <SelectedHome/> : <HomeIcon/>}

                                <span
                                    className={`bottom-nav-card-tag-title ${
                                        currentSelectedTab === 0 ? 'selected' : ''
                                    }`}>
									Home
								</span>
                                {currentSelectedTab === 0 && (
                                    <svg
                                        style={{marginTop: '4px'}}
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='43'
                                        height='2'
                                        viewBox='0 0 43 2'
                                        fill='none'>
                                        <path
                                            d='M1.16669 1H41.1667'
                                            stroke='#FAA53D'
                                            stroke-width='2'
                                            stroke-linecap='square'
                                        />
                                    </svg>
                                )}
                            </div>
                        </Tab>
                        <Tab>
                            <div
                                className='bottom-nav-tab-list-item-box'
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                {currentSelectedTab === 1 ? (
                                    <SelectedLiveClass/>
                                ) : (
                                    <LiveClass/>
                                )}
                                <span
                                    className={`bottom-nav-card-tag-title ${
                                        currentSelectedTab === 1 ? 'selected' : ''
                                    }`}>
									Live Classes
								</span>
                                {currentSelectedTab === 1 && (
                                    <svg
                                        style={{marginTop: '4px'}}
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='43'
                                        height='2'
                                        viewBox='0 0 43 2'
                                        fill='none'>
                                        <path
                                            d='M1.16669 1H41.1667'
                                            stroke='#FAA53D'
                                            stroke-width='2'
                                            stroke-linecap='square'
                                        />
                                    </svg>
                                )}
                            </div>
                        </Tab>
                        <Tab>
                            <div
                                className='bottom-nav-tab-list-item-box'
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                }}>
                                {currentSelectedTab === 2 ? <SelectedCourse/> : <Course/>}
                                {localStorage.getItem('isAngerVisited') !== 'true' ? (
                                    <p className='course-tab-badge'>NEW</p>
                                ) : (
                                    <></>
                                )}
                                <span
                                    className={`bottom-nav-card-tag-title ${
                                        currentSelectedTab === 2 ? 'selected' : ''
                                    }`}>
									Courses
								</span>
                                {currentSelectedTab === 2 && (
                                    <svg
                                        style={{marginTop: '4px'}}
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='43'
                                        height='2'
                                        viewBox='0 0 43 2'
                                        fill='none'>
                                        <path
                                            d='M1.16669 1H41.1667'
                                            stroke='#FAA53D'
                                            stroke-width='2'
                                            stroke-linecap='square'
                                        />
                                    </svg>
                                )}
                            </div>
                        </Tab>

                        <Tab>
                            <div
                                className='bottom-nav-tab-list-item-box'
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                {currentSelectedTab === 3 ? <SelectedProfile/> : <Profile/>}
                                <span
                                    className={`bottom-nav-card-tag-title ${
                                        currentSelectedTab === 2 ? 'selected' : ''
                                    }`}>
									Profile
								</span>
                                {currentSelectedTab === 3 && (
                                    <svg
                                        style={{marginTop: '4px'}}
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='43'
                                        height='2'
                                        viewBox='0 0 43 2'
                                        fill='none'>
                                        <path
                                            d='M1.16669 1H41.1667'
                                            stroke='#FAA53D'
                                            stroke-width='2'
                                            stroke-linecap='square'
                                        />
                                    </svg>
                                )}
                            </div>
                        </Tab>
                    </TabList>
                }
            </Tabs>
        </div>
    );
};

export default BottomNavigation;
