import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import './PlanDescriptionPage.css';

import {
	Faqs,
	LANDING_COMPONENT,
	peopleStats,
	Reviews,
	UTM_FOR_3_6_MONTHS,
} from '../../../utils/utils';
import { Mixpanel } from '../../../analytics/Mixpanel';
import FAQ from './FAQ';
import TestimonialsVideoSlider from './TestimonialsVideoSlider';
import PlanVariant from './PlanVariant';
import PausePlanModel from '../../../components-v2/models/PausePlanModel';
import BestOutOfItIconWithName from '../../../components-v2/rows/BestOutOfItIconWithName';
import CustomerReview from '../../../components-v2/rows/CustomerReview';
import PeopleStats from '../../../components-v2/rows/PeopleStats';
import impactImage from '../../../assets/webp-images/plan-impact-lives.png';
import { duration } from 'moment-timezone';
import useAuthenticatedUsers from '../../../hooks/useAuthenticatedUsers';
import Shimmer from '../../../components-v2/shimmer/Shimmer';

interface TriggeredEvents {
	[key: string]: boolean;
}

const sectionRanges = {
	section1: {
		start: 0,
		end: 500,
		section_heading: 'Choose your perfect plan',
	},
	section2: {
		start: 100,
		end: 800,
		section_heading: 'How we change lives...',
	},
	section3: {
		start: 650,
		end: 800,
		section_heading: 'Impact we created...',
	},
	section4: { start: 900, end: 1100, section_heading: 'FAQ and Support' },
} as { [key: string]: { start: number; end: number; section_heading: string } };

interface PlanDescriptionPageProps {
	plans: any;
	isCommunityMember: boolean;
	selectedPlanIndex: number;
	isUserLogin: boolean;
	utm_campaign: string;
	setSelectedPlanVariant: any;
	onProceedFromPlanDetailsPageHandler: (
		planVariant: any,
		selectedPlanVariantIndex: number
	) => void;
}

const PlanDescriptionPage: React.FC<PlanDescriptionPageProps> = ({
	selectedPlanIndex,
	isUserLogin,
	onProceedFromPlanDetailsPageHandler,
	plans,
	utm_campaign,
	isCommunityMember,
	setSelectedPlanVariant,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	console.log('plans', plans);

	const [selectedFAQIndex, setSelectedFAQIndex] = useState<number | null>();
	const [showMore, setShowMore] = useState(false);
	const [showText, setShowText] = useState('Show More');
	const [reviews, setReviews] = useState(Reviews);
	const [bestOutOfIt, setBestOutOfIt] = useState([
		'30 mins guided classes everyday',
		'Community access',
		'Full refund within 7 days,  if you don’t like it',
	]);
	const [showPauseBanner, setShowPauseBanner] = useState(false);
	const [selectedPlanVariantIndex, setSelectedPlanVariantIndex] =
		useState<number>(selectedPlanIndex || 0);

	const [triggeredEvents, setTriggeredEvents] = useState<TriggeredEvents>({
		section1: false,
		section2: false,
		section3: false,
	});

	const markMixPanelEvent = (position: number) => {
		const checkAndTriggerEvent = (
			sectionKey: string,
			start: number,
			end: number
		) => {
			if (
				!triggeredEvents[sectionKey] &&
				position >= start - 10 &&
				position <= end + 10
			) {
				Mixpanel.track('view_payment_landing_page_section', {
					event_action: 'viewed',
					event_section: sectionKey,
					section_heading: sectionRanges[sectionKey].section_heading,
					event_page: 'plan_landing_page',
					plan_name: `${plans?.[0]?.display_name || plans?.[0]?.name}, ${
						plans?.[1]?.display_name || plans?.[1]?.name
					}`,
				});
				setTriggeredEvents((prevEvents: any) => ({
					...prevEvents,
					[sectionKey]: true,
				}));
			}
		};

		Object.entries(sectionRanges).forEach(([sectionKey, { start, end }]) => {
			checkAndTriggerEvent(sectionKey, start, end);
		});
	};

	useEffect(() => {
		if (!triggeredEvents['section1'] && plans) {
			Mixpanel.track('view_payment_landing_page_section', {
				event_action: 'viewed',
				event_section: 'section1',
				section_heading: 'Choose your perfect plan',
				event_page: 'plan_landing_page',
				plan_name: `${plans?.[0]?.display_name || plans?.[0]?.name}, ${
					plans?.[1]?.display_name || plans?.[1]?.name
				}`,
			});
			setTriggeredEvents((prevEvents: any) => ({
				...prevEvents,
				section1: true,
			}));
		}
		const handleScroll = () => {
			markMixPanelEvent(window.scrollY);
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [triggeredEvents, plans]);

	const onClickShowMoreReviews = () => {
		setShowMore((prevState) => !prevState);
		Mixpanel.track('click_show_more_reviews', {
			event_action: 'clicked',
			event_page: 'plan_landing_page',
		});
	};

	useEffect(() => {
		if (showMore) {
			setReviews(Reviews);
			setShowText('Show Less');
		} else {
			setShowText('Show More');
			setReviews(Reviews.slice(0, 2));
		}
	}, [showMore, Reviews]);

	const onFAQClickHandler = (index: number) => {
		try {
			if (selectedFAQIndex !== index) {
				Mixpanel.track('click_view_FAQs_on_plan_landing_page', {
					event_action: 'clicked',
					faq_heading: Faqs[index],
					event_page: 'plan_landing_page',
				});
			}
		} catch (e) {}
		setSelectedFAQIndex((prevState) => (prevState === index ? null : index));
	};

	const onProceed = () => {
		onProceedFromPlanDetailsPageHandler(
			plans[selectedPlanVariantIndex],
			selectedPlanVariantIndex
		);
	};

	const handlePlanVariantClick = (
		selectedIndex: number,
		duration: number,
		price: number
	) => {
		setSelectedPlanVariantIndex(selectedIndex);
		setSelectedPlanVariant(plans[selectedIndex]);
		try {
			Mixpanel.track('click_to_choose_plan_duration', {
				event_action: 'clicked',
				duration: duration,
				plan_price: price,
			});
		} catch (err) {}
	};

	return (
		<Container
			className={'plan-description-container'}
			style={{
				backgroundImage: utm_campaign.includes(UTM_FOR_3_6_MONTHS)
					? 'linear-gradient(180deg, rgba(68, 98, 239, 0.12) 0%, rgba(68, 98, 239, 0.12) 390px, #fff 390px, #fff 100%)'
					: 'linear-gradient(180deg, rgba(68, 98, 239, 0.12) 0%, rgba(68, 98, 239, 0.12) 366px, #fff 366px, #fff 100%)',
			}}>
			<div
				className={'plan-description-component-wrapper'}
				style={{
					width: '100%',
				}}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						paddingLeft: '16px',
						maxWidth: '400px',
						width: '100%',
					}}>
					<span
						style={{
							color: '#1B1625',
							fontFamily: 'Galaxie-Copernicus-Book',
							fontSize: '20px',
							width: '100%',
							alignSelf: 'start',
							textAlign: 'start',
							fontStyle: 'normal',
							fontWeight: 500,
							lineHeight: '28px',
						}}>
						Choose your perfect plan
					</span>
				</div>
				{bestOutOfIt
					?.filter((value) => value !== null)
					.map((value, index) => (
						<BestOutOfItIconWithName key={index} index={index} name={value} />
					))}
				{plans && plans.length ? (
					plans.map((plan: any, index: number) => (
						<PlanVariant
							utm_campaign={utm_campaign}
							count={plans?.length}
							free_extension={plan?.free_extension}
							offer={plan?.offer}
							extra_offer={plan?.extra_offer}
							strike_price={plan?.strike_price}
							pause_limit={plan?.pause_limit}
							key={index}
							onClick={(selectedIndex) =>
								handlePlanVariantClick(
									selectedIndex,
									plan?.duration,
									plan?.total_price
								)
							}
							// onClick={onProceed}
							setShowPauseBanner={setShowPauseBanner}
							index={index}
							name={plan?.display_name || plan?.name}
							selectedIndex={selectedPlanVariantIndex || 0}
							type={plan?.type}
							duration={plan?.duration}
							monthly_price={
								isCommunityMember
									? plan?.community_monthly_price
									: plan?.monthly_price
							}
							total_price={
								isCommunityMember
									? plan?.community_total_price
									: plan?.total_price
							}
							recommended={plan.recommended}
						/>
					))
				) : (
					<Shimmer style={{ height: '201px' }}></Shimmer>
				)}
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					paddingLeft: '16px',
					marginTop: '32px',
					maxWidth: '400px',
					width: '100%',
				}}>
				<span
					style={{
						color: '#1B1625',
						fontFamily: 'Galaxie-Copernicus-Book',
						fontSize: '20px',
						width: '100%',
						alignSelf: 'start',
						textAlign: 'start',
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '28px',
					}}>
					How we change lives...
				</span>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<TestimonialsVideoSlider
					style={{
						width: '364px',
						height: '220px',
						marginTop: '24px',
					}}
					pageName='plan_landing_page'
					naturalSlideHeight={256}
					naturalSlideWidth={240}
					component_name={LANDING_COMPONENT.TESTIMONIAL}
				/>
			</div>

			<svg
				style={{ marginTop: '18px' }}
				xmlns='http://www.w3.org/2000/svg'
				width='354'
				height='1'
				viewBox='0 0 354 1'
				fill='none'>
				<path d='M1 0.500183H344' stroke='#E4E4E4' stroke-linecap='round' />
			</svg>
			{reviews?.map((review: any, index: number) => (
				<CustomerReview
					count={showMore ? 6 : 3}
					index={index}
					imageSrc={review.imageSrc}
					name={review.name}
					period={review.period}
					bio={review.bio}
					rating={review.rating}
				/>
			))}
			<div
				className={'horizontal-container'}
				style={{
					width: '343px',
					alignItems: 'center',
					justifyContent: 'start',
					marginTop: '12px',
				}}
				onClick={onClickShowMoreReviews}>
				<span
					style={{
						color: 'var(--purple-gradient, #000)',
						fontFamily: 'Gordita-Medium',
						fontSize: '12px',
						fontStyle: 'normal',
						fontWeight: 500,
						alignSelf: 'center',
						textDecorationLine: 'underline',
						lineHeight: '16px', // or "150%"
					}}>
					{showText}
				</span>
				{showMore ? (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='16'
						height='17'
						viewBox='0 0 16 17'
						fill='none'>
						<path
							d='M12.5 10.7502L8 6.25024L3.5 10.7502'
							stroke='#1B1625'
							stroke-width='1.5'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
					</svg>
				) : (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='16'
						height='17'
						viewBox='0 0 16 17'
						fill='none'>
						<path
							d='M12.5 6.25018L8 10.7502L3.5 6.25018'
							stroke='#1B1625'
							stroke-width='1.5'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
					</svg>
				)}
			</div>

			<div className='plan-description-people-stats'>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						marginLeft: 'auto',
						marginRight: 'auto',
						marginTop: '16px',
						maxWidth: '400px',
						width: '343px',
					}}>
					<span
						style={{
							color: '#1B1625',
							fontFamily: 'Galaxie-Copernicus-Book',
							fontSize: '20px',
							width: '100%',
							alignSelf: 'start',
							textAlign: 'start',
							fontStyle: 'normal',
							fontWeight: 500,
							lineHeight: '28px',
						}}>
						Impact we've created..
					</span>
				</div>
				<img
					className='plan-description-people-stats-img'
					style={{ marginLeft: '12px', marginTop: '4px' }}
					src={impactImage}
					alt=''
				/>
				<div className='plan-description-people-stats-row'>
					<div className='plan-description-people-stats-card'>
						<p style={{ color: '#4462EF' }} className='percentage'>
							42%
						</p>
						<p className='text'>
							Decrease in <span>stress</span>
						</p>
					</div>
					<div className='plan-description-people-stats-card'>
						<p style={{ color: '#EB5757' }} className='percentage'>
							39%
						</p>
						<p className='text'>
							Less <span>anxiety</span>
						</p>
					</div>
				</div>
				<div className='plan-description-people-stats-row'>
					<div className='plan-description-people-stats-card'>
						<p style={{ color: '#B8ACF6' }} className='percentage'>
							26%
						</p>
						<p className='text'>
							Better <span>sleep</span> cycle
						</p>
					</div>
					<div className='plan-description-people-stats-card'>
						<p style={{ color: '#FAA53D' }} className='percentage'>
							18%
						</p>
						<p className='text'>
							Better <span>heart</span> health
						</p>
					</div>
				</div>
			</div>

			<p
				className={'plan-description-faq-title'}
				style={{
					alignSelf: 'center',
					width: '343px',
					margin: '40px auto 0px auto',
				}}>
				FAQs
			</p>
			{Faqs?.map((faq, index) => (
				<FAQ
					selectedIndex={selectedFAQIndex}
					index={index}
					item={faq}
					onClickHandler={onFAQClickHandler}
				/>
			))}

			<div style={{ marginBottom: '132px' }}></div>
			<div className='plan-fixed-bottom-container'>
				{plans && plans.length ? (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span className='plan-fixed-bottom-price'>
							{'₹' + plans?.[selectedPlanVariantIndex]?.total_price}
						</span>
						<span className='plan-fixed-bottom-duration'>
							{plans?.[selectedPlanVariantIndex]?.display_name}
						</span>
					</div>
				) : (
					<Shimmer style={{ height: '50px', width: '100px' }} />
				)}
				<button className='plan-fixed-bottom-button' onClick={onProceed}>
					{isUserLogin ? 'Pay now' : 'Login & Pay'}
				</button>
			</div>
			<PausePlanModel
				setShowPauseBanner={setShowPauseBanner}
				showPauseBanner={showPauseBanner}
			/>
		</Container>
	);
};

export default PlanDescriptionPage;
