export const COLORS = {
    PRIMARY: '#534DE0',
    SECONDARY: '#EEEDFF',
    TRANSPARENT: 'rgba(0,0,0,0)',
    WHITE:'#FFFFFF',
    BG_PRIMARY: '#fff',
    BG_SECONDARY: '#faf8f8',

    BG_INVERSE: '#fff',
    BG_SECONDARY_INVERSE: '#cfcfcf',

    BLACK: '#1A1A1A',
    DARK_BLACK: '#0a0a0a',

    LIGHT_RED: '#D3626D',
    RED: '#fe0622',
    YELLOW: '#F7A928',
    LIGHT_PRIMARY: '#bfbcff',
    LIGHT_GREEN: '#41CD70',
    LIGHTEST_GREY: '#eae8e8',

    TEXT: '#1A1A1A',
    TEXT_GREY: '#6A6A6A',
    DARK_GREY: '#757575',
    LIGHT_GREY: '#bababa',

    ACTIVITY_BG_COLOR: '#F4F3FF',
    BRIGHT_RED: '#E7A4AB',
    BRIGHT_GREEN: '#8DDEA8',
    Secondary_Color_Light_Background:'#FFF3E4'
};
