import React, {Component, useEffect, useState} from 'react'
import './ReferralHistory.css'
import {ReactComponent as BackArrow} from "../../assets/arrow-back.svg";
import {ReactComponent as SeekLogo} from "../../assets/seek-logo.svg";
import {useLocation, useNavigate} from "react-router-dom";
// import {ReactComponent as ReferralBanner} from "../../assets/referral_baneer.svg";
import referralBannerImage from "../../assets/webp-images/referral_baneer.webp";


import ReferralHistoryRow from "./components/ReferralHistoryRow";
import {getLiveStreamingReferralCode} from "../../services/Firebase";
import Loader from "../../components/loader/Loader";
import {getLastAttendance} from "../../utils/utils";
import EmptyReferralPage from "./components/EmptyReferralPage";
import {Mixpanel} from "../../analytics/Mixpanel";


const ReferralHistory  = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const userId = state?.userId;
    const [referralList,setReferralList] = useState<any>([]);
    const [loader,setLoader] = useState(true);


    useEffect(()=>{
        (async () =>{
            const referralCodeRes = await getLiveStreamingReferralCode(userId);
            setLoader(false)
            setReferralList(referralCodeRes?.userDataList);

        })();
    },[userId])

    useEffect(()=>{
           Mixpanel.track('view_refer_page',{
               event_action:'viewed',
               event_page:'referral_page',
           });
    },[])


    const onClickReferralBannerHandler = () =>{
        Mixpanel.track('click_refer_and_earn_banner',{
            event_action:'clicked',
            event_page:'referral_page',
        });
        navigate('/referral',{ state: { userId:userId}, replace: false })
    }

    const onSendReminderHandler = (recipientNumber:string,name:string,last_class_date:string,email:string,) =>{
        Mixpanel.track('click_send_reminder',{
            event_action:'clicked',
            context_user_id:userId,
            referred_user:{
                number:recipientNumber,
                name,
                email,
            },
            event_page:'my_referral_page',
        });

        if(last_class_date === 'No Classes Attended'){
            const template = `
       Hey ${name},
Good to see that you have signed up for the daily live meditation classes by Seek. I have attended many classes with them till now and it has helped me a lot in my mindfulness journey. Join your first class with me this week. Let’s build this habit together. 🧘`;
            const whatsappLink = `https://wa.me/${recipientNumber}?text=${encodeURIComponent(template)}`;
            window.open(whatsappLink, '_blank');
        }else{
            const template = `
       Hey ${name},
How’s your meditation journey going? It’s been some time since you have attended the live classes with Seek. I have attended many classes with them till now and it has helped me a lot in my mindfulness journey.  
Join your next class with me this week. Let’s build this habit together. 🧘`;
            const whatsappLink = `https://wa.me/${recipientNumber}?text=${encodeURIComponent(template)}`;
            window.open(whatsappLink, '_blank');
        }
    }
       if(loader) return  <Loader style={{alignSelf:'center'}}/>;

        return (
            <div className={'referral-history-container'}>
                <div className={'referral-container'} style={{justifyContent:'space-between',alignItems:'center'}}>
                    <BackArrow onClick={navigate.bind(null,-1)} width={28} height={28} />
                    <SeekLogo style={{marginRight:'28px'}} width={84} height={44} />
                    <div/>
                </div>

                {referralList?.length > 0 ? <div style={{display: "flex", flexDirection: 'column'}}>
                    <div className={'referral-history-title'}>Your Referral History</div>
                    <div className={'referral-history-subtitle'}>You can view your referral history here and ask your buddy to attend the session.</div>
                </div> : <EmptyReferralPage/>}

                {/*<ReferralBanner onClick={onClickReferralBannerHandler} style={{position: 'relative',marginTop:'16px',marginBottom:'16px'}} width={343} height={48}/>*/}
                <img
                    src={referralBannerImage}
                    alt="Referral Banner"
                    onClick={onClickReferralBannerHandler}
                    style={{
                        position: 'relative',
                        marginTop: '16px',
                        marginBottom: '16px',
                        width: '343px',
                        height: '48px',
                        cursor: 'pointer' // Optional: Add cursor pointer to indicate it's clickable
                    }}
                />

                {referralList?.map((referee: any) => (
                    <ReferralHistoryRow
                        phoneNumber={referee?.phone}
                        email={referee?.email}
                        onSendReminder={onSendReminderHandler}
                        name={referee?.name}
                        karma_points_earned_by={referee?.awarded_karma_points}
                        last_class_date={
                            !getLastAttendance(referee.lastAttendance?.weekly_attendance)
                                ? 'No Classes Attended'
                                : `Last Class Attended : ${getLastAttendance(
                                    referee.lastAttendance?.weekly_attendance
                                )}`
                        }
                    />
                ))}
            </div>
        )
}

export default ReferralHistory
