import React, { useState, useEffect } from 'react';
import './ImageSlider.css';

interface ImageSliderProps {
    images: string[];
    interval?: number; // Interval in milliseconds
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images, interval = 3000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, interval);

        return () => clearInterval(timer);
    }, [images.length, interval]);



    return (
        <div className="poc-image-slider">
            <div className="poc-slide-container" style={{ transform: `translateX(-${currentIndex * 40}%)` }}>
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Slide ${index}`}
                        className="poc-slide"
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageSlider;

