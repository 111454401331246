import React from 'react';
import './Shimmer.css';

interface ShimmerProps {
	style?: React.CSSProperties;
	classname?: string;
	isDark?: boolean;
}

const Shimmer: React.FC<ShimmerProps> = ({ style, classname, isDark }) => {
	return (
		<div
			className={
				!isDark
					? `shimmer-wrapper ${classname}`
					: `shimmer-wrapper ${classname} dark`
			}
			style={style}>
			<div className='shimmer'></div>
		</div>
	);
};

export default Shimmer;
