import React, { useState, useEffect } from 'react';
import './AnimatedText.css'

interface AnimatedTextProps {
    words: string[];
    typingSpeed?: number;
    deletingSpeed?: number;
    pauseTime?: number;
    colorCodes:string[];
}

const AnimatedText: React.FC<AnimatedTextProps> = ({
                                                       colorCodes,
                                                       words,
                                                       typingSpeed = 150,
                                                       deletingSpeed = 100,
                                                       pauseTime = 2000
                                                   }) => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (!isDeleting && displayedText.length < words[currentWordIndex].length) {
            timer = setTimeout(() => {
                setDisplayedText(words[currentWordIndex].substring(0, displayedText.length + 1));
            }, typingSpeed);
        } else if (isDeleting && displayedText.length > 0) {
            timer = setTimeout(() => {
                setDisplayedText(words[currentWordIndex].substring(0, displayedText.length - 1));
            }, deletingSpeed);
        } else if (!isDeleting && displayedText.length === words[currentWordIndex].length) {
            timer = setTimeout(() => {
                setIsDeleting(true);
            }, pauseTime);
        } else if (isDeleting && displayedText.length === 0) {
            setIsDeleting(false);
            setCurrentWordIndex((prev) => (prev + 1) % words.length);
        }

        return () => clearTimeout(timer);
    }, [displayedText, isDeleting, words, typingSpeed, deletingSpeed, pauseTime, currentWordIndex]);

    return (
        <span className={'animated-text-title'} style={{color:colorCodes[currentWordIndex]}}>{displayedText}</span>
    );
};

export default AnimatedText;
