import React from 'react';
import {Mixpanel} from "../../analytics/Mixpanel";
import {events, events_action, events_page} from "../../analytics/Events";


interface CheckboxWithTitleProps {
    title: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    onInfoClick:() => void ;
}

const CheckboxWithTitle: React.FC<CheckboxWithTitleProps> = ({title, checked, onChange,onInfoClick}) => {
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        Mixpanel.track(events.click_opt_in_checkbox, {
            event_page: events_page.sign_up,
            event_action: events_action.clicked,
            checkbox_status:e.target.checked,
        })
        onChange(e.target.checked);
    };

    return (
        <div style={{display: 'flex', alignItems: 'center', marginTop: '14px'}}>
            <input
                type="checkbox"
                checked={checked}
                onChange={handleCheckboxChange}
                style={{marginRight: '8px'}}
            />
            <span style={{
                color: '#1B1625',
                fontFamily: 'Gordita-Regular',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
            }}>{title}</span>

            <svg style={{marginLeft: '8px'}} onClick={onInfoClick} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M12 10C11.4 10 11 10.4 11 11V16C11 16.6 11.4 17 12 17C12.6 17 13 16.6 13 16V11C13 10.4 12.6 10 12 10Z"
                    fill="url(#paint0_linear_2356_6480)"/>
                <path
                    d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
                    fill="url(#paint1_linear_2356_6480)"/>
                <path
                    d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20Z"
                    fill="url(#paint2_linear_2356_6480)"/>
                <defs>
                    <linearGradient id="paint0_linear_2356_6480" x1="11.8409" y1="10" x2="13.6967" y2="10.1526"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#4462EF"/>
                        <stop offset="1" stop-color="#1B3DD8"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_2356_6480" x1="11.8409" y1="7" x2="13.5663" y2="7.49662"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#4462EF"/>
                        <stop offset="1" stop-color="#1B3DD8"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_2356_6480" x1="10.4091" y1="2" x2="27.6626" y2="6.96624"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#4462EF"/>
                        <stop offset="1" stop-color="#1B3DD8"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export default CheckboxWithTitle;
