import React, {useState} from 'react'
import {ReactComponent as BackArrow} from "../../assets/arrow-back.svg";
import {ReactComponent as SeekLogo} from "../../assets/seek-logo.svg";
import CustomComponent from "../../components/componentWithBanner/CustomComponent";
import imageUrl from "../../assets/blue-banner.svg";
import {ReactComponent as SilverBadge} from "../../assets/silver_badge.svg";
import {ReactComponent as Infinity} from "../../assets/infinity.svg";
import {ReactComponent as DailyPoints} from "../../assets/daily-karma-points.svg";
import './KarmaPoints.css'
import WeekRangeSelector from "../../components/weekRangeSelector/WeekRangeSelector";
import KarmaPointsRow from "./components/KarmaPointsRow";
import {getKarmaPoints} from "../../services/Firebase";
import Loader from "../../components/loader/Loader";
import {createArrayOfDatesWithPoints, createKarmaHistory} from "../../utils/utils";
import { useLocation, useNavigate} from "react-router-dom";
import {Mixpanel} from "../../analytics/Mixpanel";
import useAuthenticatedUsers from "../../hooks/useAuthenticatedUsers";


const KarmaPoints = () => {
    const userData = useAuthenticatedUsers();
    const [karmaPoints,setKarmaPoints] = useState<any>();
    const [loader,setLoader] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const total_karma = location.state ? location.state?.total_karma :0;
    const onDateChangeHandler = async (parsedStartDate:string,parsedEndDate:string) => {
        setLoader(true)
        const karmaPoints = await getKarmaPoints(userData?.uid,parsedStartDate,parsedEndDate)
        if(karmaPoints?.data?.length === 0){
            const resultArray = createArrayOfDatesWithPoints(new Date(parsedStartDate), new Date(parsedEndDate));
            setKarmaPoints(resultArray)
            setLoader(false)
            return;
        }
        Mixpanel.track('view_karma_points_details',{
            event_action:'viewed',
            current_week_range_shown:{
                startDate:parsedStartDate,
                endDate:parsedEndDate
            },
            total_karma_points:total_karma || 0,
            event_page:'karma_points_screen',
        });
        setKarmaPoints(createKarmaHistory(parsedStartDate,parsedEndDate,karmaPoints?.data))
        setLoader(false)
    }


    return (
        <div className={'karma-points-container'}>
            <div className={'horizontal-container'} style={{justifyContent:'space-between',alignItems:'center'}}>
                <BackArrow onClick={navigate.bind(null,-1)} width={28} height={28} />
                <SeekLogo style={{marginRight:'28px'}} width={84} height={72} />
                <div/>
            </div>

            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <CustomComponent  imageUrl={imageUrl}>
                    <div style={{display:'flex',alignSelf:'flex-start',justifyContent:'center',alignItems:'center',marginLeft:'25px'}}>
                        <SilverBadge width={36} height={36} />
                        <span className={'karma-points-header'}>{total_karma||0}</span>
                    </div>
                    <span className={'karma-points-subheader'}>{'Total Karma Points.'}</span>
                </CustomComponent>
            </div>

            <div style={{marginTop:'32px'}}>
                <span className={'karma-points-guide'}>{'Guide to Karma Points'}</span>
                <div style={{marginTop:'12px'}}>
                    <DailyPoints  width={36} height={36} />
                    <span className={'karma-points-guide-detail'}>{'1 daily attendance = 10 Karma Points.'}</span>
                </div>

                <div style={{marginTop:'12px'}}>
                    <DailyPoints  width={36} height={36} />
                    <span className={'karma-points-guide-detail'}>{'1 Referral = 50 Karma Points'}</span>
                </div>


                <div style={{ display: 'flex', alignItems: 'center', marginTop: '19px' }}>
                    <Infinity width={36} height={36} />
                    <span className={'karma-points-guide-detail'}>
                          {'Karma points don\'t expire or get reset ever'}
                     </span>
                </div>

            </div>

            <div style={{marginTop:'32px',width:'343px'}}>
                <span className={'karma-points-guide'}>{'Karma Points History'}</span>
                <WeekRangeSelector onDateChange={onDateChangeHandler} />
                {loader?<Loader style={{marginTop:'160px'}}/>:karmaPoints?.map((point:any) => <KarmaPointsRow key={point?.date} date={point?.date} points={point?.points}/>)}
            </div>


        </div>
    )
}

export default KarmaPoints;
