import React, {useEffect, useState} from 'react'
import {Container} from "react-bootstrap";
import './PocBookSlotPage.css'
import {generateTimeOptions} from "../../utils/utils";
import BottomContainer from "../../components-v2/buttons/BottomContainer";
import {toast} from "react-toastify";
import {
    updatePhone,
    updateSelectedTimeSlot,
    updatesSelectedDateOptionIndex
} from "../../store/features/PocCounsellingSlice";
import {useDispatch, useSelector} from "react-redux";

interface PocBookSlotPageProps {
    onContinueClickHandler: (slot: string,date:number) => void,
}

type SlotOption = {
    id: number;
    label: string;
};

type Row = SlotOption[];

const rows: Row[] = [
    [{id: 1, label: 'Today'}, {id: 2, label: 'Tomorrow'}],
];


const PocBookSlotPage: React.FC<PocBookSlotPageProps> = ({
                                                             onContinueClickHandler,
                                                         }) => {


    const dispatch = useDispatch();
    const selectedTime = useSelector((state: any) => state.pocFormReducer?.pocForm?.selectedTime);
    const selectedOption = useSelector((state: any) => state.pocFormReducer?.pocForm?.selectedDateOptionIndex);
    const handleOptionClick = (optionId: number) => {
        dispatch(updatesSelectedDateOptionIndex(optionId));
    };
    const timeOptions = generateTimeOptions(selectedOption);
    const handleTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch(updateSelectedTimeSlot(event.target.value));
    };



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const onClickHandler = () =>{
        if(!selectedOption){
            toast.error(`Please select day`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true,
            });
            return;
        }else if(!selectedTime){
            toast.error(`Please select time slot`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true,
            });
            return;
        }
        onContinueClickHandler(selectedTime,selectedOption);
    }


    return (
        <Container className={'poc-book-slot-container'}>
            <span className={'poc-book-slot-heading-title'}>Book your slot with the expert</span>
            <div style={{width: '95%',marginTop:'24px'}}>
                {rows.map((row, rowIndex) => (
                    <div key={rowIndex} style={{
                        display: 'flex',
                        width: '100%',
                        marginTop: '12px',
                        gap: '12px',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {row.map((option) => (
                            <div
                                key={option.id}
                                onClick={() => handleOptionClick(option.id)}
                                className={selectedOption === option.id ? 'selected-slot' : 'un-selected-slot'}
                            >
                                <span className={'selected-slot-text'}>{option.label}</span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div style={{width: '90%', maxWidth: '400px', marginTop: '24px'}}>
                <span className={'poc-slot-form-title'}>Timing</span>
            </div>

            <select id="time-select" className="slot-custom-select" value={selectedTime || ''}
                    onChange={handleTimeChange}>
                <option value="" disabled>Select a time</option>
                {timeOptions.map((time, index) => (
                    <option className="slot-custom-option" key={index} value={time}>
                        {time}
                    </option>
                ))}
            </select>

            <BottomContainer
                onButtonClick={onClickHandler}
                label="Continue"
            />

        </Container>
    )
}

export default PocBookSlotPage
