import React from 'react';
import Modal from '../../components/model/Model';
import { ReactComponent as PaymentSuccess } from '../../assets/payment-success.svg';
import './Model.css';

interface PlanVariant {
	name: string;
}

interface PaymentSuccessModalProps {
	paymentSuccessPopup: boolean;
	setPaymentSuccessPopup: (show: boolean) => void;
	selectedPlanVariant?: PlanVariant;
}

const PaymentSuccessModal: React.FC<PaymentSuccessModalProps> = ({
	paymentSuccessPopup,
	setPaymentSuccessPopup,
	selectedPlanVariant,
}) => {
	return (
		<Modal
			show={paymentSuccessPopup}
			showHeader={true}
			onHide={() => setPaymentSuccessPopup(false)}
			title={''}
			content={
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<PaymentSuccess width={120} height={120} />
					<span className={'payment-success-hurray'}>
						Payment Successful 🎉
					</span>
					<span className={'payment-success-plan-description'}>
						You have successfully bought{' '}
						{selectedPlanVariant ? (
							<span>{selectedPlanVariant.name}</span>
						) : (
							'a plan'
						)}
					</span>
				</div>
			}
		/>
	);
};

export default PaymentSuccessModal;
