import React, {useCallback} from 'react'
import "./css/SettingsListContainer.css"
import SettingsListItem from "../../components-v2/settings/SettingListItem";
import {ReactComponent as WhatsAppIcon} from "../../assets/new-icons/whatsapp.svg";
import {ReactComponent as NotificationPreferencesIcon} from "../../assets/new-icons/notification-preferences.svg";
import {ReactComponent as ReferFriendIcon} from "../../assets/new-icons/refer-friends.svg";
import {ReactComponent as HelpCenterIcon} from "../../assets/new-icons/help-center.svg";
import {ReactComponent as RightArrow} from "../../assets/right-slide.svg";
import {Mixpanel} from "../../analytics/Mixpanel";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import {useDispatch, useSelector} from "react-redux";
import {updateEventsStatus} from "../../store/features/EventsSlices";

const SettingsListContainer = ({onClickWhatApp, onClickMenu,manageNotificationRemindersHandler}: any) => {
    const dispatch = useDispatch();
    const view_manage_whatsapp_reminder = useSelector((state: any) => state?.events?.eventsLog?.view_manage_whatsapp_reminder);
    const logViewEvent = useCallback(() => {
        if (!view_manage_whatsapp_reminder) {
            Mixpanel.track('view_manage_whatsapp_reminder', {
                event_action: 'viewed',
                event_page: 'profile',
            });
            Mixpanel.track('view_refer_a_friend', {
                event_action: 'viewed',
                event_page: 'profile',
            });
            dispatch(updateEventsStatus({key: 'view_manage_whatsapp_reminder', value: true}))
        }
    }, [view_manage_whatsapp_reminder]);


    const cardRef = useIntersectionObserver(logViewEvent, {threshold: 0.5});
    return (
        <div ref={cardRef} className={'settings-list-container'}>
            <SettingsListItem onClick={onClickWhatApp} title={'Manage Class Reminders'}
                              LeftIcon={WhatsAppIcon} RightIcon={RightArrow}/>
            <svg xmlns="http://www.w3.org/2000/svg" width="315" height="2" viewBox="0 0 315 2" fill="none">
                <path d="M0 1H315" stroke="#F4F4F4"/>
            </svg>

            <SettingsListItem onClick={manageNotificationRemindersHandler}
                              title={'Notification Preferences'}
                              LeftIcon={NotificationPreferencesIcon} RightIcon={RightArrow}/>
            <svg xmlns="http://www.w3.org/2000/svg" width="315" height="2" viewBox="0 0 315 2" fill="none">
                <path d="M0 1H315" stroke="#F4F4F4"/>
            </svg>


            <SettingsListItem onClick={onClickMenu.bind(null, 'My Referrals')} title={'Refer a Friend'}
                              LeftIcon={ReferFriendIcon} RightIcon={RightArrow}/>
            <svg xmlns="http://www.w3.org/2000/svg" width="315" height="2" viewBox="0 0 315 2" fill="none">
                <path d="M0 1H315" stroke="#F4F4F4"/>
            </svg>
            <SettingsListItem onClick={onClickMenu.bind(null, 'Contact us')} title={'Help Center'}
                              LeftIcon={HelpCenterIcon} RightIcon={RightArrow}/>
        </div>
    )
}

export default SettingsListContainer
