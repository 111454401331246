import React, {useEffect} from 'react'
import {ReactComponent as BackArrow} from "../assets/arrow-back.svg";
import {useNavigate} from "react-router-dom";
import '../screens/HowToJoinLiveClass.css'
import Card from "../components/card/CustomCard";
import timingBannerImage from '../assets/webp-images/class_timing_baneer.webp';

// import {ReactComponent as WhatsAppReminder} from "../assets/whatsapp_reminder.svg";
import whatsappReminderImage from "../assets/webp-images/whatsapp_reminder.webp";


import joinLiveClass from "../assets/webp-images/join-live-class.webp";
import Button from "../components/button/Button";
import {COLORS} from "../utils/colors";

interface HowToJoinLiveClassProps {
    continueHandler:()=>void,
    parent?:string,
}

const HowToJoinLiveClass:React.FC<HowToJoinLiveClassProps> = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {continueHandler,parent} = props;
    const navigate = useNavigate();
    return (
        <div className={'how-to-join-container'}>
            <div className={'horizontal-container'} style={{justifyContent:'space-between',alignItems:'center'}}>
                {!parent && <BackArrow onClick={navigate.bind(null,-1)} width={24} height={24}/>}
                <div style={{
                    color: "#1A1A1A",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "17px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "24px",
                    marginLeft:parent?'72px':'0px',
                }}>{'How to Join Live Class?'}</div>
                <div/>
            </div>
            <Card style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'28px',height:'224px'}}>

                {/*<TimingBannerCard style={{marginLeft:'13%',marginTop:'14px'}} width={257}*/}
                {/*                  height={94}/>*/}
                <img src={timingBannerImage} alt="Timing Banner" style={{ marginLeft: '13%', marginTop: '14px', width: '257px', height: '94px' }} />


                <p className={'choose-slot-title'} style={{marginTop:'6px'}}>Choose Any Slot</p>
                <p className={'choose-slot-subtitle'}>Join based on your convenience from the</p>
                <p className={'choose-slot-subtitle'} style={{marginTop:'-14px'}}>above class schedule.</p>
            </Card>

            <Card style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'12px',height:'224px'}}>
                <img src={joinLiveClass} style={{ alignSelf: 'center', width:257, height: 94,marginLeft:'13%',marginTop:'14px' }} alt="Landing Banner" loading="lazy" />
                <p className={'choose-slot-title'} style={{marginTop:'6px'}}>Join <div style={{
                    color: "#534DE0",
                    fontFamily: "Lora-SemiBold",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "28px"
                }}>LIVE</div> Class</p>
                <p className={'choose-slot-subtitle'}>By logging-in at least 5 mins before the</p>
                <p className={'choose-slot-subtitle'}  style={{marginTop:'-14px'}}>time and tap <div style={{
                    color: "#534DE0",
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px"
                }}>'Join Live Session'.</div></p>
            </Card>

            <Card style={{display:'flex',justifyContent:'center',alignItems:'center',marginBottom:'72px',marginTop:'12px',height:'224px'}}>

                {/*<WhatsAppReminder style={{marginLeft:'13%',marginTop:'14px'}} width={257}*/}
                {/*                  height={94}/>*/}

                <img
                    src={whatsappReminderImage}
                    alt="WhatsApp Reminder"
                    style={{ marginLeft: '13%', marginTop: '14px', width: '257px', height: '94px' }}
                />

                <p className={'choose-slot-title'}>Get WhatsApp <div style={{
                    color: "#534DE0",
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "28px"
                }}>Reminders</div></p>
                <p className={'choose-slot-subtitle'}>15 mins before your preferred time and don't</p>
                <p className={'choose-slot-subtitle'} style={{marginTop:'-14px'}}>miss your daily dose of mindfulness.</p>
            </Card>
            {parent && <Button isSticky={true} style={{marginTop:'32px',marginBottom:'32px',alignSelf:'center',backgroundColor:COLORS.PRIMARY}} onClick={continueHandler} label={'Continue'}/>}
        </div>
    )
}


export default HowToJoinLiveClass;
