import React, { ChangeEvent, useEffect, useState } from 'react';
import ErrorComponent from '../../components/error/Error';
import Button from '../../components/button/Button';
import { COLORS } from '../../utils/colors';
import PhoneInput from '../../components/phoneInput/PhoneInput';
import './Model.css';
import BottomSheet from '../../components/model/BottomSheet';
import { Mixpanel } from '../../analytics/Mixpanel';
import { events, events_action } from '../../analytics/Events';
import { resendOtp } from '../../services/Firebase';
import Success from '../../components/success/Success';
import Timer from '../../components/countdownTimer/Timer';
import InputOTP from '../inputs/InputOTP';
import PrimaryButton from '../buttons/PrimaryButton';

interface LoginModalProps {
	showEnrollNowCta: boolean;
	showOtpPopup: boolean;
	setShowOtpPopup: (show: boolean) => void;
	showLoginPopup: boolean;
	setShowLoginPopup: (show: boolean) => void;
	error: string;
	setError: any;
	closeErrorPopup: () => void;
	phone: string;
	onEnrollNowClickHandler: () => void;
	loading?: boolean;
	handlePhoneChange: (
		value: string,
		country: string,
		e: ChangeEvent<HTMLInputElement>,
		formattedValue: string
	) => void;
	onLoginSubmitHandler: (phone: string) => void;
	customInputStyle?: React.CSSProperties;
	onOtpVerifyHandler: (phone: string, otp: string) => void;
	page: string;
}

const LoginModal: React.FC<LoginModalProps> = ({
	page,
	setError,
	showEnrollNowCta,
	onEnrollNowClickHandler,
	showOtpPopup,
	setShowOtpPopup,
	onOtpVerifyHandler,
	showLoginPopup,
	setShowLoginPopup,
	error,
	closeErrorPopup,
	phone,
	handlePhoneChange,
	onLoginSubmitHandler,
	loading,
	customInputStyle,
}) => {
	const [isTimerOver, setIsTimerOver] = useState(false);
	const [otpResend, setOtpResend] = useState(false);
	const [initialTimer, setInitialTimer] = useState(60);
	const [otpValues, setOtpValues] = useState<string[]>([]);
	const [loader, setLoader] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);

	useEffect(() => {
		if (showOtpPopup) {
			setLoader(false);
		}
	}, [showOtpPopup]);

	useEffect(() => {
		if (loading) setLoader(loading);
	}, [loading]);

	const onClickLogin = async () => {
		setLoader(true);
		await onLoginSubmitHandler(phone);
		// setLoader(false)
	};
	const onResendOtpHandler = () => {
		if (!isTimerOver) {
			return;
		}
		Mixpanel.track(events.click_resend_otp, {
			event_page: page,
			event_action: events_action.clicked,
		});
		setOtpResend(true);
		setTimeout(() => {
			setOtpResend(false);
		}, 2000);
		setInitialTimer(60);
		setIsTimerOver(false);
		// make api call to resend otp
		resendOtp(phone).then();
	};

	async function onOTPVerify() {
		setLoader(true);
		Mixpanel.track('click_verify_otp', {
			event_page: page,
			event_action: events_action.clicked,
		});
		if (otpValues?.length === 0) {
			setLoader(false);
			setError('Please enter the correct otp');
			setTimeout(() => {
				setError('');
			}, 2000);
			return;
		}
		await onOtpVerifyHandler(phone, otpValues.join(''));
		setLoader(false);
	}

	const onClickEnrollNowCta = () => {
		Mixpanel.track('click_enroll_now', {
			event_page: page,
			event_action: events_action.clicked,
		});
		onEnrollNowClickHandler();
	};

	useEffect(() => {
		if (otpValues.length !== 6 || loader || loading) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
			otpValues.forEach((digit) => {
				if (!digit.length) {
					setIsDisabled(true);
				}
			});
		}
	}, [otpValues, loader, loading]);

	if (showOtpPopup) {
		return (
			<BottomSheet
				customStyle={{ height: '48%' }}
				show={showOtpPopup}
				showHeader={false}
				onHide={setShowOtpPopup.bind(null, false)}
				title={''}
				content={
					<div className={'login-form-popup-container'}>
						{otpResend && !error && (
							<Success
								customStyles={{
									position: 'absolute',
									top: 10,
									left: 10,
									right: 10,
								}}
								message={`An OTP has been re-sent on the given mobile number ${phone}`}
							/>
						)}
						{loader && (
							<div className='popup-small-loader'>
								<div className='popup-loader-circle'></div>
							</div>
						)}
						{error && (
							<ErrorComponent
								onClose={closeErrorPopup}
								customStyles={{
									position: 'fixed',
									justifyContent: 'flex-start',
									alignItems: 'center',
									top: 10,
									left: 10,
									right: 10,
								}}
								message={error}
							/>
						)}
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
								width: '100%',
								maxWidth: '400px',
								alignItems: 'center',
								justifyContent: 'center',
							}}>
							<span className={'otp-title'}>Verify your number</span>
							<span
								style={{
									marginBottom: '24px',
								}}
								className={'otp-subtitle'}>
								{`Please enter the 6-digit code sent on `}
								<span>{'+' + phone}</span>
							</span>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									width: '100%',
									// marginTop: '24px'
								}}>
								<InputOTP setOtpValues={setOtpValues} otpValues={otpValues} />
								<span className={'otp-resend'}>
									Didn’t get the OTP?{' '}
									{isTimerOver ? (
										<span
											onClick={onResendOtpHandler}
											style={{
												marginLeft: '5px',
												fontSize: '14px',
												fontFamily: 'Gordita-Bold',
												fontWeight: '700',
												color: '#4462EF',
												textDecoration: 'underline',
											}}>
											Resend It{' '}
										</span>
									) : (
										<span
											onClick={onResendOtpHandler}
											style={{
												marginLeft: '5px',
												fontSize: '14px',
												fontFamily: 'Gordita-Bold',
												fontWeight: '700',
												textDecoration: 'underline',
											}}>
											Resend It{' '}
											<Timer
												onTimerComplete={setIsTimerOver.bind(null, true)}
												initialTime={initialTimer}
											/>
										</span>
									)}
								</span>
							</div>
						</div>
						{/* <Button
							isSticky={true}
							style={{
								backgroundColor: COLORS.PRIMARY,
								alignSelf: 'center',
								marginBottom: '24px',
							}}
							onClick={onOTPVerify}
							label={'Continue'}
							disabled={isDisabled}
						/> */}
						<PrimaryButton
							isSticky={true}
							disabled={isDisabled}
							style={{
								backgroundColor: isDisabled
									? COLORS.LIGHT_GREY
									: COLORS.PRIMARY,
								alignSelf: 'center',
								marginBottom: '24px',
								width: '327px',
								height: '50px',
							}}
							textStyle={{ fontSize: '16px' }}
							onClick={onOTPVerify}>
							{'Verify'}
						</PrimaryButton>
					</div>
				}
			/>
		);
	}

	return (
		<BottomSheet
			customStyle={{ height: '48%' }}
			show={showLoginPopup}
			showHeader={false}
			onHide={setShowLoginPopup.bind(null, false)}
			title={''}
			content={
				<div className={'login-form-popup-container'}>
					{loader && !showOtpPopup && (
						<div className='popup-small-loader'>
							<div className='popup-loader-circle'></div>
						</div>
					)}
					{error && (
						<ErrorComponent
							onClose={closeErrorPopup}
							customStyles={{
								position: 'fixed',
								justifyContent: 'flex-start',
								alignItems: 'center',
								top: 10,
								left: 10,
								right: 10,
							}}
							message={error}
						/>
					)}
					<div
						className={'login-form-title'}
						style={{ textAlign: 'center', width: '100%', marginTop: '8px' }}>
						Login to your account!
					</div>
					<span className={'form-title margin-top-40px'}>WhatsApp Number</span>
					<PhoneInput
						phoneNumber={phone}
						defaultCountry='in'
						onPhoneChange={handlePhoneChange}
						inputStyle={customInputStyle}
					/>
					<Button
						style={{
							alignSelf: 'center',
							marginTop: '36px',
							backgroundColor: COLORS.PRIMARY,
						}}
						onClick={onClickLogin}
						label={'Get OTP'}
					/>
					{showEnrollNowCta && (
						<span
							onClick={onClickEnrollNowCta}
							className={'login-form-popup-signup-text margin-top-10px'}>
							New to Seek? <span>Enrol Now</span>
						</span>
					)}
				</div>
			}
		/>
	);
};

export default LoginModal;
