import React from 'react';
import './BackButton.css';
import { ReactComponent as BackArrow } from '../../assets/left_arrow_black.svg';

function BackButton({ onClick, isDark }: any) {
	return (
		<div
			onClick={onClick}
			className={
				isDark ? 'back-button-container dark' : 'back-button-container'
			}>
			<BackArrow
				style={{
					filter: isDark
						? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)'
						: '',
				}}
				width={32}
				height={32}
			/>
			<span className={'back-button-title'}>{'Back'}</span>
		</div>
	);
}

export default BackButton;
