import React from 'react'
import './SlotChip.css'

interface SlotChipProps {
    title: string,
    isAvailable: boolean,
    isLive: boolean,
}

const SlotChip: React.FC<SlotChipProps> = ({title, isAvailable, isLive}) => {
    return (
        <div className={`slot-chip-container`}>
            {
                <div style={{opacity: isLive ? '1' : '0',marginTop:'5px',display:'flex',alignSelf:'center',justifyContent:'center', textAlign:'center',height:'100%'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path
                            d="M8.12492 5.83337C6.92832 5.83337 5.95825 6.80344 5.95825 8.00004C5.95825 9.19664 6.92832 10.1667 8.12492 10.1667C9.32152 10.1667 10.2916 9.19664 10.2916 8.00004C10.2916 6.80344 9.32152 5.83337 8.12492 5.83337Z"
                            fill="#EE4D4D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.88075 4.97489C6.07873 5.16738 6.08318 5.48393 5.89069 5.68192C4.64744 6.96064 4.64744 9.03944 5.89069 10.3182C6.08318 10.5162 6.07873 10.8327 5.88075 11.0252C5.68275 11.2177 5.36621 11.2132 5.17371 11.0152C3.5531 9.34844 3.5531 6.65165 5.17371 4.98483C5.36621 4.78684 5.68275 4.78239 5.88075 4.97489ZM10.3691 4.97489C10.5671 4.78239 10.8837 4.78684 11.0761 4.98483C12.6967 6.65165 12.6967 9.34844 11.0761 11.0152C10.8837 11.2132 10.5671 11.2177 10.3691 11.0252C10.1711 10.8327 10.1667 10.5162 10.3592 10.3182C11.6024 9.03944 11.6024 6.96064 10.3592 5.68192C10.1667 5.48393 10.1711 5.16738 10.3691 4.97489Z"
                              fill="#EE4D4D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M4.47184 3.64675C4.66693 3.84219 4.66666 4.15877 4.47123 4.35386C2.45393 6.36767 2.45393 9.63233 4.47123 11.6461C4.66666 11.8413 4.66693 12.1578 4.47184 12.3533C4.27675 12.5487 3.96017 12.5489 3.76473 12.3539C1.35609 9.9494 1.35609 6.05061 3.76473 3.64614C3.96017 3.45105 4.27675 3.45132 4.47184 3.64675ZM11.778 3.64675C11.9731 3.45132 12.2897 3.45105 12.4851 3.64614C14.8937 6.05061 14.8937 9.9494 12.4851 12.3539C12.2897 12.5489 11.9731 12.5487 11.778 12.3533C11.5829 12.1578 11.5832 11.8413 11.7786 11.6461C13.7959 9.63233 13.7959 6.36767 11.7786 4.35386C11.5832 4.15877 11.5829 3.84219 11.778 3.64675Z"
                              fill="#EE4D4D"/>
                    </svg>
                </div>

            }
            <span className={`slot-title ${isAvailable && 'available'}`}>{title}</span>
        </div>
    )
}

export default SlotChip
