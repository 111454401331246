import React, {useEffect} from 'react';
import { Container } from 'react-bootstrap';
import './CourseNotEnrolled.css'
import not_enrolled_url from "../../../assets/not_enrolled.svg"
import {ReactComponent as ContactCourse} from "../../../assets/contact_course.svg";
import {ReactComponent as CLose} from "../../../assets/close_blue.svg";

interface WelcomePageProps {
    onEnrollNowHandler:() =>void;
    onBackArrowClick:() =>void;
}

const CourseNotEnrolled:React.FC<WelcomePageProps> = ({onEnrollNowHandler,onBackArrowClick}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container className={'course-not-enrolled-page-container'}>
            <img src={not_enrolled_url} alt="Your Image" style={{ width: '288px', height: '288px', marginTop: '-8px',alignSelf:'center' }} />
            <CLose onClick={onBackArrowClick} style={{position: 'absolute', top: 12, right: 24}} width={36}
                   height={36}/>
            <span className={'course-not-enrolled-title'}>Not Enrolled!</span>
            <span className={'course-not-enrolled-subtitle'}>
                We see your interest in the course, but we noticed you haven't registered yet.
            </span>
            {/*<span className={'course-not-enrolled-subtitle_two'} style={{marginTop:'12px'}}>*/}
            {/*    Take the next step and enroll today.*/}
            {/*</span>*/}
            <ContactCourse width={'auto'} height={76} style={{alignItems:'center',justifyContent:'center', alignSelf: 'center',marginTop:'24px',width:'100%'}}/>
            {/*{*/}
            {/*     <Button isSticky={false} style={{ alignSelf: 'center',marginTop:'24px' }} onClick={onEnrollNowHandler} label={'Enroll Now'}></Button>*/}
            {/*}*/}
        </Container>
    );
};

export default CourseNotEnrolled;
