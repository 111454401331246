import React, { CSSProperties, useEffect } from 'react';
import './PrimaryButton.css';
import { Mixpanel } from '../../analytics/Mixpanel';

interface PrimaryButtonProps {
	onClick?: () => void;
	className?: string;
	children: any;
	disabled?: boolean;
	isSticky?: boolean;
	style?: CSSProperties;
	textStyle?: CSSProperties;
	page?: string;
	plan?: any;
	phone?: any;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
	style,
	isSticky,
	onClick,
	className,
	children,
	disabled,
	textStyle,
	page,
	plan,
	phone,
}) => {
	const buttonStyle: CSSProperties = {
		background: disabled
			? '#D6D6D6'
			: 'linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%)',
		...style,
	};

	if (isSticky) {
		// Add sticky styles
		buttonStyle.position = 'fixed';
		buttonStyle.bottom = '10px'; // Adjust the distance from the bottom as needed
		buttonStyle.left = '50%';
		buttonStyle.transform = 'translateX(-50%)';
	}

	useEffect(() => {
		if (page === 'payment_successful_screen' && phone && plan) {
			Mixpanel.track('view_continue_cta_payment_successful', {
				event_action: 'viewed',
				plan_duration: plan?.duration,
				phone: phone,
				event_page: 'payment_successful_screen',
			});
		}
	}, [page, phone, plan]);

	return (
		<button
			className={`primary-button ${className} ${
				disabled ? 'primary-button-disabled' : ''
			}`}
			onClick={disabled ? undefined : onClick}
			disabled={disabled}
			style={buttonStyle}>
			<span
				className={`primary-button-title ${disabled ? 'disabled' : ''}`}
				style={textStyle}>
				{children}
			</span>
		</button>
	);
};

export default PrimaryButton;
