import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data:{
        loading:true,
        leaderboardUsers:[],
        topFourLeaderboardUsers:[],
        globalLeaderBoard:[],
        topFourGlobalLeaderboardUsers:[],
        classPresentCount:-1,
        classAttendanceOfTheWeek:-1,
        reloadAttendance:false,
    }
}

export const leaderboardUsers = createSlice({
    name:'leaderboard',
    initialState,
    reducers:{
        setLeaderboardUsers:(state,action)=>{
            // console.log("addLeaderboardUsers state",state);
            // console.log("addLeaderboardUsers action",action);
            state.data.loading = false;
            state.data.leaderboardUsers = action.payload;
        },
        setTopFourLeaderboardUsers:(state,action)=>{
            // console.log("setTopFourLeaderboardUsers state",state);
            // console.log("setTopFourLeaderboardUsers action",action);
            state.data.loading = false;
            state.data.topFourLeaderboardUsers = action.payload;
        },
        setGlobalLeaderboardUsers:(state,action)=>{
            // console.log("setGlobalLeaderboardUsers state",state);
            // console.log("setGlobalLeaderboardUsers action",action);
            state.data.loading = false;
            state.data.globalLeaderBoard = action.payload;
        },
        setTopFourGlobalLeaderboardUsers:(state,action)=>{
            // console.log("setTopFourGlobalLeaderboardUsers state",state);
            // console.log("setTopFourGlobalLeaderboardUsers action",action);
            state.data.loading = false;
            state.data.topFourGlobalLeaderboardUsers = action.payload;
        },

        setClassPresentCount:(state,action)=>{
            // console.log("setTopFourGlobalLeaderboardUsers state",state);
            // console.log("setTopFourGlobalLeaderboardUsers action",action);
            state.data.loading = false;
            state.data.classPresentCount = action.payload;
        },
        setClassAttendanceOfTheWeek:(state,action)=>{
            // console.log("setTopFourGlobalLeaderboardUsers state",state);
            // console.log("setTopFourGlobalLeaderboardUsers action",action);
            state.data.loading = false;
            state.data.classAttendanceOfTheWeek = action.payload;
        },
        setReloadAttendance:(state,action)=>{
            // console.log("setTopFourGlobalLeaderboardUsers state",state);
            // console.log("setTopFourGlobalLeaderboardUsers action",action);
            state.data.reloadAttendance = action.payload;
        },

    }
})

export const {setLeaderboardUsers,setTopFourLeaderboardUsers,setClassPresentCount,setClassAttendanceOfTheWeek,setReloadAttendance,setGlobalLeaderboardUsers,setTopFourGlobalLeaderboardUsers} = leaderboardUsers.actions;

export default leaderboardUsers.reducer
