import React, {useState} from 'react';
import './css/UpgradeBanner.css';
import PrimaryButton from '../buttons/PrimaryButton';
import {Card} from "react-bootstrap";
import {Mixpanel} from "../../analytics/Mixpanel";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

interface UpgradeBannerProps {
    title: string;
    subtitle: string;
    ctaText: string;
    onCtaClick?: () => void;
    customStyle?:any;
    daysToExpire:number;
}

const UpgradeBanner: React.FC<UpgradeBannerProps> = ({customStyle,daysToExpire, title, subtitle, ctaText, onCtaClick }) => {
    const [eventLogged, setEventLogged] = useState(false);  // State to track if event is logged
    const logViewEvent = () => {
        if (!eventLogged) {
            // console.log("logViewEvent UpgradeBanner",title)
            Mixpanel.track('view_buy_now_banner', {
                event_action: 'viewed',
                user_type:'',
                event_page: 'live_classes',
            });
            setEventLogged(true);  // Update the state to indicate event has been logged
        }
    };

    const cardRef = useIntersectionObserver(logViewEvent, { threshold: 0.5 });

    if(daysToExpire > 0){
        return null;
    }

    return (
        <Card ref={cardRef} className="upgrade-banner-card" style={customStyle}>
            <span className="upgrade-banner-title">{title}</span>
            <svg className={"upgrade-banner-animation"} xmlns="http://www.w3.org/2000/svg" width="180" height="43" viewBox="0 0 180 43" fill="none">
                <g>
                    <path d="M101.322 9.86589H117.987C117.557 9.81359 116.437 9.67536 115.317 9.53711C115.407 9.41755 115.509 9.29799 115.599 9.18217C126.63 10.049 137.705 10.8411 148.644 11.8087C153.95 12.2795 159.109 12.9894 164.098 13.7516C173.488 15.19 177.074 17.9847 178.929 21.1156C179.268 21.6872 179.438 22.2701 179.676 22.8492C181.667 27.5941 174.404 29.9892 160.24 32.9145C159.346 33.0976 158.181 33.1498 157.378 33.3591C144.572 36.7254 129.107 37.6221 113.631 38.4515C87.5206 39.8489 61.9421 39.1091 37.212 35.9596C29.8812 35.0255 22.6636 33.9195 15.8758 32.6193C5.55846 30.6503 1.0446 27.3962 0.173506 23.5852C-1.44424 16.4976 8.29616 10.9382 27.4942 7.71759C40.21 5.58795 53.8194 4.56797 67.9043 4.27281C81.5247 3.98886 95.2133 3.67875 108.834 3.79831C123.315 3.92534 137.671 4.70621 151.054 6.7275C152.921 7.0077 154.357 7.5943 155.478 8.24065C146.721 7.81098 137.988 7.35144 129.22 6.95914C124.819 6.76112 120.362 6.64157 115.927 6.56309C94.4896 6.182 73.2889 6.71628 52.575 8.64789C45.5949 9.29799 38.7054 10.1648 32.2004 11.2221C16.0908 13.8375 7.34591 19.24 9.1899 25.0237C9.78949 26.888 12.3462 28.3376 17.3352 29.2829C41.511 33.8673 67.508 35.8212 95.3153 35.1824C109.321 34.8611 123.225 34.3529 136.506 32.7164C146.495 31.4836 155.998 29.9629 164.042 27.598C172.842 25.0087 174.585 22.0758 169.144 18.5937C168.125 17.9398 166.213 17.3794 164.347 16.9385C157.163 15.2311 148.35 14.7117 140.533 13.505C128.609 11.6668 115.746 11.4463 103.325 10.4786C102.59 10.4226 101.911 10.2881 101.209 10.1909C101.243 10.0826 101.288 9.97426 101.322 9.86589Z" fill="#FAA53D"/>
                </g>
            </svg>
            <span className="upgrade-banner-subtitle">{subtitle}</span>
            <PrimaryButton className={'upgrade-banner-cta'} onClick={onCtaClick}>
                {ctaText}
            </PrimaryButton>
        </Card>
    );
};

export default UpgradeBanner;
