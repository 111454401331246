// TODO new upcoming code
import React, { useEffect, useState } from 'react';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { ReactComponent as TimeIcon } from '../../assets/course-watch.svg';
import { storage } from '../../services/Firebase';
import './FavouriteClasses.css';

import { Mixpanel } from '../../analytics/Mixpanel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { getDownloadURL, ref } from 'firebase/storage';
import play_icon from '../../assets/new-icons/start-class-cta.svg';
import Header from '../../components-v2/header/Header';
import seek_logo_url from '../../assets/seek-logo.svg';
import BackButton from '../../components-v2/buttons/BackButton';
import KnowYourInstructor from '../instructor/KnowYourInstructor';
import { ReactComponent as LowEffort } from '../../assets/new-icons/easy-difficulty-level.svg';
import { ReactComponent as MediumEffort } from '../../assets/new-icons/medium-difficulty-level.svg';
import { ReactComponent as HighEffort } from '../../assets/new-icons/high-difficulty-level.svg';
import { capitalizeFirstLetter } from '../../utils/utils';
import { ReactComponent as LanguageIcon } from '../../assets/new-icons/language-icon.svg';
import { ReactComponent as SessionInfoThree } from '../../assets/session_info_icon_three.svg';
import { ReactComponent as SessionInfoTwo } from '../../assets/session_info_icon_two.svg';
import { ReactComponent as SessionInfoOne } from '../../assets/session_info_icon_one.svg';
import BestOutOfItIconWithName from '../../components-v2/rows/BestOutOfItIconWithName';

interface TriggeredEvents {
	[key: string]: boolean;
}

const sectionRanges = {
	section1: { start: 0, end: 500, section_heading: 'session_info' },
	section2: { start: 500, end: 700, section_heading: 'know_your_instructor' },
	// Add other sections as needed
} as { [key: string]: { start: number; end: number; section_heading: string } };

interface Props {
	name: string;
	index: number;
}

const SessionInfoIconWithName: React.FC<Props> = ({ name, index }) => {
	if (index > 2) return null;
	if (!name) {
		return null;
	}
	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				height: 'auto',
				flexDirection: 'row',
				alignItems: 'center',
				borderRadius: '12px',
				marginTop: index === 0 ? '18px' : '16px',
				padding: '8px',
				border: '1px solid #E4E4E4', // Assuming purple gradient fallback color is #534DE0
				background: '#fff',
				backdropFilter: 'blur(8px)', // Adjust the blur strength as needed
			}}>
			<div style={{ flex: 1, display: 'flex' }}>
				{index === 0 && (
					<SessionInfoThree
						width={64}
						height={64}
						style={{ marginRight: '8px' }}
					/>
				)}
				{index === 1 && (
					<SessionInfoTwo
						width={64}
						height={64}
						style={{ marginRight: '8px' }}
					/>
				)}
				{index === 2 && (
					<SessionInfoOne
						width={64}
						height={64}
						style={{ marginRight: '8px' }}
					/>
				)}
			</div>
			<div
				style={{
					// Container for the icon with fixed size
					marginRight: '8px',
					flexDirection: 'column',
					display: 'flex',
					flex: 3,
					alignItems: 'flex-start',
				}}>
				{index === 0 && (
					<span className={'favourite-class-highlights-title'}>
						{'Movement/Kriyas'}
					</span>
				)}
				{index === 1 && (
					<span className={'favourite-class-highlights-title'}>
						{'Breathworks'}
					</span>
				)}
				{index === 2 && (
					<span className={'favourite-class-highlights-title'}>
						{'Meditation'}
					</span>
				)}
				<span className={'favourite-class-highlights-subtitle'}>{name}</span>
			</div>
		</div>
	);
};

const FavouriteClasses = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const location = useLocation();
	const calendarItem = location?.state?.favouriteClass;
	const [showPlayer, setShowPlayer] = useState(false);
	const [imageUrl, setImageUrl] = useState('');

	const [bestOutOfIt, setBestOutOfIt] = useState([
		'Use headphones',
		'Find a quiet space where you are not disturbed',
		'Use yoga mat',
		'Keep a glass of water',
	]);

	const formattedDate = moment(
		new Date(
			calendarItem?.streaming_date?._seconds * 1000 +
				calendarItem?.streaming_date?._nanoseconds / 1000000
		)
	).format('DD MMM YY');
	const targetDate = new Date(formattedDate);
	const currentDate = new Date();
	const [specialInstruction, setSpecialInstruction] = useState<any>(null);

	useEffect(() => {
		const modifiedValue =
			calendarItem?.how_to_get_the_best_of_this_class?.list_item_one;
		if (modifiedValue) {
			// setBestOutOfIt(prevArray => [...prevArray, modifiedValue]);
			setSpecialInstruction(modifiedValue);
		}
	}, [calendarItem?.how_to_get_the_best_of_this_class?.list_item_one]);

	const [triggeredEvents, setTriggeredEvents] = useState<TriggeredEvents>({
		section1: false,
		section2: false,
	});

	const markMixPanelEvent = (position: number) => {
		const checkAndTriggerEvent = (
			sectionKey: string,
			start: number,
			end: number
		) => {
			if (
				!triggeredEvents[sectionKey] &&
				position >= start - 10 &&
				position <= end + 10
			) {
				Mixpanel.track('view_favourite_class_page_section', {
					event_action: 'viewed',
					event_section: sectionKey,
					section_heading: sectionRanges[sectionKey].section_heading,
					class_name: calendarItem?.title,
					instructor_name: calendarItem?.instructor,
					event_page: 'favourite_class_page',
				});
				setTriggeredEvents((prevEvents: any) => ({
					...prevEvents,
					[sectionKey]: true,
				}));
			}
		};

		Object.entries(sectionRanges).forEach(([sectionKey, { start, end }]) => {
			checkAndTriggerEvent(sectionKey, start, end);
		});
	};

	useEffect(() => {
		const handleScroll = () => {
			markMixPanelEvent(window.scrollY);
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [triggeredEvents]);

	targetDate.setHours(0, 0, 0, 0);
	currentDate.setHours(0, 0, 0, 0);

	useEffect(() => {
		if (calendarItem?.title) {
			Mixpanel.track('view_favourite_classes_page', {
				event_action: 'viewed',
				class_name: calendarItem?.title,
				instructor_name: calendarItem?.instructor,
				event_page: 'favourite_class',
			});
		}
	}, [calendarItem]);

	useEffect(() => {
		(async () => {
			try {
				const imageRef = ref(storage, calendarItem?.thumbnail);
				const url = await getDownloadURL(imageRef);
				setImageUrl(url);
			} catch (error) {
				console.error('Error fetching image:', error);
			}
		})();
	}, [calendarItem]);

	// const parsedBio = calendarItem?.instructorData?.bio?.split('/');

	const [user, setUser] = useState(location.state ? location.state.user : null);
	const userData = useAuthenticatedUsers(true);

	// useEffect(() => {
	//     if (!user && userData?.email) {
	//         loginUser(userData?.email, userData?.phone).then((userLoginResult) => {
	//             setUser(userLoginResult?.userData)
	//         })
	//     }
	// }, [userData])

	const onPlayIconClickHandler = async () => {
		setShowPlayer(true);
		Mixpanel.track('click_watch_class', {
			event_action: 'clicked',
			class_name: calendarItem?.title,
			instructor_name: calendarItem?.instructor,
			event_page: 'favourite_class',
		});

		Mixpanel.track('view_favourite_class_video', {
			event_action: 'viewed',
			class_name: calendarItem?.title,
			instructor_name: calendarItem?.instructor,
			event_page: 'favourite_class',
		});
	};
	const navigate = useNavigate();
	const parsedClassDescription = calendarItem?.description?.split('/');
	const [readMore, setReadMore] = useState(false);
	const readMoreHandler = () => {
		// Mixpanel.track('read_more', {
		//     event_action: 'clicked',
		//     event_page: 'course_modules_details',
		//     course_homework_name: calendarItem?.subtitle,
		// });
		setReadMore((prevState) => !prevState);
	};

	const onKarmaPointsClickHandler = () => {
		Mixpanel.track('click_karma_points_badge', {
			event_action: 'clicked',
			context_user_id: user?.uid || userData?.uid,
			total_karma_points: user?.total_karma || 0,
			event_page: 'home_screen',
		});

		navigate('/karma', {
			state: { email: user?.email, total_karma: user?.total_karma },
			replace: false,
		});
	};

	return (
		<div className='favourite-class-description-container'>
			<Header
				onClick={onKarmaPointsClickHandler}
				logoUrl={seek_logo_url}
				karma_points={user?.total_karma}
			/>
			<div style={{ width: '90%', maxWidth: '400px', marginTop: '24px' }}>
				<BackButton onClick={navigate.bind(null, -1)} />
			</div>
			<div className={'favourite-class-player-container'}>
				{showPlayer && (
					<div
						style={{
							width: '90vw',
							maxWidth: '400px',
							height: '232px',
							padding: '0',
							position: 'relative',
						}}>
						<iframe
							src={`https://player.vimeo.com/video/${calendarItem?.videoId}?autoplay=1&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
							// src={`https://player.vimeo.com/video/${calendarItem?.videoId}?h=f403fad7cf`}
							frameBorder='0'
							allow='autoplay; fullscreen'
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
							}}
							title='Class 24th Jan'></iframe>
					</div>
				)}

				{imageUrl && !showPlayer && (
					<LazyLoadImage
						src={imageUrl}
						alt='User'
						className={'favourite-image-banner'}
					/>
				)}

				{!showPlayer && (
					<img
						onClick={onPlayIconClickHandler}
						src={play_icon}
						style={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '120px',
							height: '32px',
						}}
						alt='Play Icon'
					/>
				)}
			</div>

			<div className={'favourite-class-body-container'}>
				<span
					style={{
						color: '#1B1625',
						fontFamily: 'Gordita-Medium',
						fontSize: '20px',
						width: '100%',
						fontStyle: 'normal',
						alignSelf: 'start',
						fontWeight: 500,
						marginTop: '18px',
						lineHeight: '28px',
					}}>
					{calendarItem?.title}
				</span>
				<span
					style={{
						color: '#000',
						width: '100%',
						fontFamily: 'Gordita-Regular',
						fontSize: '14px',
						fontWeight: 400,
						alignSelf: 'start',
						marginTop: '6px',
						lineHeight: '20px',
					}}>
					{'By ' + calendarItem?.instructor}
				</span>
				<div className={'favourite-class-subheader-container'}>
					<div className={'favourite-class-subheader-subContainer'}>
						<TimeIcon width={32} height={32} />
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginLeft: '4px',
							}}>
							<span
								className={'favourite-class-subheader-title'}
								style={{
									fontStyle: 'normal',
									fontWeight: '500',
									fontFamily: 'Gordita-Medium',
								}}>
								{'30 '}
								<span
									className={'upcoming-session-subheader-subtitle'}
									style={{ alignSelf: 'center', marginLeft: '2px' }}>
									{' Mins'}
								</span>
							</span>
							<span className={'favourite-class-subheader-subtitle'}>
								{'Duration'}
							</span>
						</div>
					</div>
					<div
						style={{ width: '3px', height: '40px', background: '#ECEFF2' }}
					/>
					<div className={'favourite-class-subheader-subContainer'}>
						{(calendarItem?.effort === 'Easy' ||
							calendarItem?.effort === 'LOW') && (
							<LowEffort width={32} height={32} />
						)}
						{(calendarItem?.effort === 'Medium' ||
							calendarItem?.effort === 'MEDIUM') && (
							<MediumEffort width={32} height={32} />
						)}
						{(calendarItem?.effort === 'Hard' ||
							calendarItem?.effort === 'HIGH') && (
							<HighEffort width={32} height={32} />
						)}
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginLeft: '4px',
								alignSelf: 'start',
							}}>
							<span
								className={'favourite-class-subheader-title'}
								style={{
									fontStyle: 'normal',
									fontWeight: '500',
									fontFamily: 'Gordita-Medium',
									alignSelf: 'start',
									marginLeft:
										calendarItem?.effort?.toUpperCase() !== 'MEDIUM'
											? '-6px'
											: '0px',
								}}>
								{capitalizeFirstLetter(calendarItem?.effort)}
							</span>
							<span className={'favourite-class-subheader-subtitle'}>
								{'Intensity'}
							</span>
						</div>
					</div>
					<div
						style={{ width: '3px', height: '40px', background: '#ECEFF2' }}
					/>
					<div className={'favourite-class-subheader-subContainer'}>
						<LanguageIcon width={32} height={32} />
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginLeft: '4px',
								alignSelf: 'start',
							}}>
							<span
								className={'favourite-class-subheader-title'}
								style={{
									fontStyle: 'normal',
									fontWeight: '500',
									fontFamily: 'Gordita-Medium',
								}}>
								{calendarItem?.language}
							</span>
							<span className={'favourite-class-subheader-subtitle'}>
								{'Language'}
							</span>
						</div>
					</div>
				</div>

				<p
					style={{
						display: 'flex',
						fontFamily: 'Gordita-Regular',
						fontSize: '14px',
						fontWeight: '400',
						width: '100%',
						marginTop: specialInstruction ? '0px' : '12px',
						flexDirection: 'column',
						alignItems: 'flex-start',
					}}>
					{readMore ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignSelf: 'start',
								marginTop: '12px',
							}}>
							{parsedClassDescription?.length > 0 && (
								<p
									style={{
										color: '#2A2A2A',
										fontFamily: 'Gordita-Regular',
										fontSize: '14px',
										width: '344px',
										fontWeight: 400,
										marginTop: '-10px',
										lineHeight: '24px',
									}}>
									{parsedClassDescription?.[0]}
								</p>
							)}

							{parsedClassDescription?.length > 1 && (
								<p
									style={{
										color: '#2A2A2A',
										fontFamily: 'Gordita-Regular',
										fontSize: '14px',
										width: '344px',
										fontWeight: 400,
										marginTop: '-10px',
										lineHeight: '24px',
									}}>
									{parsedClassDescription?.[1]}
								</p>
							)}

							{parsedClassDescription?.length > 2 && (
								<p
									style={{
										color: '#2A2A2A',
										fontFamily: 'Gordita-Regular',
										fontSize: '14px',
										width: '344px',
										fontWeight: 400,
										marginTop: '-10px',
										lineHeight: '24px',
									}}>
									{parsedClassDescription?.[2]}
								</p>
							)}
						</div>
					) : (
						calendarItem.description.split(' ').slice(0, 25).join(' ') + '...'
					)}
				</p>

				{calendarItem.description.split(' ')?.length > 27 && (
					<div
						onClick={readMoreHandler}
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignSelf: 'start',
						}}>
						<span
							style={{
								display: 'flex',
								color: '#534DE0',
								textDecorationLine: 'underline',
								fontFamily: 'Gordita-Medium',
								fontWeight: 500,
								lineHeight: '16px',
								fontSize: '12px',
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}>
							{readMore ? 'Read Less ' : 'Read More'}
						</span>
						{readMore ? (
							<svg
								style={{ alignSelf: 'center', marginTop: '-4px' }}
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 20 20'
								fill='none'>
								<path
									d='M10.0002 9.00003L13.4202 12.141C13.5503 12.2699 13.7261 12.3422 13.9092 12.3422C14.0923 12.3422 14.2681 12.2699 14.3982 12.141C14.4625 12.0778 14.5135 12.0023 14.5483 11.9192C14.5831 11.836 14.6011 11.7467 14.6011 11.6565C14.6011 11.5664 14.5831 11.4771 14.5483 11.3939C14.5135 11.3107 14.4625 11.2353 14.3982 11.172L10.4902 7.34203C10.3598 7.21336 10.1839 7.14121 10.0007 7.14121C9.81749 7.14121 9.64164 7.21336 9.51121 7.34203L5.60321 11.172C5.53896 11.2353 5.48794 11.3107 5.45311 11.3939C5.41828 11.4771 5.40035 11.5664 5.40035 11.6565C5.40035 11.7467 5.41828 11.836 5.45311 11.9192C5.48794 12.0023 5.53896 12.0778 5.60321 12.141C5.73335 12.2699 5.90908 12.3422 6.09221 12.3422C6.27534 12.3422 6.45108 12.2699 6.58121 12.141L10.0002 9.00003Z'
									fill='#534DE0'
								/>
							</svg>
						) : (
							<svg
								style={{ alignSelf: 'center', marginTop: '-4px' }}
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 20 20'
								fill='none'>
								<path
									d='M10.0002 11L13.4202 7.85897C13.5503 7.73012 13.7261 7.65784 13.9092 7.65784C14.0923 7.65784 14.2681 7.73012 14.3982 7.85897C14.4625 7.92224 14.5135 7.99766 14.5483 8.08084C14.5831 8.16402 14.6011 8.2533 14.6011 8.34347C14.6011 8.43365 14.5831 8.52292 14.5483 8.6061C14.5135 8.68928 14.4625 8.7647 14.3982 8.82797L10.4902 12.658C10.3598 12.7866 10.1839 12.8588 10.0007 12.8588C9.81749 12.8588 9.64164 12.7866 9.51121 12.658L5.60321 8.82797C5.53896 8.7647 5.48794 8.68928 5.45311 8.6061C5.41828 8.52292 5.40035 8.43365 5.40035 8.34347C5.40035 8.2533 5.41828 8.16402 5.45311 8.08084C5.48794 7.99766 5.53896 7.92224 5.60321 7.85897C5.73335 7.73012 5.90908 7.65784 6.09221 7.65784C6.27534 7.65784 6.45108 7.73012 6.58121 7.85897L10.0002 11Z'
									fill='#534DE0'
								/>
							</svg>
						)}
					</div>
				)}
				<div
					style={{
						marginTop: '24px',
						width: '100%',
					}}>
					{calendarItem.highlights > 0 && (
						<span
							style={{
								color: '#4A4A4A',
								fontFamily: 'Gordita-Medium',
								fontSize: '20px',
								fontStyle: 'normal',
								fontWeight: 500,
								alignSelf: 'start',
								lineHeight: '28px',
								letterSpacing: '0.36px',
							}}>
							Highlights
						</span>
					)}

					{calendarItem.highlights?.map(
						(item: { key: string }, index: number) => (
							<BestOutOfItIconWithName
								key={index}
								index={index}
								name={item?.key}
							/>
						)
					)}

					{calendarItem?.session_info.map(
						(item: { key: string }, index: number) => (
							<SessionInfoIconWithName
								key={index}
								index={index}
								name={item.key}
							/>
						)
					)}
				</div>
				<div style={{ marginTop: '24px', width: '100%' }}>
					<span
						style={{
							color: '#4A4A4A',
							fontFamily: 'Gordita-Medium',
							fontSize: '20px',
							fontStyle: 'normal',
							fontWeight: 500,
							alignSelf: 'start',
							lineHeight: '28px',
							letterSpacing: '0.36px',
						}}>
						How to get the best of this class?
					</span>

					{bestOutOfIt
						.filter((value) => value !== null)
						.map((value, index) => (
							<BestOutOfItIconWithName key={index} index={index} name={value} />
						))}
				</div>
				<span
					style={{
						color: '#4A4A4A',
						fontFamily: 'Gordita-Medium',
						fontSize: '20px',
						fontStyle: 'normal',
						fontWeight: 500,
						alignSelf: 'start',
						lineHeight: '28px',
						letterSpacing: '0.36px',
						marginTop: '24px',
					}}>
					Know your instructor
				</span>
				{!!calendarItem?.instructorData && (
					<KnowYourInstructor instructorData={calendarItem?.instructorData} />
				)}
			</div>
		</div>
	);
};

export default FavouriteClasses;
