import React, {useEffect} from 'react'
import './Founders.css'
import {ReactComponent as SeekLogo} from "../../assets/seek-logo.svg";
import {ReactComponent as BackArrow} from "../../assets/arrow-back.svg";
import {useNavigate} from "react-router-dom";
import akash_url from "../../assets/webp-images/founder_two.webp";
import {ReactComponent as FounderOneSurface} from "../../assets/Surface.svg";
// import {ReactComponent as FounderTwo} from "../../assets/founder_one.svg";

import founder_two_ulr from "../../assets/webp-images/founder_one.webp";

import {Mixpanel} from "../../analytics/Mixpanel";

const Founders = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();
    useEffect(()=>{
        Mixpanel.track('view_founders_note', {
            event_action: 'viewed',
            activity_Page:'Know the founders',
        });
    },[])

    const onLinkedinClickHandlerFounderOne = () =>{
        const linkedInUrl = 'https://www.linkedin.com/in/akashjain91/';
        Mixpanel.track('click_founder_profile',{
            event_page:'activity_page',
            founder_name:'Akash',
        })
        // Open the LinkedIn profile URL in a new tab or the LinkedIn app if installed
        window.open(linkedInUrl, '_blank');

    }

    const onLinkedinClickHandlerFounderTwo = () =>{
        const linkedInUrl = 'https://www.linkedin.com/in/kshitij-agarwal-5a2b5934/';
        Mixpanel.track('click_founder_profile',{
            event_page:'activity_page',
            founder_name:'Kshitij',
        })
        // Open the LinkedIn profile URL in a new tab or the LinkedIn app if installed
        window.open(linkedInUrl, '_blank');
    }

    return (
        <div className={'founder-container'}>
             <div className={'horizontal-container '} style={{justifyContent:'space-between',marginLeft:'-12px'}}>
                 <BackArrow onClick={navigate.bind(null,-1)} width={24} height={24} />
                 <SeekLogo style={{marginTop:'-18px',marginLeft:'48px'}} width={84} height={64} />
                 <div style={{marginRight:'48px'}}/>
             </div>
            <div style={{ color: '#1A1A1A',
                fontFamily: 'Lora-Regular',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 400,
                marginTop:'24px',
                textAlign:'left',
                lineHeight: '36px'}}>From the Founder’s Desk.</div>

            <div className={'founder-description'}>
                <div>Kshitij Agarwal</div> & <span>Akash Jain</span> are the founders of Seek. They both share a deep interest in meditation primarily having been for multiple Vipasaana retreats from a very young age. They both are alumni of IIT and ISB.
            </div>
            <div className={'founder-description'}>
                Kshitij was a management consultant with the Boston Consulting Group and Akash was the Director of Product at Urban Company. Having been seekers themselves, they felt compelled to solve this problem at a large scale as much as they can. They left their cushy jobs and start Seek with this vision.
            </div>
            <div className={'founder-description'}>
                They aim to leverage their technical, design and business acumen to impact this world in a very positive way. In case you feel impacted by this course and want to contribute to this cause, do let us know in the chat with admin section.
            </div>
            <img
                src={akash_url}
                style={{ width: '260px', height: '264px', objectFit: 'cover',marginTop:'32px',alignSelf:'center' }}
            />
            <div onClick={onLinkedinClickHandlerFounderOne} style={{zIndex:5,background:'transparent',position:'absolute',right:'78px',bottom:'38.5%',height:'30px',width:'40px'}}/>
            <div onClick={onLinkedinClickHandlerFounderTwo} style={{zIndex:5,background:'transparent',position:'absolute',right:'78px',bottom:'9%',height:'30px',width:'40px'}}/>

            <FounderOneSurface style={{marginTop:'-54px' ,alignSelf:'center'}} width={'260px'} height={164}/>

            <img src={founder_two_ulr} alt="Founder Two" style={{ position: 'relative', marginTop: '-12px', alignSelf: 'center', width: '260px', height: '428px' }} />
            {/*<FounderTwo style={{position: 'relative',marginTop:'-12px',alignSelf:'center'}} width={260} height={428}/>*/}
        </div>
    )
}

export default Founders
