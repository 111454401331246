import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    pocForm: {
        fullName:null,
        whatDoYouWantToHeal:null,
        phone:null,
        selectedTime:null,
        selectedDateOptionIndex:1,
        selectedTechniqueOptionIndex:0,

    }
}

export const pocForm = createSlice({
    name: 'pocForm',
    initialState,
    reducers: {
        updateFullName: (state, action) => {
            state.pocForm.loading = false;
            state.pocForm.fullName = action.payload;
        },
        updateWhatDoYouWantToHeal: (state, action) => {
            state.pocForm.whatDoYouWantToHeal = action.payload;
        },
        updatePhone: (state, action) => {
            state.pocForm.phone = action.payload;
        },
        updateSelectedTimeSlot: (state, action) => {
            state.pocForm.selectedTime = action.payload;
        },
        updatesSelectedDateOptionIndex: (state, action) => {
            state.pocForm.selectedDateOptionIndex = action.payload;
        },
        updateSelectedTechniqueOptionIndex: (state, action) => {
            state.pocForm.selectedTechniqueOptionIndex = action.payload;
        },
    }
})

export const {
    updateFullName,
    updateWhatDoYouWantToHeal,
    updatePhone,
    updateSelectedTimeSlot,
    updatesSelectedDateOptionIndex,
    updateSelectedTechniqueOptionIndex,
} = pocForm.actions;

export default pocForm.reducer
