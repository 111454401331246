import React from 'react';
import { Modal } from 'react-bootstrap';

interface CustomModalProps {
    show: boolean;
    onHide: () => void;
    title: string;
    content: React.ReactNode;
    showHeader?:boolean,
}

const CustomModal: React.FC<CustomModalProps> = ({ show, onHide, title, content,showHeader= true }) => {
    return (
        <Modal show={show} onHide={onHide}>
            {showHeader && <Modal.Header closeButton>
                <Modal.Title style={{fontFamily:'Poppins'}}>{title}</Modal.Title>
            </Modal.Header>}
            <Modal.Body>{content}</Modal.Body>
        </Modal>
    );
};

export default CustomModal;
