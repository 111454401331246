import React, {useEffect, useRef, useState, useCallback} from 'react';
import './ClassCard.css';
import {Mixpanel} from "../../analytics/Mixpanel";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import {Card} from "react-bootstrap";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../services/Firebase";
import ShimmerLoader from "../shimmer/Shimmer";
import transparent_bg from '../../assets/new-icons/transparent_bg.svg';
import {useDispatch, useSelector} from "react-redux";
import {updateEventsStatus} from "../../store/features/EventsSlices";

interface ClassCardProps {
    imageUrl: string;
    title: string;
    subtitle: string;
    key: number;
    favouriteClass: any;
    onClick: (favouriteClass: any) => void;
}

const ClassCard: React.FC<ClassCardProps> = React.memo(({
                                                            key,
                                                            onClick,
                                                            favouriteClass,
                                                            imageUrl,
                                                            title,
                                                            subtitle,
                                                        }) => {

    const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
    const thumbnailUrlRef = useRef<string | null>(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const view_favourite_classes = useSelector((state: any) => state?.events?.eventsLog?.view_favourite_classes);
    const logViewEvent = useCallback(() => {
        if (!view_favourite_classes) {
            Mixpanel.track('view_favourite_classes', {
                event_action: 'viewed',
                event_page: 'homescreen',
            });
            dispatch(updateEventsStatus({key:'view_favourite_classes',value:true}))
        }
    }, [view_favourite_classes]);

    const cardRef = useIntersectionObserver(logViewEvent, {threshold: 0.5});


    useEffect(() => {
        if (!thumbnailUrlRef.current) {
            (async () => {
                try {
                    const imageRef = ref(storage, imageUrl);
                    const url = await getDownloadURL(imageRef);
                    setThumbnailUrl(url);
                    thumbnailUrlRef.current = url;
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                }
            })();
        } else {
            setThumbnailUrl(thumbnailUrlRef.current);
            setLoading(false);
        }
    }, [imageUrl]);

    const handleClick = useCallback(() => {
        onClick(favouriteClass);
    }, [onClick, favouriteClass]);

    if (loading) return <ShimmerLoader/>;

    return (
        <div onClick={handleClick} ref={cardRef} className="class-card-container">
            <div className="class-card-bg-image" style={{backgroundImage: `url(${thumbnailUrl})`}}>
                <div className="class-card-bg-image" style={{backgroundImage: `url(${transparent_bg})`}}>
                </div>
            </div>
            <div className="class-card-content">
                <span className="class-card-title">{title}</span>
                <span className="class-card-subtitle">{subtitle}</span>
            </div>
        </div>
    );
});

export default ClassCard;
