import React from 'react'
import SlotChip from "../../components-v2/chips/SlotChip";
import './LiveClassSlotContainer.css'
import {isEmpty} from "../../utils/utils";

interface LiveClassSlotContainerProps {
    slots: any,
}

const LiveClassSlotContainer: React.FC<LiveClassSlotContainerProps> = ({slots}) => {
    return (
        <div className={'live-class-clot-container'}>{
            slots?.map((_slot: any) => {
                if (isEmpty(_slot)) return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="12" viewBox="0 0 2 12" fill="none">
                        <path d="M0.323975 11.6279V0.371948H1.17597V11.6279H0.323975Z" fill="#E4E4E4"/>
                    </svg>)
                else
                    return <SlotChip title={_slot?.slot} isAvailable={_slot?.isAvailable} isLive={_slot?.isLive}/>
            })
        }</div>
    )
}
export default LiveClassSlotContainer;
