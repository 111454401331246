import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    betaUsers:{
        betaPaidUsers:[],
        betaUnpaidUsers:[],
    }
}

export const betaUsers = createSlice({
    name: 'betaUsers',
    initialState,
    reducers: {
        updateBetaUsers: (state, action) => {
            console.log("updateBetaUsers action",action);
            state.betaUsers.betaPaidUsers = action.payload.betaPaidUsers;
            state.betaUsers.betaUnpaidUsers = action.payload.betaUnpaidUsers;
        },
    }
})

export const {
    updateBetaUsers,
} = betaUsers.actions;

export default betaUsers.reducer
