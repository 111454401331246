import { useEffect, useRef, useState } from 'react';

/**
 * Custom hook to check if an element is visible in the viewport
 * @returns {Array} An array containing the ref and the visibility state
 */

interface useVisibilityObserverParams {
	topOffset: number;
	bottomOffset: number;
}

const useVisibilityObserver = ({
	topOffset,
	bottomOffset,
}: useVisibilityObserverParams): Array<any> => {
	const [isVisible, setIsVisible] = useState(false); // State to track visibility
	const elementRef = useRef<HTMLDivElement | null>(null); // Reference to the observed element with correct typing

	const checkVisibility = () => {
		if (elementRef.current) {
			const rect = elementRef.current.getBoundingClientRect();
			// Check if the element is within the viewport
			const inViewport =
				rect.top >= 0 + topOffset &&
				rect.bottom + bottomOffset <=
					(window.innerHeight || document.documentElement.clientHeight);

			setIsVisible(inViewport);
		}
	};

	useEffect(() => {
		// Check visibility when the component mounts
		checkVisibility();

		// Add event listeners for scroll and resize
		window.addEventListener('scroll', checkVisibility);
		window.addEventListener('resize', checkVisibility);

		// Cleanup event listeners when the component unmounts
		return () => {
			window.removeEventListener('scroll', checkVisibility);
			window.removeEventListener('resize', checkVisibility);
		};
	}, []);

	return [elementRef, isVisible, setIsVisible]; // Return the ref and visibility state
};

export default useVisibilityObserver;
