import React, {useCallback} from 'react';
import './css/PlanStatusBanner.css';
import PrimaryButton from '../buttons/PrimaryButton';
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {PAID_UPGRADE_PLAN_POPUP_BUFFER_DAYS} from "../../utils/utils";
import {Mixpanel} from "../../analytics/Mixpanel";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import {updateEventsStatus} from "../../store/features/EventsSlices";
import moment from "moment-timezone";

interface UpgradeBannerProps {
    ctaText: string;
    onCtaClick?: () => void;
    customStyle?: any;
    Icon: any;
}

const PlanStatusBanner: React.FC<UpgradeBannerProps> = ({
                                                            customStyle,
                                                            Icon,
                                                            ctaText,
                                                            onCtaClick
                                                        }) => {
    const activeStreamingPlan = useSelector((state: any) => state.plan?.userPlan?.activeStreamingPlan);
    const daysToExpire = useSelector((state: any) => state.plan?.userPlan?.daysToExpire);
    const formattedEndDate = dayjs(new Date(activeStreamingPlan?.endDate?._seconds * 1000))?.format('MMM DD YYYY') || moment()?.format('MMM DD, YYYY');
    // console.log("activeStreamingPlan p", activeStreamingPlan);
    // console.log("daysToExpire p", daysToExpire);
    // console.log("formattedEndDate p", formattedEndDate);

    const dispatch = useDispatch();
    const view_plan_banner = useSelector((state: any) => state?.events?.eventsLog?.view_plan_banner);
    const logViewEvent = useCallback(() => {
        if (!view_plan_banner && activeStreamingPlan && formattedEndDate) {
            Mixpanel.track('view_plan_banner', {
                event_action: 'viewed',
                plan_type: activeStreamingPlan?.active_plan_name,
                ending_date: formattedEndDate,
                event_page: 'profile',
            });
            dispatch(updateEventsStatus({key: 'view_plan_banner', value: true}))
        }
    }, [view_plan_banner, activeStreamingPlan, formattedEndDate]);
    const cardRef = useIntersectionObserver(logViewEvent, {threshold: 0.5});


    return (
        <div ref={cardRef} className="plan-status-banner-card" style={customStyle}>
            <div className={'plan-status-banner-content-wrapper'}>
                <div style={{display: 'flex', flexDirection: 'column', alignSelf: "flex-start"}}>
                    <span
                        className="plan-status-banner-title">{`${activeStreamingPlan?.active_plan_name?.toLowerCase() || 'FREE PASS'}`}</span>
                    <span className="plan-status-banner-subtitle">{`Seek Daily Plan`}</span>
                    {daysToExpire <= 0 && <span
                        className="plan-status-banner-subtext"
                        style={{color: '#EB5757'}}>{'Ended on ' + formattedEndDate}</span>
                    }

                    {daysToExpire > PAID_UPGRADE_PLAN_POPUP_BUFFER_DAYS && daysToExpire != null && <span
                            className="plan-status-banner-subtext">{'Ending on ' + formattedEndDate}</span>}
                    {daysToExpire <= PAID_UPGRADE_PLAN_POPUP_BUFFER_DAYS && daysToExpire > 0 && daysToExpire != null &&
                        <span
                            className="plan-status-banner-subtext"
                            style={{color: '#FAA53D'}}>{'Ending on ' + formattedEndDate}</span>
                    }

                </div>
                <Icon width={80} height={80}/>
            </div>
            {
                (daysToExpire === null || (daysToExpire <= PAID_UPGRADE_PLAN_POPUP_BUFFER_DAYS)) && (
                    <PrimaryButton className={'plan-status-banner-cta'} onClick={onCtaClick}>
                        {ctaText}
                    </PrimaryButton>
                )
            }

        </div>
    );
};

export default PlanStatusBanner;
