import React from 'react';
import Modal from "../../components/model/Model";
import Button from "../../components/button/Button";
import {COLORS} from "../../utils/colors";
import {ReactComponent as AlertIcon} from "../../assets/alert.svg";
import './Model.css'


interface LoginFailureModalProps {
    isLoginFailure: boolean;
    closeModal: () => void;
    onSignUpClickHandler: () => void;
}

const LoginFailureModal: React.FC<LoginFailureModalProps> = ({
                                                                 isLoginFailure,
                                                                 closeModal,
                                                                 onSignUpClickHandler
                                                             }) => {
    return (
        <Modal
            show={isLoginFailure}
            onHide={closeModal}
            title={'Login Failed!'}
            showHeader={false}
            content={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '24px 8px 12px 8px'
                    }}
                >
                    <AlertIcon style={{alignSelf: 'center'}} width={120} height={120}/>
                    <span className={'login-failure-text'}>Oops ❌</span>
                    <span className={'login-failure-subtitle'}>Looks like you don’t have an account with us. To start your meditation journey, sign up.</span>
                    <Button
                        style={{alignSelf: 'center', backgroundColor: COLORS.PRIMARY, marginTop: '20px'}}
                        onClick={onSignUpClickHandler}
                        label={'Sign Up Now'}
                    />
                </div>
            }
        />
    );
};

export default LoginFailureModal;
