import React, {memo, ReactElement} from 'react';
import {ReactComponent as SendReminder} from "../../../assets/send_reminder.svg";
import './ReferralRowHistory.css';
import {ReactComponent as LineSeparator} from "../../../assets/line-seperator.svg";
import {COLORS} from "../../../utils/colors";

interface ReferralHistoryRowProps {
    name: string;
    email:string;
    last_class_date: string;
    karma_points_earned_by:number;
    phoneNumber:string;
    onSendReminder:(phoneNumber:string,name:string,last_class_date:string,email:string)=>void;
}

const ReferralHistoryRow: React.FC<ReferralHistoryRowProps> = ({ name,email,last_class_date,karma_points_earned_by,phoneNumber,onSendReminder }) => {
    return (
        <div className={"referral-history-row-container"}>
            <div className={"referral-history-row-name"}>{name}</div>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
                <div className={"referral-history-row-class-date"}>{last_class_date}</div>
                <SendReminder onClick={onSendReminder.bind(null,phoneNumber,name,last_class_date,email)} style={{marginTop:'6px'}} width={108} height={18}/>
            </div>
            <div className={"referral-history-row-karma-points"}>{karma_points_earned_by} <div>Karma</div></div>
            <div style={{width:'100%',height:'2px',backgroundColor:'#E4E4E4',marginTop:'16px'}}/>
        </div>
    );
};

export default memo(ReferralHistoryRow);
