import React, {useEffect} from 'react'
import './SampleClasses.css'
import {ReactComponent as SeekLogo} from "../../assets/seek-logo.svg";
import {ReactComponent as BackArrow} from "../../assets/arrow-back.svg";
import {useNavigate} from "react-router-dom";
import YouTubeVideo from "./YouTubeVideo";
import {getYoutubeVideoId} from "../../utils/utils";
import {sampleClassesData} from "../../utils/utils";
import {Mixpanel} from "../../analytics/Mixpanel";

const SampleClasses = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();

    useEffect(()=>{
        Mixpanel.track('view_seek_archives', {
            event_action: 'viewed',
            activity_Page:'Sample Classes',
            video_titles:sampleClassesData,
        });
    },[])

    return (
        <div className={'sample-classes-container'}>
            <div className={'horizontal-container'} style={{justifyContent:'space-between',marginLeft:'-12px'}}>
                <BackArrow onClick={navigate.bind(null,-1)} width={24} height={24} />
                <SeekLogo style={{marginTop:'-18px',marginLeft:'48px'}} width={84} height={64} />
                <div style={{marginRight:'48px'}}/>
            </div>
            <div style={{ color: '#1A1A1A',
                fontFamily: 'Lora-Regular',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 400,
                marginTop:'24px',
                textAlign:'center',
                width:'343px',
                marginBottom:'24px',
                lineHeight: '36px'}}>Seek Archives</div>
            {sampleClassesData?.map((videoData, index) => {
                const videoId = getYoutubeVideoId(videoData.url);
                return (
                    <YouTubeVideo title={videoData?.title} time={videoData?.date} author={videoData.author} key={index} videoId={videoId} />
                );
            })}
        </div>
    )
}

export default SampleClasses
