// AppLayout.js

import React from 'react';
import './AppLayout.css'; // Import the stylesheet for styling

const AppLayout = ({ children }) => {
    return (
        <div className="app-layout">
            <div className="background-image" />
            {children}
        </div>
    );
};

export default AppLayout;
