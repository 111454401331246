import React, { useLayoutEffect, useRef } from 'react';
import { InputOTP as OTPInput } from 'antd-input-otp';
import './InputOTP.css';

interface InputOTPProps {
	otpValues: string[];
	setOtpValues: (values: string[]) => void;
}

const InputOTP: React.FC<InputOTPProps> = ({ otpValues, setOtpValues }) => {
	const inputRefs = useRef([]);

	useLayoutEffect(() => {
		inputRefs.current.forEach((element: any) => {
			element.input.maxLength = 6;
			element.input.type = 'tel';
			element.input.addEventListener('change', (e: any) => {
				const value = e.target.value;
				if (value.length === 6) {
					setOtpValues(value.split(''));
				}
			});
		});
	}, [setOtpValues]);

	return (
		<div className='otp-input'>
			<OTPInput
				inputRef={inputRefs}
				inputType='numeric'
				onPaste={(e) => {
					e.preventDefault();
					navigator &&
						navigator.clipboard &&
						navigator.clipboard.readText().then((value) => {
							if (!isNaN(Number(value))) {
								setOtpValues(value.split(''));
							}
						});
				}}
				onChange={setOtpValues}
				value={otpValues}
				length={6}
			/>
		</div>
	);
};

export default InputOTP;
