import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    userPlan: {
        loading: true,
        activeStreamingPlan: {},
        daysToExpire: null,
        daysToExpireTrail: -1,
        totalClassesAttendedTillDate: -1,
        trialActivated: null,
        daysToStartTrial: -1,
    }
}

export const plan = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        updateUsersPlan: (state, action) => {
            state.userPlan.loading = false;
            state.userPlan.activeStreamingPlan = action.payload;
        },
        updateDaysToExpire: (state, action) => {
            state.userPlan.daysToExpire = action.payload;
        },
        updateDaysToExpireTrail: (state, action) => {
            state.userPlan.daysToExpireTrail = action.payload;
        },
        updateTotalClassesAttendedTillDate: (state, action) => {
            state.userPlan.totalClassesAttendedTillDate = action.payload;
        },
        updateTrialActivated: (state, action) => {
            state.userPlan.trialActivated = action.payload;
        },
        updateDaysToStartTrial: (state, action) => {
            state.userPlan.daysToStartTrial = action.payload;
        },
        deletePlan: (state) => {
            state.userPlan = {
                loading: true,
                activeStreamingPlan: {},
                daysToExpire: null,
                daysToExpireTrail: -1,
                totalClassesAttendedTillDate: -1,
                trialActivated: null,
                daysToStartTrial: -1,
            }
        }
    }
})

export const {
    updateDaysToStartTrial,
    updateUsersPlan,
    updateDaysToExpire,
    updateDaysToExpireTrail,
    updateTotalClassesAttendedTillDate,
    updateTrialActivated,
    deletePlan,
} = plan.actions;

export default plan.reducer
