import React from 'react';

interface ProgressBarProps {
    progress: number; // Progress value between 0 and 100
    threshold:number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress,threshold }) => {
    const isFirstCircleFilled = progress >= 33.3;
    const isSecondCircleFilled = progress >= 66.6;
    const isThirdCircleFilled = progress >= 100;

    return (
        <div style={{ display: 'flex', alignItems: 'center',justifyContent:'center',alignSelf:'center', marginTop: '24px', width: '100%' }}>
            <div style={{ width: '100%', height: '5px', backgroundColor: '#E4E4E4', borderRadius: '5px', position: 'relative' }}>
                <div style={{ zIndex:1, position: 'absolute', width: `${progress-threshold}%`, height: '100%', backgroundColor: '#11BC59', borderRadius: '5px' }}></div>
                <div style={{ position: 'absolute', width: `${progress}%`, height: '100%', backgroundColor: '#A0E4BD', borderRadius: '5px' }}></div>
                <div style={{ position: 'absolute', top: '-5px', left: '33.3%', transform: 'translateX(-50%)', width: '15px', height: '15px', border: '2px solid #E4E4E4', borderRadius: '50%', backgroundColor: isFirstCircleFilled ? '#11BC59' : 'white',  display: 'flex', justifyContent: 'center', alignItems: 'center', color: isFirstCircleFilled ? '#fff' : '#000'}}></div>
                <div style={{ position: 'absolute', top: '-5px', left: '66.6%', transform: 'translateX(-50%)', width: '15px', height: '15px', border: '2px solid #E4E4E4', borderRadius: '50%', backgroundColor: isSecondCircleFilled ? '#11BC59' : 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', color: isSecondCircleFilled ? '#fff' : '#000'}}></div>
                <div style={{ position: 'absolute', top: '-5px', left: '100%', transform: 'translateX(-50%)', width: '15px', height: '15px', border: '2px solid #E4E4E4', borderRadius: '50%', backgroundColor: isThirdCircleFilled ? '#11BC59' : 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', color: isThirdCircleFilled ? '#fff' : '#000'}}></div>
            </div>
        </div>
    );
}

export default ProgressBar;
