import React from 'react';

interface InstructionCardProps {
    imageUrl: string;
    description?: string;
}

const InstructionCard: React.FC<InstructionCardProps> = ({imageUrl, description}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            padding: '16px 12px',
            backgroundColor: '#FFF6EC',
            borderRadius: '12px'
        }}>
            <img
                src={imageUrl}
                style={{width: '254px', height: '272px', objectFit: 'cover', alignSelf: 'center', margin: '12px'}}
            />
            <span style={{
                color: '#4A4A4A',
                textAlign: 'start',
                fontFamily: 'Gordita-Regular',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                flex: '1 0 0',
                width:'320px',
                marginTop: '12px',
                lineHeight: '20px'
            }}>{description}
                </span>
        </div>
    );
};

export default InstructionCard;
