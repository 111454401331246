import React, { useEffect, useState } from 'react';
import { Mixpanel } from '../../analytics/Mixpanel';
import { events_action } from '../../analytics/Events';

interface ProfileCardProps {
	imageUrl: string;
	name?: string;
	expertise?: string;
	description?: string;
	readMoreHandler?: any;
}

const InstructorCard: React.FC<ProfileCardProps> = ({
	imageUrl,
	name,
	expertise,
	description,
	readMoreHandler,
}) => {
	const [readMore, setReadMore] = useState(false);
	const [desc, setDesc] = useState('');
	const [instName, setInstName] = useState('');

	const readMoreAboutUS = (name: string) => {
		setReadMore((prevState) => !prevState);
		setInstName(name);
	};

	useEffect(() => {
		if (readMore && description) {
			setDesc(description);
			Mixpanel.track('click_read_more_instructor', {
				event_page: 'landing_page',
				event_section: 'Learn from the Best.',
				event_action: events_action.clicked,
				instructor_name: instName,
			});
			readMoreHandler(true);
		} else if (description) {
			setDesc(description.substring(0, 226));
			readMoreHandler(false);
		}
	}, [readMore]);

	return (
		<div
			style={{
				background: '#fff',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				width: '100%',
				borderRadius: '12px',
				overflow: 'hidden',
				border: '1px solid #E4E4E4',
			}}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					width: '100%',
					position: 'relative', // Make the container position relative
					paddingBottom: '75%', // Aspect ratio 4:3 (3/4 = 0.75)
					height: 0, // Set height to 0 to maintain the aspect ratio
				}}>
				<img
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						objectFit: 'cover', // Ensure the image scales without being cut
					}}
					src={imageUrl}
					alt={`Slide ${name}`}
				/>
			</div>

			<div
				style={{
					paddingLeft: '12px',
					paddingRight: '12px',
					// height: '256px',
					paddingBottom: '12px',
					display: 'flex',
					alignItems: 'flex-start',
					flexDirection: 'column',
					borderRadius: '12px',
				}}>
				<span
					style={{
						color: '#1B1625',
						fontFamily: 'Gordita-Medium',
						fontSize: '16px',
						fontStyle: 'normal',
						fontWeight: 500,
						marginTop: '12px',
						lineHeight: '24px',
					}}>
					{name}
				</span>
				<span
					style={{
						color: '#3A3A3A',
						fontFamily: 'Gordita-Regular',
						fontSize: '12px',
						fontStyle: 'italic',
						fontWeight: 400,
						lineHeight: '16px',
					}}>
					{expertise}
				</span>
				<div
					style={{
						color: '#3A3A3A',
						fontFamily: 'Gordita-Regular',
						fontSize: '14px',
						fontStyle: 'normal',
						textAlign: 'left',
						fontWeight: 400,
						marginTop: '4px',
						marginRight: '1px',
						lineHeight: '20px',
					}}>
					{`${desc}...`}
					<span
						onClick={readMoreAboutUS.bind(null, name as string)}
						style={{
							color: '#534DE0',
							fontFamily: 'Gordita-Medium',
							fontSize: '12px',
							fontStyle: 'normal',
							fontWeight: 500,
							lineHeight: '20px',
							borderRadius: '12px',
							textDecorationLine: 'underline',
						}}>
						{readMore ? 'READ LESS' : 'READ MORE'}
					</span>
				</div>
			</div>
		</div>
	);
};

export default InstructorCard;
