import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFavouriteClasses, getUpcomingCourses } from '../services/Firebase';
import {
	setFavouriteClasses,
	setForceRefreshOnCoursePurchase,
	setUpcomingCourses,
} from '../store/features/UpcomingClassesSlice';

const useFetchCoursesData = (
	userData: any,
	user: any,
	upcomingCourses: any,
	favouriteClasses: any
) => {
	const dispatch = useDispatch();
	const force_refresh_on_course_purchase = useSelector(
		(state: any) => state.upcomingClasses?.force_refresh_on_course_purchase
	); // Assuming upcomingClasses state is stored in Redux

	useEffect(() => {
		const fetchCoursesData = async () => {
			try {
				const [upcomingCoursesResult, favouriteClassesResult] =
					await Promise.all([
						!upcomingCourses?.length && (userData?.uid || user?.uid)
							? getUpcomingCourses(userData?.uid || user?.uid)
							: Promise.resolve({ data: upcomingCourses }),
						!favouriteClasses?.length
							? getFavouriteClasses()
							: Promise.resolve({ data: favouriteClasses }),
					]);

				if (upcomingCoursesResult?.data) {
					dispatch(setUpcomingCourses(upcomingCoursesResult.data));
					dispatch(setForceRefreshOnCoursePurchase(false));
				}
				if (favouriteClassesResult?.data) {
					dispatch(setFavouriteClasses(favouriteClassesResult.data));
				}
			} catch (err) {
				// console.log("Error fetching courses data", err);
			}
		};

		if (!upcomingCourses?.length || !favouriteClasses?.length) {
			fetchCoursesData().then();
		}
	}, [
		upcomingCourses?.length,
		favouriteClasses?.length,
		userData?.uid,
		dispatch,
		force_refresh_on_course_purchase,
	]);
};

export default useFetchCoursesData;
