import React, {useCallback, useState} from 'react'
import './LeaderNudge.css'
import LeaderBoardRow from "./LeaderBoardRow";
import {Mixpanel} from "../../../analytics/Mixpanel";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import {useDispatch, useSelector} from "react-redux";
import {updateEventsStatus} from "../../../store/features/EventsSlices";

interface LeaderNudgeProps {
    leaderboardUsers?:Array<any>;
    phone:string,
    onClick?:()=>void,
    title?:string,
    currentUserLeaderboardStats:any,
}
const LeaderNudge:React.FC<LeaderNudgeProps> = ({leaderboardUsers,phone,onClick,currentUserLeaderboardStats}) => {
    const dispatch = useDispatch();
    const view_weekly_leaderboard = useSelector((state: any) => state?.events?.eventsLog?.view_weekly_leaderboard);


    const logViewEvent = useCallback(() => {
        if (!view_weekly_leaderboard && currentUserLeaderboardStats) {
            Mixpanel.track('view_weekly_leaderboard', {
                event_action: 'viewed',
                rank:currentUserLeaderboardStats?.rank,
                weekly_karma_points:currentUserLeaderboardStats?.total_karma,
                event_page: 'homescreen',
            });
            dispatch(updateEventsStatus({key:'view_weekly_leaderboard',value:true}))
        }
    }, [view_weekly_leaderboard, currentUserLeaderboardStats]);

    const cardRef = useIntersectionObserver(logViewEvent, { threshold: 0.5 });
    return (
        <div ref={cardRef} className={'leaderNudge-container'}>
            <div className={'leaderNudge-header-container'} style={{width:'100%'}}>
                <span className="leaderNudge-header-text">{`Rank`}</span>
                <span className="leaderNudge-header-text" style={{display:"flex",flex:3,  marginLeft:'-22px'}}>{`Name`}</span>
                <span className="leaderNudge-header-text" style={{display:"flex",flex:1.2}}>{`Karma Points`}</span>
            </div>
            {leaderboardUsers?.map((item, index)=><LeaderBoardRow userPhone={item?.phone||item?.number} phone={phone} name={item?.name} points={item?.total_karma} rank={item.rank}/>)}
        </div>
    )
}

export default LeaderNudge
