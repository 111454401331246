import React, {CSSProperties, ReactNode} from 'react';
import { Card } from 'react-bootstrap';
import './CustomCard.css'

interface CustomCardProps {
    onClick?:any;
    children:ReactNode;
    style?:CSSProperties;
}

const CustomCard:React.FC<CustomCardProps> = ({ children,style,onClick }) => {
    return (
        <Card onClick={onClick} className={'custom-card'} style={style}>
            <Card.Body>
                {children}
            </Card.Body>
        </Card>
    );
};

export default CustomCard;

