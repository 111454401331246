import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import './LandingPage.css';
import Button from '../../components/button/Button';
import { useSelector } from 'react-redux';
import { isMobileDevice } from '../../utils/utils';

interface LandingPageProps {
	onLandingPageContinueHandler: (name: string) => void;
}

const LandingPage: React.FC<LandingPageProps> = ({
	onLandingPageContinueHandler,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const userPreferences = useSelector(
		(state: any) => state.auth.userPreferences
	);
	const [name, setName] = useState(userPreferences?.name);
	const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(true);

	useEffect(() => {
		if (name.trim().length >= 3) {
			setIsStartButtonDisabled(false);
		} else {
			setIsStartButtonDisabled(true);
		}
	}, [name]);

	// Function to handle input change
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	};

	const [isAnimating, setIsAnimating] = useState(true);
	const [isInputFocused, setIsInputFocused] = useState(false);

	useEffect(() => {
		if (!isInputFocused && isMobileDevice()) {
			window.scrollTo(0, 0);
		}
	}, [isInputFocused]);

	const startAnimation = () => {
		setIsAnimating(true);

		// Reset animation after a certain duration
		setTimeout(() => {
			setIsAnimating(false);
		}, 2000); // Adjust the duration as needed
	};

	useEffect(() => {
		startAnimation();
	}, []);

	return (
		<Container className={'landing-page-container'}>
			<p className={'landing-title'} style={{ marginTop: '102px' }}>
				Your Name?
			</p>
			<input
				type='text'
				value={name}
				placeholder={'Enter your full name'}
				onChange={handleInputChange}
				className={`${name ? 'input-value' : 'input-placeholder'} ${
					isInputFocused ? 'input-focus' : ''
				}`}
				style={{
					marginTop: '150px',
					fontSize: '24px',
					lineHeight: '32px',
					width: '303px',
					padding: '8px',
					borderLeft: 'none',
					borderRight: 'none',
					borderTop: 'none',
					textAlign: 'center',
					cursor: 'pointer',
				}}
				onFocus={() => setIsInputFocused(true)}
				onBlur={() => setIsInputFocused(false)}
			/>
			<Button
				disabled={isStartButtonDisabled}
				isSticky={true}
				style={{
					alignSelf: 'center',
					marginBottom: '24px',
					background: isStartButtonDisabled
						? 'var(--Inactive-Color, #D6D6D6)'
						: 'var(--Primary_Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%))',
				}}
				onClick={onLandingPageContinueHandler.bind(null, name)}
				label={'Start'}></Button>
		</Container>
	);
};

export default LandingPage;
