import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import './WhatYouWillGet.css';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../services/Firebase';
import { Mixpanel } from '../../../analytics/Mixpanel';
import { events_page } from '../../../analytics/Events';
import useAuthenticatedUsers from '../../../hooks/useAuthenticatedUsers';

type WhatYouWillGetItemProps = {
	item: any;
	last: boolean;
	courseTitle: string;
	offerPrice: string | undefined;
	actualPrice: string | undefined;
	recordingStatus: boolean;
	setRecordingStatus: Dispatch<SetStateAction<boolean>>;
};

const WhatYouWillGetItem: React.FC<WhatYouWillGetItemProps> = ({
	item,
	last,
	offerPrice,
	actualPrice,
	recordingStatus,
	setRecordingStatus,
	courseTitle,
}) => {
	const [imgUrl, setImgUrl] = useState<string>('');
	const userData = useAuthenticatedUsers(true);
	const colorMap: any = {
		grey: '#4a4a4a',
		blue: '#4462EF',
	};

	const fontMap: any = {
		grey: 'Gordita-Medium',
		blue: 'Gordita-Medium',
	};

	const formatTextWithStyles = (text: string) => {
		const regex = /(<grey>|<blue>)/g;
		const parts = text.split(regex);

		let currentColor: string | null = null;
		let currentFont: string | null = null;

		return parts.map((part, index) => {
			if (colorMap[part.slice(1, -1)]) {
				currentColor = currentColor ? null : colorMap[part.slice(1, -1)];
				currentFont = currentFont ? null : fontMap[part.slice(1, -1)];
				return null;
			}

			return (
				<span
					key={index}
					style={{
						color: currentColor || 'inherit',
						fontFamily: currentFont || 'inherit',
					}}>
					{part}
				</span>
			);
		});
	};

	useEffect(() => {
		(async () => {
			try {
				const imageRef = ref(storage, item.img_url);
				const url = await getDownloadURL(imageRef);
				setImgUrl(url);
			} catch (error) {}
		})();
	}, [item]);

	return (
		<div className='course-what-you-will-get-item-container'>
			<div className='course-what-you-will-get-item'>
				<div className='course-what-you-will-get-item-text'>
					<p className='course-what-you-will-get-item-text-title'>
						{formatTextWithStyles(item.heading)}
					</p>
					<p className='course-what-you-will-get-item-text-subtitle'>
						{formatTextWithStyles(item.description)}
					</p>
				</div>
				<div className='course-what-you-will-get-item-img'>
					<img src={imgUrl} alt='' />
				</div>
			</div>
			{last && !!offerPrice && (
				<div className='course-what-you-will-get-item-recordings'>
					<div className='course-what-you-will-get-item-recordings-text'>
						<p className='course-what-you-will-get-item-recordings-text-title'>
							Only for
						</p>
						<div className='course-what-you-will-get-item-recordings-text-price'>
							<p className='course-what-you-will-get-item-recordings-text-price-offer'>
								INR {offerPrice}
							</p>
							<p className='course-what-you-will-get-item-recordings-text-price-strike'>
								INR {actualPrice}
							</p>
						</div>
					</div>
					<div
						onClick={() => {
							setRecordingStatus(!recordingStatus);
							if (recordingStatus) {
								Mixpanel.track('click_added_recording', {
									event_action: 'clicked',
									event_page: events_page.course_landing_page,
									course_name: courseTitle,
									phone: userData?.phone || userData?.number,
								});
							} else {
								Mixpanel.track('click_add_recording', {
									event_action: 'clicked',
									event_page: events_page.course_landing_page,
									course_name: courseTitle,
									phone: userData?.phone || userData?.number,
								});
							}
						}}
						className='course-what-you-will-get-item-recordings-button'>
						<span>{recordingStatus ? '×' : '+'}</span>{' '}
						{recordingStatus ? 'Added' : 'Add my recording'}
					</div>
				</div>
			)}
		</div>
	);
};

type Props = {
	data: any[];
	offerPrice: string | undefined;
	actualPrice: string | undefined;
	recordingStatus: boolean;
	courseTitle: string;
	setRecordingStatus: Dispatch<SetStateAction<boolean>>;
};

const WhatYouWillGet: React.FC<Props> = ({
	data,
	courseTitle,
	offerPrice,
	actualPrice,
	recordingStatus,
	setRecordingStatus,
}) => {
	return (
		<div className='course-what-you-will-get'>
			{data.map((item: any, index: number) => {
				return (
					<WhatYouWillGetItem
						courseTitle={courseTitle}
						key={index}
						item={item}
						last={data.length === index + 1}
						offerPrice={offerPrice}
						actualPrice={actualPrice}
						recordingStatus={recordingStatus}
						setRecordingStatus={setRecordingStatus}
					/>
				);
			})}
		</div>
	);
};

export default WhatYouWillGet;
