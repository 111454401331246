import React, {CSSProperties} from 'react';
import {CarouselProvider, Slider, Slide} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {Nudge} from "./Nudge";
import {ReactComponent as SpecialCourseIcon} from '../../assets/new-icons/new.svg';


interface CustomCarouselProps {
    IngressNudges: any;
    naturalSlideWidth: number;
    naturalSlideHeight: number;
    style?: CSSProperties;
    width?: any;
    margin?: any;
    pageName?: string,
    onClick: any;
}

const CustomCarousel: React.FC<CustomCarouselProps> = ({
                                                           onClick,
                                                           IngressNudges,
                                                           naturalSlideWidth,
                                                           naturalSlideHeight,
                                                           style,
                                                           width,
                                                           margin,
                                                           pageName,

                                                       }) => {

    return (
        <CarouselProvider
            naturalSlideWidth={naturalSlideWidth}
            naturalSlideHeight={naturalSlideHeight}
            totalSlides={IngressNudges?.length}
            isPlaying={true}
            interval={3000}
            preventVerticalScrollOnTouch={true}
            lockOnWindowScroll={true}
            infinite
            visibleSlides={IngressNudges?.length === 1 ? 1 : 1.1} // Set the number of visible slides
        >
            <Slider style={style}>
                {IngressNudges?.map((item: any, index: number) => (
                        <Slide
                            key={index}
                            index={index}
                        >
                            <Nudge course_name={item?.course_name} size={IngressNudges?.length}
                                   nudge_type={item.nudge_type} redirection_link={item.redirection_link}
                                   onClick={onClick} isVisible={item.isVisible} index={index}
                                   Icon={item?.Icon || SpecialCourseIcon}
                                   title={item.title} key={index}/>
                        </Slide>
                ))}
            </Slider>
        </CarouselProvider>
    );
};

export default CustomCarousel;
