import React, {useEffect, useState} from 'react'
import {ReactComponent as BackArrow} from "../../assets/arrow-back.svg";
import {ReactComponent as SeekLogo} from "../../assets/seek-logo.svg";

// import {ReactComponent as ReferralBanner} from "../../assets/referral-page-image.svg";
import  ReferralBannerUrl from "../../assets/webp-images/referral-page-image.webp";


import {ReactComponent as CopyIcon} from "../../assets/copy-icon.svg";
import {ReactComponent as VerticalBar} from "../../assets/vertical_bar.svg";
import {ReactComponent as LineSeparator} from "../../assets/line-seperator.svg";
import './Referral.css'
import {useLocation, useNavigate} from "react-router-dom";
import {Mixpanel} from "../../analytics/Mixpanel";
import CustomComponent from "../../components/componentWithBanner/CustomComponent";
import imageUrl from "../../assets/code_bg.svg";
import Button from "../../components/button/Button";
import {COLORS} from "../../utils/colors";
import ReferralRow from "./components/ReferralRow";
import { ReactComponent as ShareIcon } from "../../assets/share_link.svg";
import { ReactComponent as UseCodeIcon } from "../../assets/use-code.svg";
import { ReactComponent as OfferIcon } from "../../assets/referral-offer.svg";
import { ReactComponent as Calendar } from "../../assets/calander.svg";
import { ReactComponent as NoLimitIcon } from "../../assets/no-limit-icon.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "../../components/model/Model";
import {
    EmailIcon,
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon
} from 'react-share';
import {getLiveStreamingReferralCode} from "../../services/Firebase";
import Loader from "../../components/loader/Loader";


export const ReferralPoints = [
    {
        icon:<ShareIcon width={32} height={32}/>,
        title:'Share the link or your referral code with\n' +
              'your friend.'
    },
    {
        icon:<UseCodeIcon width={32} height={32}/>,
        title:'Ask them to use the code while they sign-up.'
    },
    {
        icon:<OfferIcon width={32} height={32}/>,
        title:'They will get 25% off on their plan, after successful sign-up.'
    },
    {
        icon:<Calendar width={32} height={32}/>,
        title:'You will get 7 days extension once they attend their first live class.'
    },
    {
        icon:<NoLimitIcon width={32} height={32}/>,
        title:'There is no limit of on the number of referrals. If you refer more friends, then you will get more extensions on you plan.'
    }
]


const Referral = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isCopied, setIsCopied] = useState(false);
    const [code,setCode] = useState('')
    const [openSharePopup, setOpenSharePopup] = useState(false);
    const location = useLocation();
    const { state } = location;

    const userId = state?.userId;

    const shareUrl = 'https://www.seekdaily.live';


    const msg = `Hello, 
Seek has few of the best meditation teachers 🧑‍🏫 in the country 🇮🇳 and you can attend their daily meditation classes on your convenient time slot ⏲️.
If you use my referral code 🌟${code}🌟during the sign-up then you will get up to 25% off on the regular price.
So what are you waiting for? Click on the link below 👇 to join the Global Community of Seek`;


    const onShareReferralWithLinkHandler = () => {
        Mixpanel.track('click_refer_share_link',{
            event_action:'clicked',
            event_page:'referral_page',
        });
        if (navigator.share) {
            navigator.share({
                title: 'Share GiftCode',
                text: `${msg} ${shareUrl}`,
            })
                .then(() => console.log('Shared successfully'))
                .catch((error) => console.error('Error sharing:', error));
        }else{
            setOpenSharePopup(true);
        }
    };


    useEffect(()=>{
        (async () =>{
            const referralCodeRes = await getLiveStreamingReferralCode(userId);
            setCode(referralCodeRes?.code?.code)
        })();
    },[userId])


    useEffect(()=>{
        if(userId){
            Mixpanel.track('view_refer_page',{
                event_action:'viewed',
                context_user_id:userId,
                event_page:'referral_page',
            });
        }
    },[userId])



    const handleCopyClick = () => {
        navigator.clipboard.writeText(code).then(() => {
            Mixpanel.track('click_copy_referral_link',{
                event_action:'clicked',
                event_page:'referral_page',
            });
            setIsCopied(true);
            toast.success('Code copied to clipboard!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true,
            });
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        });
    };

    return (
        <div className={'referral-page-container'}>
            <div className={'referral-container'} style={{justifyContent:'space-between',alignItems:'center'}}>
                <BackArrow onClick={navigate.bind(null,-1)} width={28} height={28} />
                <SeekLogo style={{marginRight:'28px'}} width={84} height={44} />
                <div/>
            </div>

            {/*<ReferralBanner style={{marginTop:'16px'}} height={320} width={320} />*/}
            <img
                src={ReferralBannerUrl}
                alt="Referral Banner"
                style={{ marginTop: '16px', height: '320px', width: '320px' }}
            />
            <div className={'referral-page-title'}>Spread the Love</div>
            <div className={'referral-page-subtitle'}>Gift your friend 25% off <div>(members only price)</div> on Seek Daily Plan and get a 7 days extension gift on your plan.</div>

            <CustomComponent classname={'referral-page-code-bg'} imageUrl={imageUrl}>
                <div onClick={handleCopyClick} style={{display:'flex',flexDirection:'row'}}>
                    {code?<div className={'referral-page-code'}>{code}</div>:<Loader/>}
                    <VerticalBar style={{marginLeft:'6px',marginRight:'2px'}} height={24} width={24} />
                    <CopyIcon height={24} width={24} />
                </div>
            </CustomComponent>

            <LineSeparator style={{marginTop:'16px',alignSelf:'center'}}/>
            <Button
                isSticky={false}
                style={{ backgroundColor: COLORS.PRIMARY, alignSelf: 'center',marginTop:'16px' }}
                onClick={onShareReferralWithLinkHandler}
                label={'Share Link'}
            />

            <Modal show={openSharePopup} showHeader={true} onHide={setOpenSharePopup.bind(null,false)} title={'Share via'} content={ <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                <FacebookShareButton url={shareUrl} title={msg}>
                    <FacebookIcon size={32} round={true}/>
                </FacebookShareButton>

                <WhatsappShareButton url={shareUrl} title={msg}>
                    <WhatsappIcon size={32} round={true}/>
                </WhatsappShareButton>

                <EmailShareButton url={shareUrl} body={msg}>
                    <EmailIcon size={32} round={true}/>
                </EmailShareButton>
                <TwitterShareButton url={shareUrl} title={msg}>
                    <TwitterIcon size={32} round={true}/>
                </TwitterShareButton>

                <LinkedinShareButton url={shareUrl} title={msg}>
                    <LinkedinIcon size={32} round={true}/>
                </LinkedinShareButton>

            </div>}/>


            <div className={'referral-page-title'} style={{alignSelf:'start',marginTop:'32px'}}>Instructions to use...</div>
            {
                ReferralPoints?.map((item,index) => <ReferralRow icon={item.icon} title={item.title}/>)
            }
            <ToastContainer />
        </div>
    )
}

export default Referral;
