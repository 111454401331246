import React from 'react'
import './Logout.css'
import {ReactComponent as LogoutIcon} from "../../assets/logout-icon.svg";

const Logout = ({onLogoutCLickHandler}:any) => {
    return (
        <div onClick={onLogoutCLickHandler} className={'logout-container'}>
            <LogoutIcon width={24} height={24}/>
            <span className={'logout-title'}> Logout</span>
        </div>
    )
}

export default Logout
