import React from 'react';
import './Header.css';
import { ReactComponent as Coins } from '../../assets/new-icons/coins.svg';
import HorizontalChipWithIconAndTitle from '../chips/HorizontalChipWithIconAndTitle';

interface HeaderProps {
	logoUrl: string;
	icon?: any;
	title?: string;
	karma_points: string;
	onClick: any;
	isDark?: boolean;
}

const Header: React.FC<HeaderProps> = ({
	logoUrl,
	icon,
	title,
	karma_points,
	onClick,
	isDark,
}) => {
	return (
		<header
			className={isDark ? 'header-v2-container dark' : 'header-v2-container'}>
			<div className='header-v2-left-content'>
				<img
					style={{ backgroundColor: isDark ? 'transparent' : 'white' }}
					src={logoUrl}
					alt='Logo'
					className='header-v2-logo'
				/>
			</div>
			{parseInt(karma_points) >= 0 && (
				<div className='header-v2-right-content'>
					<div
						style={{ borderColor: isDark ? '#4a4a4a' : '#e4e4e4' }}
						className='header-v2-icon-title-container'>
						<HorizontalChipWithIconAndTitle
							onIconClick={onClick}
							styles={{
								icon: { width: '32px', height: '32px' },
								title: {
									color: isDark ? '#ffffff' : 'var(--Body-Font, #4A4A4A)',
									fontFamily: 'Gordita-Bold',
									fontSize: '14px',
									fontStyle: 'normal',
									fontWeight: 700,
									lineHeight: '20px',
								},
								subtitle: {
									color: isDark ? '#ffffff' : 'var(--Body-Font, #4A4A4A)',
								},
							}}
							Icon={Coins}
							title={karma_points}
							subtitle={'KP'}
						/>
					</div>
				</div>
			)}
		</header>
	);
};
export default Header;
