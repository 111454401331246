// authService.js

const AUTH_KEY = 'myAppAuthState';

export const storeLoginAuthState = async (user) => {
    localStorage.setItem(AUTH_KEY, user);
};

export const storeLogoutAuthState = () => {
    localStorage.removeItem(AUTH_KEY);
};

export const isAuthenticated = () => {
    return localStorage.getItem(AUTH_KEY) !== null;
};

export const getAuthStateUser = () => {
    return  localStorage.getItem(AUTH_KEY);

};


export const store = (key,value) =>{
    localStorage.setItem(key, value);
}

export const get = (key) =>{
    return localStorage.getItem(key);
}

export const clear = (key) =>{
    localStorage.removeItem(key);
}
