import React from 'react'
import Modal from "../../components/model/Model";
import pause_Banner_url from "../../assets/webp-images/pause_banner.webp";
import {ReactComponent as PauseIconOne} from "../../assets/pause_icon_one.svg";
import {ReactComponent as PauseIconTwo} from "../../assets/pause_icon_two.svg";
import {ReactComponent as PauseIconThree} from "../../assets/pause_icon_three.svg";
import './PausePlanModel.css'


interface IconWithTitleProps {
    icon: React.ReactNode;
    title_one: string;
    subtitle?:string;
}

const IconWithTitle: React.FC<IconWithTitleProps> = ({icon, title_one,subtitle}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            width: 'auto',
            alignItems: 'center',
            marginTop: '12px'
        }}>
            {icon && <div style={{marginTop: '4px', marginLeft: '8px'}}>{icon}</div>}
            <div style={{marginTop: '4px', textAlign: 'start', marginLeft: '6px'}}>
                <span className={'pause-list-item'} style={{margin: 0}}>{title_one} <span>{subtitle}</span></span>
            </div>
        </div>
    );
};

function PausePlanModel({ setShowPauseBanner, showPauseBanner }:any) {
    return (
   <Modal show={showPauseBanner} showHeader={false}
                          onHide={setShowPauseBanner.bind(null, false)}
                          title={''} content={<div
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'auto'
        }}>
        <img src={pause_Banner_url} alt="Consistency Card" style={{width: '324px', height: '264px'}}/>
        <div className={'pause-title'}>Membership Pause</div>
        <IconWithTitle
            icon={<PauseIconOne width={32} height={32}/>}
            title_one="In case of travel, unavoidable health problems, a stressful week at office or a personal emergency, you can pause your membership "
        />
        <svg style={{marginTop: '10px'}} xmlns="http://www.w3.org/2000/svg" width="313" height="2" viewBox="0 0 313 2"
             fill="none">
            <path d="M1 0.956177H312" stroke="#E4E4E4" stroke-linecap="round" stroke-dasharray="8 8"/>
        </svg>


        <IconWithTitle
            icon={<PauseIconTwo width={32} height={32}/>}
            title_one="When you pause for a certain duration, "
            subtitle={'those many days will get added to your membership plan when you resume'}
        />
        <svg style={{marginTop: '10px'}} xmlns="http://www.w3.org/2000/svg" width="313" height="2" viewBox="0 0 313 2"
             fill="none">
            <path d="M1 0.956177H312" stroke="#E4E4E4" stroke-linecap="round" stroke-dasharray="8 8"/>
        </svg>
        <IconWithTitle
            icon={<PauseIconThree width={32} height={32}/>}
            title_one="Contact us to pause anytime and we will do it right away, as many times as you want"
        />
        <div onClick={setShowPauseBanner.bind(null, false)} style={{
            position: 'absolute',
            background: 'transparent',
            width: '24px',
            height: '24px',
            right: 28,
            top: 28
        }}></div>
    </div>}/>
    )
}

export default PausePlanModel
