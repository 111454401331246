import { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import {getCurrentStreamingPlanByUserid} from "../services/Firebase";
import {updateDaysToExpire, updateUsersPlan} from "../store/features/PlanSlice";
import {setReloadPlan} from "../store/features/AuthSlice";


const useFetchStreamingPlan = (userData:any, user:any, reloadPlan:boolean) => {
    const dispatch = useDispatch();
    const activeStreamingPlan = useSelector((state: any) => state.plan?.userPlan?.activeStreamingPlan);
    // console.log("useFetchStreamingPlan",activeStreamingPlan)
    useEffect(() => {
        (async () => {
            if ((isEmpty(activeStreamingPlan) && (userData?.uid || user?.uid)) || (reloadPlan && (userData?.uid || user?.uid))) {
                try {
                    // console.log("useFetchStreamingPlan calling api")
                    const activePlanRes = await getCurrentStreamingPlanByUserid(userData.uid || user?.uid);
                    if (activePlanRes.activePlan != null) {
                        const formattedEndDate = dayjs(new Date(activePlanRes.activePlan?.userLiveStreamingPlans[0]?.endDate?._seconds * 1000)).format('MMM DD YYYY');
                        const todayDate = dayjs().format('MMM DD YYYY');
                        const date1 = dayjs(formattedEndDate);
                        const date2 = dayjs(todayDate);
                        const daysDifference = date1.diff(date2, 'day');
                        dispatch(updateDaysToExpire(daysDifference));
                        dispatch(updateUsersPlan(activePlanRes.activePlan?.userLiveStreamingPlans[0]));
                        dispatch(setReloadPlan(false));
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        })();
    }, [userData, user, reloadPlan, activeStreamingPlan]);
};

export default useFetchStreamingPlan;
