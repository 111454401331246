import React, {useEffect, useState} from 'react'
import './ManageReminders.css'
import {useLocation, useNavigate} from "react-router-dom";
import useAuthenticatedUsers from "../../hooks/useAuthenticatedUsers";
import {getUserByUserId, updateUser} from "../../services/Firebase";
import {isEmpty, managedNotificationPreferences} from "../../utils/utils";
import {Spinner} from "react-bootstrap";
import {COLORS} from "../../utils/colors";
import {Mixpanel} from "../../analytics/Mixpanel";
import Header from "../../components-v2/header/Header";
import seek_logo_url from "../../assets/seek-logo.svg";
import BackButton from "../../components-v2/buttons/BackButton";
import {useDispatch, useSelector} from "react-redux";
import {updateEventsStatus} from "../../store/features/EventsSlices";
import {addUsers} from "../../store/features/AuthSlice";
import SingleToggleComponent from "./components/SingleToggleComponent";

const ManageReminders = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const userData = useAuthenticatedUsers();
    const location = useLocation();
    const user = useSelector((state: any) => state?.auth?.user?.data);
    // console.log("user ManageReminders",user);
    const navigate = useNavigate()
    const [loader, setLoader] = useState<boolean>(false);


    useEffect(() => {
        if (isEmpty(user) && userData?.uid) {
            getUserByUserId(userData?.uid).then((userLoginResult) => {
                // console.log("userLoginResult",userLoginResult?.userData);
                dispatch(addUsers(userLoginResult.userData))
                Mixpanel.identify(userLoginResult?.userData?.uid)
            })
        }
    }, [userData, user])

    const [selectedSlotCount, setSelectedSlotCount] = useState<number>(
        user?.notificationPreference
            ? 1
            : 0
    );

    const [userNotificationPreference, setUserUserNotificationPreference] = useState<boolean>(
        !!user?.notificationPreference
    );

    useEffect(() => {
        setSelectedSlotCount(
            user?.notificationPreference
                ? 1
                : 0
        );
        setUserUserNotificationPreference(
            !!user?.notificationPreference
        );
    }, [user]);


    const onToggleSlotHandler = (slotValue: string, isChosen: boolean) => {
        // console.log("userNotificationPreference onToggleSlotHandler", userNotificationPreference, isChosen)
        Mixpanel.track('click_notification_preference_toggle', {
            event_action: 'clicked',
            notification_toggle_status: isChosen,
            event_page: 'notification_preference',
        });
        setUserUserNotificationPreference(isChosen);
        setSelectedSlotCount(isChosen ? 1 : 0)
        onSlotUpdateHandler(isChosen)

    };

    const dispatch = useDispatch();
    const view_notification_preference_page = useSelector((state: any) => state?.events?.eventsLog?.view_notification_preference_page);

    useEffect(() => {
        if (!view_notification_preference_page && userNotificationPreference) {
            Mixpanel.track('view_notification_preference_page', {
                event_action: 'viewed',
                event_page: 'notification_preference',
                reminder_time_slots: userNotificationPreference
            });
            dispatch(updateEventsStatus({key: 'view_notification_preference_page', value: true}))
        }
    }, [view_notification_preference_page, userNotificationPreference])


    const onSlotUpdateHandler = (isChosen: any) => {
        updateUser(user?.uid || userData?.uid, {'notificationPreference': isChosen}).then((updatedUser:any) => {
            console.log("onSlotUpdateHandler user",updatedUser?.user);
            dispatch(addUsers(updatedUser?.user))
        });
    }


    const onKarmaPointsClickHandler = () => {
        Mixpanel.track('click_karma_points_badge', {
            event_action: 'clicked',
            context_user_id: user?.uid,
            total_karma_points: user?.total_karma || 0,
            event_page: 'home_screen',
        });

        navigate('/karma', {state: {email: user?.email, total_karma: user?.total_karma}, replace: false})
    }

    const onBackPress = () => {
        if (location.key === 'default') {
            window.location.href = 'https://seekdaily.live/';
        } else {
            navigate(-1);
        }
    }

    return (
        <div className={'manage-reminder-container'}>
            <Header onClick={onKarmaPointsClickHandler} logoUrl={seek_logo_url} karma_points={user?.total_karma}/>
            <div style={{width: '90%', maxWidth: '400px', marginTop: '24px'}}>
                <BackButton onClick={onBackPress}/>
            </div>
            <div className={'manage-reminder-body-container'}>
                <div className={'manage-reminder-title'}>Notification Preferences</div>
                {
                    loader ? <Spinner style={{
                        color: COLORS.PRIMARY,
                        alignSelf: 'center',
                        marginTop: '40%'
                    }}></Spinner> : managedNotificationPreferences?.map((slot: any, index: number) =>
                        <SingleToggleComponent
                            index={index}
                            selectedSlotCount={selectedSlotCount} onToggleSlot={onToggleSlotHandler}
                            slotValue={slot?.slotValue}
                            isSelected={userNotificationPreference} title={slot?.title} subtitle={slot?.subtitle}/>)
                }
            </div>
            {/*<Button isSticky={true} style={{alignSelf: 'center', marginBottom: '24px'}} onClick={onSlotUpdateHandler}*/}
            {/*        label={'Change Reminder'}></Button>*/}
        </div>
    )
}

export default ManageReminders;
