import React, { useState} from 'react';
import './SeekGurusCard.css';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ShimmerLoader from "../shimmer/Shimmer";

interface SeekGurusCardProps {
    title: string,
    subtitle: string,
    imageUrl: string,
    conversations:string,
}

const SeekGurusCard: React.FC<SeekGurusCardProps> = ({title,subtitle,imageUrl,conversations}) => {

    return (
        <div className="seek-guru-card-container">
            <div className="seek-guru-card-horizontal">
                <div className="seek-guru-image-wrapper">
                    <LazyLoadImage
                        className="seek-guru-card-image-container"
                        src={imageUrl}
                        effect="blur"
                        onLoad={() => <ShimmerLoader classname="seek-guru-image-placeholder" />}
                    />
                    <svg className="online-indicator" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" fill="#7ED321" stroke="white" strokeWidth="2.12471"/>
                    </svg>
                </div>


                <div className="seek-guru-card-content">
                    <span className="seek-guru-card-title">{title}</span>
                    <span className="seek-guru-card-subtitle">{subtitle}</span>
                    <span className="seek-guru-card-description">{conversations}</span>
                </div>
            </div>
        </div>
    );
};

export default SeekGurusCard;
