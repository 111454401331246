import React from 'react';
import tile1 from './../../assets/webp-images/landing-gallery-tile-1.webp';
import tile2 from './../../assets/webp-images/landing-gallery-tile-2.webp';
import tile3 from './../../assets/webp-images/landing-gallery-tile-3.webp';
import tile4 from './../../assets/webp-images/landing-gallery-tile-4.webp';
import tile5 from './../../assets/webp-images/landing-gallery-tile-5.webp';
import tile6 from './../../assets/webp-images/landing-gallery-tile-6.webp';
import tile7 from './../../assets/webp-images/landing-gallery-tile-7.webp';
import tile8 from './../../assets/webp-images/landing-gallery-tile-8.webp';
import tile9 from './../../assets/videos/landing-gallery-tile-9.mp4';
import tile10 from './../../assets/webp-images/landing-gallery-tile-10.webp';
import tile11 from './../../assets/webp-images/landing-gallery-tile-11.webp';
import tile12 from './../../assets/webp-images/landing-gallery-tile-12.webp';
import tile13 from './../../assets/videos/landing-gallery-tile-13.mp4';
import tile14 from './../../assets/webp-images/landing-gallery-tile-14.webp';
import tile15 from './../../assets/webp-images/landing-gallery-tile-15.webp';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import './TileGallery.css';
import TileGalleryVideo from './TileGalleryVideo';

interface TileGalleryProps {}

const TileGallery: React.FC<TileGalleryProps> = () => {
	return (
		<Swiper
			className='tile-swiper'
			slidesPerView={0.5}
			freeMode={true}
			spaceBetween={87}
			allowTouchMove={false}
			autoplay={{ delay: 0, disableOnInteraction: false }}
			modules={[Autoplay]}
			loop={true}
			speed={15000}>
			<SwiperSlide className='tile-swiper-slide'>
				<div className='tile-gallery'>
					<div className='tile-gallery-column'>
						<img className='tile-gallery-column-image' src={tile1} alt='' />
						<img className='tile-gallery-column-image' src={tile2} alt='' />
						<img className='tile-gallery-column-image' src={tile3} alt='' />
					</div>
					<div className='tile-gallery-column'>
						<img className='tile-gallery-column-image' src={tile4} alt='' />
						<img className='tile-gallery-column-image' src={tile5} alt='' />
						<img className='tile-gallery-column-image' src={tile6} alt='' />
					</div>
					<div className='tile-gallery-column'>
						<img className='tile-gallery-column-image' src={tile7} alt='' />
						<img className='tile-gallery-column-image' src={tile8} alt='' />
						<TileGalleryVideo video={tile9} text='Trataka Meditation' />
					</div>
					<div className='tile-gallery-column'>
						<img className='tile-gallery-column-image' src={tile10} alt='' />
						<img className='tile-gallery-column-image' src={tile11} alt='' />
						<img className='tile-gallery-column-image' src={tile12} alt='' />
					</div>
					<div className='tile-gallery-column'>
						<TileGalleryVideo video={tile13} text='Chair Yoga' />
						<img className='tile-gallery-column-image' src={tile14} alt='' />
						<img className='tile-gallery-column-image' src={tile15} alt='' />
					</div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className='tile-gallery alternate'>
					<div className='tile-gallery-column'>
						<img className='tile-gallery-column-image' src={tile1} alt='' />
						<img className='tile-gallery-column-image' src={tile2} alt='' />
						<img className='tile-gallery-column-image' src={tile3} alt='' />
					</div>
					<div className='tile-gallery-column'>
						<img className='tile-gallery-column-image' src={tile4} alt='' />
						<img className='tile-gallery-column-image' src={tile5} alt='' />
						<img className='tile-gallery-column-image' src={tile6} alt='' />
					</div>
					<div className='tile-gallery-column'>
						<img className='tile-gallery-column-image' src={tile7} alt='' />
						<img className='tile-gallery-column-image' src={tile8} alt='' />
						<TileGalleryVideo video={tile9} text='Trataka Meditation' />
					</div>
					<div className='tile-gallery-column'>
						<img className='tile-gallery-column-image' src={tile10} alt='' />
						<img className='tile-gallery-column-image' src={tile11} alt='' />
						<img className='tile-gallery-column-image' src={tile12} alt='' />
					</div>
					<div className='tile-gallery-column'>
						<TileGalleryVideo video={tile13} text='Chair Yoga' />
						<img className='tile-gallery-column-image' src={tile14} alt='' />
						<img className='tile-gallery-column-image' src={tile15} alt='' />
					</div>
				</div>
			</SwiperSlide>
		</Swiper>
	);
};

export default TileGallery;
