import React, {useEffect} from 'react'
import {Container} from "react-bootstrap";
import './PocCheckout.css'

interface CheckoutPageProps {
    payNowClickHandler: (price: number) => void,
}

const PocCheckout: React.FC<CheckoutPageProps> = ({
                                                       payNowClickHandler,
                                                   }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container className={'poc-checkout-container'}>
            <span className={'poc-checkout-details'}>Checkout Details</span>
            <div style={{background: '#F0F2FE', borderRadius: '12px', marginTop: '12px',width:'95%', maxWidth:'414px', padding: '12px'}}>
                <div className={'poc-checkout-plan-details-container'}>
                    <span className={'poc-checkout-plan-start-date'}>{'Minimum Duration'}</span>
                    <span className={'poc-checkout-plan-start-date-value'}>{`5 Mins`}</span>
                </div>

                <svg style={{marginTop:'-12px',marginBottom:'-12px'}} xmlns="http://www.w3.org/2000/svg" width="321" height="2" viewBox="0 0 321 2" fill="none">
                    <path d="M1 1H320" stroke="#E4E4E4" stroke-width="0.64" stroke-linecap="round" stroke-dasharray="4 4"/>
                </svg>
                <div className={'poc-checkout-plan-details-container'}>
                    <span className={'poc-checkout-plan-start-date'}>{'Total Amount to Pay'}</span>
                    <span className={'poc-checkout-plan-start-date-value'}>{`INR  75`}</span>
                </div>
                <svg style={{marginTop:'-12px',marginBottom:'-12px'}} xmlns="http://www.w3.org/2000/svg" width="321" height="2" viewBox="0 0 321 2" fill="none">
                    <path d="M1 1H320" stroke="#E4E4E4" stroke-width="0.64" stroke-linecap="round" stroke-dasharray="4 4"/>
                </svg>
                <div className={'poc-checkout-plan-details-container'}>
                    <span className={'poc-checkout-plan-start-date'}>{'Additional Charge/min'}</span>
                    <span className={'poc-checkout-plan-start-date-value'}>{`INR  15`}</span>
                </div>
            </div>
            <span className={'poc-checkout-note'}><span>Note:</span> You will be redirected to WhatsApp chat with the expert after successful payment.</span>
            <div style={{marginBottom: '132px'}}></div>
            <div className="poc-fixed-bottom-container" style={{paddingBottom:'12px'}}>
                <span className="poc-fixed-bottom-price">{'₹' + 75}</span>
                <button className="poc-fixed-bottom-button " onClick={payNowClickHandler.bind(null, 75)}>
                    {'Pay Now'}
                </button>
            </div>
        </Container>
    )
}

export default PocCheckout
