import React, { useCallback, useEffect, useState } from 'react';
import './CourseNudge.css';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../services/Firebase';
import { useDispatch, useSelector } from 'react-redux';
import { Mixpanel } from '../../analytics/Mixpanel';
import { updateEventsStatus } from '../../store/features/EventsSlices';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import angerNudgeL2 from '../../assets/anger-nudge-l2.png';
import angerShimmer from '../../assets/anger-nudge-shimmer.png';

interface CourseNudgeProps {
	title: string;
	subtitle: string;
	description: string;
	onClick: any;
	class_name: string;
	thumbnailImageUrl: string;
	instructor: string;
}

const CourseNudge: React.FC<CourseNudgeProps> = ({
	onClick,
	class_name,
	title,
	subtitle,
	description,
	thumbnailImageUrl,
	instructor,
}) => {
	const view_session_upcoming_courses = useSelector(
		(state: any) => state?.events?.eventsLog?.view_session_upcoming_courses
	);
	const dispatch = useDispatch();

	const logViewEvent = useCallback(() => {
		if (!view_session_upcoming_courses && title) {
			Mixpanel.track('view_course_nudge', {
				event_action: 'viewed',
				event_page: 'class_page',
				class_name: class_name,
				course_name: title,
				instructor_name: instructor,
			});
			dispatch(
				updateEventsStatus({
					key: 'view_session_upcoming_courses',
					value: true,
				})
			);
		}
	}, [view_session_upcoming_courses, title]);

	const cardRef = useIntersectionObserver(logViewEvent, { threshold: 0.5 });

	const [imageUrl, setImageUrl] = useState('');
	useEffect(() => {
		(async () => {
			try {
				const imageRef = ref(storage, thumbnailImageUrl);
				const imageUrl = await getDownloadURL(imageRef);
				setImageUrl(imageUrl);
			} catch (error) {
				console.error('Error fetching image:', error);
			}
		})();
	}, [thumbnailImageUrl]);

	return (
		<div
			onClick={onClick}
			ref={cardRef}
			style={{
				padding: title === 'Freedom from Anger' ? '0' : '8px 12px',
				height: title === 'Freedom from Anger' ? 'auto' : '84px',
				position: 'relative',
			}}
			className='course-vertical-card-container'>
			{title === 'Freedom from Anger' ? (
				<>
					<img className='anger-nudge-l2' src={angerNudgeL2} alt='' />
					<img className='anger-nudge-shimmer' src={angerShimmer} alt='' />
				</>
			) : (
				<>
					<div className='course-vertical-card-content'>
						<span className='course-vertical-card-title'>{title}</span>
						<span className='course-vertical-card-subtitle'>
							{'Starts on'} <span> {subtitle}</span>
						</span>
						<span className='course-vertical-card-description'>
							{description}
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								viewBox='0 0 16 16'
								fill='none'>
								<path
									d='M5.75 3.5L10.25 8L5.75 12.5'
									stroke='url(#paint0_linear_696_7555)'
									stroke-width='1.5'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
								<defs>
									<linearGradient
										id='paint0_linear_696_7555'
										x1='7.64205'
										y1='3.5'
										x2='11.7604'
										y2='4.09272'
										gradientUnits='userSpaceOnUse'>
										<stop stop-color='#4462EF' />
										<stop offset='1' stop-color='#1B3DD8' />
									</linearGradient>
								</defs>
							</svg>
						</span>
					</div>
					<div
						style={{
							alignSelf: 'center',
							marginRight: '-16px',
							width: 112,
							height: 84,
							background: `linear-gradient(270deg, rgba(255, 255, 255, 0.00) 55.34%, #FFF6EF 94.39%), url(${imageUrl}) lightgray 50% / cover no-repeat`,
						}}
					/>
				</>
			)}
		</div>
	);
};

export default CourseNudge;
