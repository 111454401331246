import { configureStore } from '@reduxjs/toolkit';
import leaderboardReducer from '../store/features/LeaderboardSlice';
import authReducer from '../store/features/AuthSlice';
import planReducer from '../store/features/PlanSlice';
import upcomingClassesReducer from '../store/features/UpcomingClassesSlice';
import bottomNavReducer from '../store/features/BottomNavSlice';
import eventsSliceReducer from '../store/features/EventsSlices';
import pocFormReducer from '../store/features/PocCounsellingSlice';
import utmDetailsReducer from '../store/features/UtmSlice';
import betaUsersReducer from '../store/features/BetaUsers';

export const store = configureStore({
	reducer: {
		leaderboard: leaderboardReducer,
		auth: authReducer,
		plan: planReducer,
		betaUsers:betaUsersReducer,
		upcomingClasses: upcomingClassesReducer,
		bottomNav: bottomNavReducer,
		events: eventsSliceReducer,
		pocFormReducer: pocFormReducer,
		utmDetails: utmDetailsReducer,
		// Add other reducers if needed
	},
});
