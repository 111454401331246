import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    force_refresh_on_course_purchase:false,
    upcomingClasses:{
        loading:true,
        data:[],
    },
    upcomingCourse:{
        loading:true,
        data:[],
    },
    nudges:{
        loading:true,
        data:null,
    },
    favouriteClasses:{
        loading:true,
        data:[],
    }
}

export const upcomingCLassesSlice = createSlice({
    name:'upcomingClasses',
    initialState,
    reducers:{
        setUpcomingClasses:(state,action)=>{
            // console.log("upcomingClasses state",state);
            // console.log("upcomingClasses action",action);
            state.upcomingClasses.loading = false;
            state.upcomingClasses.data = action.payload;
        },
        setUpcomingCourses:(state,action)=>{
            // console.log("upcomingClasses state",state);
            // console.log("upcomingClasses action",action);
            state.upcomingCourse.loading = false;
            state.upcomingCourse.data = action.payload;
        },
        setNudges:(state,action)=>{
            // console.log("upcomingClasses state",state);
            // console.log("upcomingClasses action",action);
            state.nudges.loading = false;
            state.nudges.data = action.payload;
        },
        setFavouriteClasses:(state,action)=>{
            // console.log("upcomingClasses state",state);
            // console.log("upcomingClasses action",action);
            state.favouriteClasses.loading = false;
            state.favouriteClasses.data = action.payload;
        }, setForceRefreshOnCoursePurchase:(state,action)=>{
            state.force_refresh_on_course_purchase= action.payload;
        },

    }
})

export const {setUpcomingClasses,setUpcomingCourses,setFavouriteClasses,setNudges,setForceRefreshOnCoursePurchase} = upcomingCLassesSlice.actions;

export default upcomingCLassesSlice.reducer
