import React, { useCallback } from 'react';
import './Nudge.css';
import { Mixpanel } from '../../analytics/Mixpanel';
import { updateEventsStatus } from '../../store/features/EventsSlices';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import angerNudge from '../../assets/anger-nudge.png';
import angerShimmer from '../../assets/anger-nudge-shimmer.png';
import { useDispatch, useSelector } from 'react-redux';

interface NudgeProps {
	Icon: any;
	title: string;
	index: number;
	isVisible: boolean;
	onClick: any;
	redirection_link: string;
	nudge_type: string;
	size: number;
	course_name: string;
}

export const Nudge: React.FC<NudgeProps> = ({
	Icon,
	size,
	course_name,
	nudge_type,
	title,
	index,
	isVisible,
	onClick,
	redirection_link,
}) => {
	const dispatch = useDispatch();
	const view_plan_renewal = useSelector(
		(state: any) => state?.events?.eventsLog?.view_plan_renewal
	);
	const view_course_nudge = useSelector(
		(state: any) => state?.events?.eventsLog?.view_course_nudge
	);
	const activeStreamingPlan = useSelector(
		(state: any) => state.plan?.userPlan?.activeStreamingPlan
	);
	const daysToExpire = useSelector(
		(state: any) => state.plan?.userPlan?.daysToExpire
	);

	const logViewEvent = useCallback(() => {
		if (!view_plan_renewal && nudge_type === 'Plan') {
			Mixpanel.track('view_plan_renewal', {
				event_action: 'viewed',
				days_left: daysToExpire,
				plan_duration: activeStreamingPlan?.active_plan_name?.toLowerCase(),
				// course_date: courseDate,
				event_page: 'live_classes',
				// instructor_name:subtitle,
				// course_type:course_type,
			});
			dispatch(updateEventsStatus({ key: 'view_plan_renewal', value: true }));
		}
		if (!view_course_nudge && nudge_type === 'Course') {
			Mixpanel.track('view_course_nudge', {
				event_action: 'viewed',
				course_name: course_name,
				event_page: 'live_classes',
			});
			dispatch(updateEventsStatus({ key: 'view_course_nudge', value: true }));
		}
	}, [view_plan_renewal, view_course_nudge, nudge_type]);

	const cardRef = useIntersectionObserver(logViewEvent, { threshold: 0.5 });

	if (!isVisible) return null;

	const renderTitle = (title: string) => {
		const parts = title.split(/(<b>)/i).filter(Boolean);
		console.log('parts', parts);
		return parts.map((part, i) => {
			if (part.toLowerCase() === '<b>') {
				const nextPart = parts[i + 1]?.trim();
				const firstWord = nextPart?.split(' ')[0]; // Get the first word after <b>
				const remainingText = nextPart?.substring(firstWord?.length).trim(); // Get the remaining text after the first word
				return (
					<span key={i}>
						<span
							style={{
								color: '#1B1625',
								fontFamily: 'Gordita-Medium',
								fontSize: '14px',
								fontStyle: 'normal',
								fontWeight: '500',
								lineHeight: '20px',
							}}>{` ${firstWord}`}</span>
						{}
						{remainingText && <span>{` ${remainingText}`}</span>}
					</span>
				);
			}
			if (i > 0 && parts[i - 1]?.toLowerCase() === '<b>') {
				return null;
			}
			return <span key={i}>{part?.trim()}</span>;
		});
	};

	return (
		<div
			ref={cardRef}
			onClick={onClick.bind(null, index)}
			className='nudge-container'
			style={{
				marginLeft:
					course_name === 'Freedom from Anger' && size === 1 ? 'auto' : '3px',
				marginRight:
					course_name === 'Freedom from Anger' && size === 1 ? 'auto' : '3px',

				backgroundImage:
					course_name === 'Freedom from Anger' ? `url(${angerNudge})` : '',
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				height: '40px',
				position: 'relative',
				width: 'auto',
				padding: course_name === 'Freedom from Anger' ? '0' : '8px',
				border:
					course_name !== 'Freedom from Anger'
						? nudge_type === 'Course'
							? '1px solid #AD247B'
							: '1px solid #008080'
						: 'none',
			}}>
			{course_name !== 'Freedom from Anger' ? (
				<>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignSelf: 'center',
						}}>
						<Icon className={'nudge-icon'}></Icon>
						<span className='nudge-title'> {renderTitle(title)}</span>
					</div>

					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='16'
						height='16'
						viewBox='0 0 16 16'
						fill='none'>
						<path
							d='M5.75 3.5L10.25 8L5.75 12.5'
							stroke='url(#paint0_linear_696_6092)'
							stroke-width='1.5'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
						<defs>
							<linearGradient
								id='paint0_linear_696_6092'
								x1='7.64205'
								y1='3.5'
								x2='11.7604'
								y2='4.09272'
								gradientUnits='userSpaceOnUse'>
								<stop stop-color='#4462EF' />
								<stop offset='1' stop-color='#1B3DD8' />
							</linearGradient>
						</defs>
					</svg>
				</>
			) : (
				<img src={angerShimmer} alt='' className='anger-nudge-shimmer' />
			)}
		</div>
	);
};
