import { useEffect, useRef, MutableRefObject } from 'react';

const useIntersectionObserver = (
    callback: () => void,
    options?: IntersectionObserverInit
): MutableRefObject<HTMLDivElement | null> => {
    const elementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    callback();
                }
            });
        }, options);

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, [callback, options]);

    return elementRef;
};

export default useIntersectionObserver;
