import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    user: {
        loading: true,
        force_refresh_on_plan_purchase: false,
        reloadReminder: false,
        data: {},
    },
    userPreferences: {
        name: '',
        goal: '',
        managedReminders: ['5 AM','7 PM'],
        trialStartDate: '',
        location: '',
        priorities: [],
        onboarding_questionnaire: [],
        age: '',
        language: '',
        physical_activity_level: '',
        userExperience: '',
    },
    backScreen: 0,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        addUsers: (state, action) => {
            state.user.loading = false;
            state.user.data = action.payload;
        },
        setReloadPlan: (state, action) => {
            state.user.force_refresh_on_plan_purchase = action.payload;
        },
        setReloadReminder: (state, action) => {
            state.user.reloadReminder = action.payload;
        },
        updateUserPreference: (state, action) => {
            const {key, value} = action.payload;
            state.userPreferences[key] = value;
        },
        updateMultipleUserPreferences: (state, action) => {
            state.userPreferences = {
                ...state.userPreferences,
                ...action.payload,
            };
        },
        deleteUserPreferences: (state) => {
            state.userPreferences = {
                name: '',
                goal: '',
                managedReminders: ['5 AM','7 PM'],
                trialStartDate: '',
                location: '',
                priorities: [],
                onboarding_questionnaire: [],
                age: '',
                language: '',
                physical_activity_level: '',
                userExperience: '',
            };
        },
        updateBackScreen: (state, action) => {
            state.backScreen = action.payload;
        }
    }
})

export const {
    addUsers,
    setReloadPlan,
    setReloadReminder,
    updateMultipleUserPreferences,
    updateUserPreference,
    updateBackScreen,
    deleteUserPreferences
} = authSlice.actions;

export default authSlice.reducer;
