import React, {ChangeEvent, useEffect, useState} from 'react'
import './PocForm.css'
import './../../styles.css'
import TextInput from "../../components/textInput/TextInput";
import PhoneInput from "../../components/phoneInput/PhoneInput";
import ErrorComponent from "../../components/error/Error";
import {Mixpanel} from '../../analytics/Mixpanel';
import {events, events_action, events_page} from "../../analytics/Events";

import Loader from "../../components/loader/Loader";
import BottomContainer from "../../components-v2/buttons/BottomContainer";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {
    updateFullName,
    updatePhone,
     updateSelectedTechniqueOptionIndex,
    updateWhatDoYouWantToHeal
} from "../../store/features/PocCounsellingSlice";

type SlotOption = {
    id: number;
    label: string;
};

type Row = SlotOption[];

const techniqueRows: Row[] = [
    [{ id: 1, label: 'Reiki' }, { id: 2, label: 'Meditation' }],
    [{ id: 3, label: 'Hypnosis' }, { id: 4, label: 'Kundalini' }],
    [{ id: 5, label: 'Yoga' }, { id: 6, label: 'Not Sure' }],
];

interface PocFormProps {
    onClickChatNowHandler:(fullName:string,phone:string,whatDoYouWantToHeal:any,selectedOption:any)=>void,
    onClickChatLaterHandler:(fullName:string,phone:string,whatDoYouWantToHeal:any,selectedOption:any)=>void,
    pocCounsellingTimeWindow:boolean,
}

const PocForm:React.FC<PocFormProps> = ({onClickChatLaterHandler,onClickChatNowHandler,pocCounsellingTimeWindow}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const fullName = useSelector((state: any) => state.pocFormReducer?.pocForm?.fullName);
    const whatDoYouWantToHeal = useSelector((state: any) => state.pocFormReducer?.pocForm?.whatDoYouWantToHeal);
    const phone = useSelector((state: any) => state.pocFormReducer?.pocForm?.phone);
    const selectedOption = useSelector((state: any) => state.pocFormReducer?.pocForm?.selectedTechniqueOptionIndex);

    const [error, setError] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handleOptionClick = (optionId: number) => {
        dispatch(updateSelectedTechniqueOptionIndex(optionId));
    };

    useEffect(() => {
        try {
            Mixpanel.track('view_registeration_page', {
                event_page: 'registeration_page',
                event_action: events_action.viewed,
            })
        } catch (e) {

        }
    }, [])


    const handleFullNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setError('')
        dispatch(updateFullName(event.target.value));
    };

    const handleWhatDoYouWantToHeal = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setError('')
        dispatch(updateWhatDoYouWantToHeal(event.target.value));
    };

    const customInputStyle: React.CSSProperties = {
        // width: '300px',
        marginTop: '10px',
        height: '52px',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid #D6D6D6',
    };


    const handlePhoneChange = (value: string, country: string, e: ChangeEvent<HTMLInputElement>, formattedValue: string): void => {
        setError('')
        dispatch(updatePhone(value));
    };


    const closeErrorPopup = () => {
        setError('');
    }

    const onCLickHandler = (ctaType:string) =>{
        if(!fullName || !phone || !whatDoYouWantToHeal){
            toast.error(`Please fill the details.`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true,
            });
            return;
        }
        if(ctaType ==='chat now'){
            onClickChatNowHandler(fullName,phone,whatDoYouWantToHeal,selectedOption)
        }else{
            onClickChatLaterHandler(fullName,phone,whatDoYouWantToHeal,selectedOption)
        }
    }




    return (
        <div className={'poc-form-container'}>
            {loader && <Loader/>}
            {error && <ErrorComponent onClose={closeErrorPopup} customStyles={{
                position: 'fixed',
                justifyContent: 'flex-start',
                alignItems: 'center',
                top: 10,
                left: 10,
                right: 10
            }} message={error}/>}

            <span className={'poc-heading-title'}>Please fill the details to connect with the right expert</span>
            <span className={'form-title margin-top-30px'}>Full Name</span>
            <TextInput
                onFocus={() => {
                }}
                value={fullName}
                onChange={handleFullNameChange}
                placeholder="Enter your full name"
                // style={customInputStyle}
            />


            <span className={'poc-form-title'} style={{marginTop: '16px'}}>WhatsApp Number</span>
            <PhoneInput
                phoneNumber={phone}
                defaultCountry="in"
                onPhoneChange={handlePhoneChange}
                inputStyle={customInputStyle}
            />
            <span className={'poc-form-title'} style={{marginTop: '16px'}}>What do you want to heal?</span>
            <TextInput
                onFocus={() => {}}
                value={whatDoYouWantToHeal}
                onChange={handleWhatDoYouWantToHeal}
                placeholder="Enter your reason"
                // style={customInputStyle}
            />

            <span className={'poc-form-title'} style={{marginTop: '16px'}}>What technique you will prefer</span>
            {/*<SlotSelectors onOptionClick = {setSelectedOption}/>*/}

            <div style={{width:'100%'}}>
                {techniqueRows.map((row, rowIndex) => (
                    <div key={rowIndex} style={{ display: 'flex', width:'100%',marginTop:'12px', gap: '12px',justifyContent:'center',alignItems:'center'}}>
                        {row.map((option) => (
                            <div
                                key={option.id}
                                onClick={() => handleOptionClick(option.id)}
                                className={selectedOption === option.id?'selected-slot':'un-selected-slot'}
                            >
                                <span className={'selected-slot-text'}>{option.label}</span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <div style={{marginBottom: '132px'}}></div>

            {/*{pocCounsellingTimeWindow?<div className={"poc-buttons-container"}>*/}
            {/*    <button className="poc-chat-later"*/}
            {/*            onClick={onCLickHandler.bind(null,'chat later')}>*/}
            {/*        {'Chat Later'}*/}
            {/*    </button>*/}
            {/*    <button className="poc-chat-now"*/}
            {/*            onClick={onCLickHandler.bind(null,'chat now')}>*/}
            {/*        {'Chat Now @INR 15/min'}*/}
            {/*    </button>*/}
            {/*</div>: <BottomContainer*/}
            {/*    onButtonClick={onCLickHandler.bind(null,'chat later')}*/}
            {/*    label="Book your Slot"*/}
            {/*/>}*/}

            <BottomContainer
                onButtonClick={onCLickHandler.bind(null,'chat now')}
                label="Chat Now @INR 15/min"
            />

        </div>
    )
}

export default PocForm
