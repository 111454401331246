// import React, { useCallback, useEffect, useState } from 'react';
// import seek_logo_url, {
// 	ReactComponent as SeekLogo,
// } from '../../assets/seek-logo.svg';
// import './Leaderboard.css';
// import { useLocation, useNavigate } from 'react-router-dom';
// import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
// import Header from '../../components-v2/header/Header';
// import { Mixpanel } from '../../analytics/Mixpanel';
// import BackButton from '../../components-v2/buttons/BackButton';
// import LeaderNudge from './components/LeaderNudge';
// import { useDispatch, useSelector } from 'react-redux';
// import { getLeaderBoardUsers } from '../../services/Firebase';
// import { getUserListWithSpecialUser } from '../../utils/utils';
// import {
// 	setLeaderboardUsers,
// 	setTopFourLeaderboardUsers,
// } from '../../store/features/LeaderboardSlice';
// import debounce from 'lodash.debounce';

// const Leaderboard = () => {
// 	const userData = useAuthenticatedUsers();
// 	useEffect(() => {
// 		window.scrollTo(0, 0);
// 	}, []);
// 	const dispatch = useDispatch();

// 	const navigate = useNavigate();
// 	const location = useLocation();
// 	const { state } = location;
// 	// const [loader,setLoader] = useState<boolean>(false);
// 	const leaderboardUsers = useSelector(
// 		(state: any) => state?.leaderboard?.data?.leaderboardUsers
// 	);
// 	const user = state?.user;

// 	const currentUserLeaderboardStats = leaderboardUsers?.find(
// 		(leaderboardUser: any) => leaderboardUser?.phone === user?.phone
// 	);
// 	const [currentPage, setCurrentPage] = useState(1);
// 	const [hasMore, setHasMore] = useState(true);
// 	const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);

// 	useEffect(() => {
// 		document.body.style.overscrollBehavior = 'none';

// 		return () => {
// 			document.body.style.overscrollBehavior = 'initial';
// 		};
// 	}, []);

// 	useEffect(() => {
// 		const fetchLeaderboardUsers = async () => {
// 			try {
// 				const result = await getLeaderBoardUsers(
// 					currentPage,
// 					user?.uid || userData?.uid
// 				);
// 				const newUsers = result?.users || [];
// 				if (
// 					newUsers.length &&
// 					(!leaderboardUsers.length ||
// 						newUsers[0].userId !== leaderboardUsers[0].userId)
// 				) {
// 					setHasScrolledToEnd(false);
// 					const updatedLeaderboardUsers = [...leaderboardUsers, ...newUsers];

// 					dispatch(setLeaderboardUsers(updatedLeaderboardUsers));
// 				}

// 				if (newUsers.length < 50) {
// 					// Assuming page size is 50
// 					setHasMore(false); // No more data to load
// 				}
// 			} catch (error) {
// 				console.error('Error fetching leaderboard users:', error);
// 			}
// 		};

// 		fetchLeaderboardUsers();
// 	}, [currentPage, userData]);

// 	useEffect(() => {
// 		const handleScroll = () => {
// 			console.log(
// 				'scroll end conditions',
// 				window.innerHeight + document.documentElement.scrollTop ===
// 					document.documentElement.offsetHeight,
// 				window.innerHeight + document.documentElement.scrollTop,
// 				document.documentElement.offsetHeight
// 			);
// 			if (
// 				window.innerHeight + document.documentElement.scrollTop ===
// 					document.documentElement.offsetHeight &&
// 				hasMore
// 			) {
// 				if (!hasScrolledToEnd) {
// 					console.log('Add rows');
// 					setHasScrolledToEnd(true);
// 					setCurrentPage((prevPage) => prevPage + 1);
// 				}
// 			}
// 		};

// 		window.addEventListener('scroll', handleScroll);

// 		return () => {
// 			window.removeEventListener('scroll', handleScroll);
// 			dispatch(setLeaderboardUsers([]));
// 		};
// 	}, []);

// 	const onKarmaPointsClickHandler = () => {
// 		Mixpanel.track('click_karma_points_badge', {
// 			event_action: 'clicked',
// 			context_user_id: user?.uid,
// 			total_karma_points: user?.total_karma || 0,
// 			event_page: 'home_screen',
// 		});
// 		navigate('/karma', {
// 			state: { email: user?.email, total_karma: user?.total_karma },
// 			replace: false,
// 		});
// 	};

// 	return (
// 		<div className={'leaderboard-container'}>
// 			<Header
// 				onClick={onKarmaPointsClickHandler}
// 				logoUrl={seek_logo_url}
// 				karma_points={user?.total_karma}
// 			/>
// 			<div style={{ width: '90%', maxWidth: '400px', marginTop: '24px' }}>
// 				<BackButton onClick={navigate.bind(null, -1)} />
// 			</div>
// 			<div
// 				style={{
// 					display: 'flex',
// 					justifyContent: 'center',
// 					alignItems: 'center',
// 					flexDirection: 'column',
// 					marginTop: '24px',
// 					marginBottom: '16px',
// 					maxWidth: '375px',
// 					width: '90%',
// 				}}>
// 				<span className={'leaderboard-screen-title'}>
// 					{'Weekly Leaderboard'}
// 				</span>
// 				<span className={'leaderboard-screen-subtitle'}>
// 					{'Climb the charts and find your inner peace:'}
// 				</span>
// 				<span className={'leaderboard-screen-subtitle'}>
// 					{"This week's top meditators!"}
// 				</span>
// 			</div>
// 			<LeaderNudge
// 				currentUserLeaderboardStats={currentUserLeaderboardStats}
// 				onClick={() => {}}
// 				phone={user?.phone || userData?.phone}
// 				leaderboardUsers={leaderboardUsers}
// 			/>
// 		</div>
// 	);
// };

// export default Leaderboard;

import React, { useCallback, useEffect, useState } from 'react';
import seek_logo_url, {
	ReactComponent as SeekLogo,
} from '../../assets/seek-logo.svg';
import './Leaderboard.css';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import Header from '../../components-v2/header/Header';
import { Mixpanel } from '../../analytics/Mixpanel';
import BackButton from '../../components-v2/buttons/BackButton';
import LeaderNudge from './components/LeaderNudge';
import { useDispatch, useSelector } from 'react-redux';
import { getLeaderBoardUsers } from '../../services/Firebase';
import { getUserListWithSpecialUser } from '../../utils/utils';
import {
	setLeaderboardUsers,
	setTopFourLeaderboardUsers,
} from '../../store/features/LeaderboardSlice';
import InfiniteScroll from 'react-infinite-scroll-component';

import chevronIcon from '../../assets/new-icons/chevron-right-enabled.svg';

const Leaderboard = () => {
	const userData = useAuthenticatedUsers();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { state } = location;
	const user = state?.user;

	const leaderboardUsers = useSelector(
		(state: any) => state?.leaderboard?.data?.leaderboardUsers
	);
	const currentUserLeaderboardStats = leaderboardUsers?.find(
		(leaderboardUser: any) => leaderboardUser?.phone === user?.phone
	);

	const [totalPages, setTotalPages] = useState(100);
	const [currentPage, setCurrentPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const [upButtonVisible, setUpButtonVisible] = useState(false);

	const loadMore = () => {};

	useEffect(() => {
		const fetchLeaderboardUsers = async () => {
			try {
				const result = await getLeaderBoardUsers(
					currentPage,
					user?.uid || userData?.uid
				);
				const newUsers = result?.users || [];
				setTotalPages(result?.totalPages);

				if (
					newUsers.length &&
					(!leaderboardUsers.length ||
						newUsers[0].userId !== leaderboardUsers[0].userId) &&
					hasMore
				) {
					const updatedLeaderboardUsers = [...leaderboardUsers, ...newUsers];
					dispatch(setLeaderboardUsers(updatedLeaderboardUsers));
				}

				if (currentPage >= totalPages) {
					setHasMore(false);
				}
			} catch (error) {
				console.error('Error fetching leaderboard users:', error);
			}
		};
		fetchLeaderboardUsers();
	}, [currentPage]);

	useEffect(() => {
		if (hasMore) {
			setCurrentPage(leaderboardUsers.length / 50 + 1);
		}
	}, [leaderboardUsers]);

	useEffect(() => {
		window.scrollTo(0, 0);
		document.body.style.overscrollBehavior = 'none';

		const handleScroll = () => {
			window.innerHeight + document.documentElement.scrollTop > 1500
				? setUpButtonVisible(true)
				: setUpButtonVisible(false);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			document.body.style.overscrollBehavior = 'initial';
			window.removeEventListener('scroll', handleScroll);
			setLeaderboardUsers([]);
		};
	}, []);

	return (
		<div className={'leaderboard-container'}>
			<Header
				onClick={() => navigate('/karma')}
				logoUrl={seek_logo_url}
				karma_points={user?.total_karma}
			/>
			<div style={{ width: '90%', maxWidth: '400px', marginTop: '24px' }}>
				<BackButton onClick={() => navigate(-1)} />
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					marginTop: '24px',
					marginBottom: '16px',
					maxWidth: '375px',
					width: '90%',
				}}>
				<span className={'leaderboard-screen-title'}>
					{'Weekly Leaderboard'}
				</span>
				<span className={'leaderboard-screen-subtitle'}>
					{'Climb the charts and find your inner peace:'}
				</span>
				<span className={'leaderboard-screen-subtitle'}>
					{"This week's top meditators!"}
				</span>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					marginBottom: '16px',
					maxWidth: '375px',
					width: '90%',
				}}>
				<InfiniteScroll
					dataLength={leaderboardUsers.length}
					next={loadMore}
					hasMore={hasMore}
					loader={
						<p
							style={{
								textAlign: 'center',
								marginTop: '16px',
								fontFamily: 'Gordita-Regular',
							}}>
							Loading...
						</p>
					}
					endMessage={
						<p
							style={{
								textAlign: 'center',
								marginTop: '16px',
								fontFamily: 'Gordita-Regular',
							}}>
							No more users to display.
						</p>
					}
					scrollThreshold={0.9}>
					<LeaderNudge
						currentUserLeaderboardStats={currentUserLeaderboardStats}
						onClick={() => {}}
						phone={user?.phone || userData?.phone}
						leaderboardUsers={leaderboardUsers}
					/>
				</InfiniteScroll>
			</div>
			{upButtonVisible && (
				<div
					style={{
						width: '50px',
						height: '50px',
						borderRadius: '100px',
						backgroundColor: '#ffffff',
						position: 'fixed',
						zIndex: '10000',
						bottom: '24px',
						right: '24px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						boxShadow: '0px 0px 12px 2px #00000033',
					}}>
					<img
						onClick={() => {
							window.scrollTo(0, 0);
						}}
						style={{ width: '24px', transform: 'rotate(-90deg)' }}
						src={chevronIcon}
						alt=''
					/>
				</div>
			)}
		</div>
	);
};

export default Leaderboard;
