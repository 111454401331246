import React from 'react'
import './ViewAll.css'


function ViewAll({onClick}: any) {
    return (
        <div onClick={onClick} className={'view-all-container'}>
            <span className={'view-all-title'}>{'View All'}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M5.75 3.5L10.25 8L5.75 12.5" stroke="#1B1625" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    )
}

export default ViewAll
