import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import './CourseCheckoutPage.css';
import '../../../styles.css';
import moment from 'moment-timezone';
import { Mixpanel } from '../../../analytics/Mixpanel';
import karma_slider_badge from '../../../assets/slider_badge.svg';
import { ReactComponent as RiskFree } from '../../../assets/new-icons/course-risk-free.svg';
import NumberPickerSlider from './NumberPickerSlider';
import { ReactComponent as Coins } from '../../../assets/new-icons/coins.svg';
import { KARMA_BASED_USER_TYPE } from '../../../utils/utils';
import { checkUserEnrolledToCourse } from '../../../services/Firebase';
import { toast, ToastContainer } from 'react-toastify';
import { COLORS } from '../../../utils/colors';
import BackButton from '../../../components-v2/buttons/BackButton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useAuthenticatedUsers from '../../../hooks/useAuthenticatedUsers';
import RecordingsModal from './RecordingsModal';
import SecondaryButton from '../../../components-v2/buttons/SecondaryButton';
import PrimaryButton from '../../../components-v2/buttons/PrimaryButton';

interface CheckoutPageProps {
	payNowClickHandler: any;
	onBackArrowClick: () => void;
	courseItem: any;
	totalKarma: any;
	userId: any;
	imageUrl: string;
	recordingStatus: boolean;
	setRecordingStatus: Dispatch<SetStateAction<boolean>>;
}

const CourseCheckoutPage: React.FC<CheckoutPageProps> = ({
	payNowClickHandler,
	onBackArrowClick,
	courseItem,
	totalKarma,
	userId,
	imageUrl,
	recordingStatus,
	setRecordingStatus,
}) => {
	const userData = useAuthenticatedUsers(true);
	const [showRecordingsModal, setShowRecordingsModal] = useState(false);
	const [viewBenefits, setViewBenefits] = useState(false);
	const [recordingsAdded, setRecordingsAdded] = useState(recordingStatus);

	const formattedDate = moment(
		new Date(
			courseItem?.batch[0]?.start_date._seconds * 1000 +
				courseItem?.batch[0]?.start_date._nanoseconds / 1000000
		)
	).format('DD MMM YYYY');
	const endDate = moment(
		new Date(
			courseItem?.batch[0]?.end_date._seconds * 1000 +
				courseItem?.batch[0]?.end_date._nanoseconds / 1000000
		)
	).format('DD MMM YYYY');

	const [discountedAmountToPay, setDiscountedAmountToPay] = useState(
		courseItem?.batch[0]?.offers_valid
			? courseItem?.batch[0]?.course_offer_price
			: courseItem?.batch[0]?.price
	);

	const [amountToPay, setAmountToPay] = useState(
		courseItem?.batch[0]?.offers_valid
			? courseItem?.batch[0]?.course_offer_price
			: courseItem?.batch[0]?.price
	);

	const [appliedKarma, setAppliedKarma] = useState(0);
	const [loader, setLoader] = useState(false);

	const applicable_karma = Math.min(
		totalKarma,
		courseItem?.batch[0]?.price * 0.1
	);
	let userType = '';
	// console.log("applicable_karma", applicable_karma);

	if (totalKarma < 40) {
		userType = KARMA_BASED_USER_TYPE.user_with_kp_less_than_min_required;
	} else if (totalKarma > applicable_karma) {
		userType = KARMA_BASED_USER_TYPE.user_with_kp_greater_than_discount_percent;
	} else {
		userType = KARMA_BASED_USER_TYPE.user_with_kp_less_than_discount_percent;
	}

	const [isToggled, setIsToggled] = useState<boolean>(false);

	useEffect(() => {
		setTimeout(() => !recordingStatus && setShowRecordingsModal(true), 2000);
	}, []);

	useEffect(() => {
		setRecordingStatus(recordingsAdded);
		if (recordingsAdded) {
			setAmountToPay(
				parseInt(
					courseItem?.batch[0]?.offers_valid
						? courseItem?.batch[0]?.course_offer_price
						: courseItem?.batch[0]?.price
				) + parseInt(courseItem?.recording_offer_price)
			);
			setDiscountedAmountToPay(
				parseInt(
					courseItem?.batch[0]?.offers_valid
						? courseItem?.batch[0]?.course_offer_price
						: courseItem?.batch[0]?.price
				) + parseInt(courseItem?.recording_offer_price)
			);
		} else {
			setAmountToPay(
				parseInt(
					courseItem?.batch[0]?.offers_valid
						? courseItem?.batch[0]?.course_offer_price
						: courseItem?.batch[0]?.price
				)
			);
			setDiscountedAmountToPay(
				parseInt(
					courseItem?.batch[0]?.offers_valid
						? courseItem?.batch[0]?.course_offer_price
						: courseItem?.batch[0]?.price
				)
			);
		}
	}, [courseItem?.recording_offer_price, recordingsAdded, setRecordingStatus]);

	const onPayNowClick = async () => {
		setLoader(true);
		try {
			Mixpanel.track('click_pay_now_course_payment_page', {
				event_action: 'clicked',
				event_page: 'course_payment_page',
				price: amountToPay,
				total_amount_to_pay: discountedAmountToPay,
				selected_karma_points: appliedKarma,
				karma_points_discount: appliedKarma,
				user_type: userType,
				recordings_status: recordingsAdded,
				course_name: courseItem?.title,
				phone: userData?.phoneNumber || userData?.phone,
			});

			const result = await checkUserEnrolledToCourse(
				userId,
				courseItem?.courseId,
				courseItem?.batch?.[0]?.batchId,
				'COURSE'
			);

			console.log('courseItem checkout ', courseItem?.batch[0]?.batchId);

			if (result?.status === 200) {
				toast.error(`You are already enrolled in this course`, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 2000,
					hideProgressBar: false,
				});
				setLoader(false);
				return;
			}
		} catch (e) {}
		setTimeout(() => {
			setLoader(false);
		}, 3000);
		if (isToggled) {
			payNowClickHandler(discountedAmountToPay, appliedKarma, recordingsAdded);
		} else {
			payNowClickHandler(amountToPay, appliedKarma, recordingsAdded);
		}
	};

	const handleToggle = () => {
		try {
			Mixpanel.track('click_redeem_karma_points', {
				event_action: 'clicked',
				event_page: 'course_payment_page',
				price: amountToPay,
				total_amount_to_pay: discountedAmountToPay,
				selected_karma_points: appliedKarma,
				karma_points_discount: appliedKarma,
				user_type: userType,
				discount: '10%',
			});
		} catch (e) {
			console.log('mixpanel karma points');
		}
		setIsToggled((prevState) => !prevState);
	};

	const onSliderChangeHandler = (karmaPoints: any) => {
		setIsToggled(false);
		if (parseInt(karmaPoints) > 0) {
			const discountedPrice = amountToPay - parseInt(karmaPoints);
			setDiscountedAmountToPay(discountedPrice);
			setAppliedKarma(parseInt(karmaPoints));
		}
	};

	const onRecordingsAddHandler = () => {
		setRecordingsAdded(true);
	};

	const onRecordingsRemoveHandler = () => {
		setRecordingsAdded(false);
		Mixpanel.track('click_remove_recording', {
			event_action: 'clicked',
			event_page: 'course_payment_page',
			course_name: courseItem?.title,
			phone: userData?.phone || userData?.number,
		});
	};

	useEffect(() => {
		if (courseItem) {
			Mixpanel.track('view_course_payment_details_page', {
				event_action: 'viewed',
				course_name: courseItem?.title,
				starting_date: formattedDate,
				ending_date: endDate,
				event_page: 'course_payment_page',
				max_karma_points: totalKarma,
				total_amount_to_pay: discountedAmountToPay,
			});

			Mixpanel.track('view_course_recording_bottom_sheet', {
				event_action: 'viewed',
				event_page: 'course_payment_bottom_sheet',
				course_name: courseItem?.title,
				phone: userData?.phone || userData?.number,
			});
		}
	}, [courseItem]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const onHideRecordingsModal = (action: string) => {
		setShowRecordingsModal(false);
		if (action === 'add') {
			Mixpanel.track('click_add_recordings_bottom_sheet', {
				event_action: 'clicked',
				event_page: 'course_payment_bottom_sheet',
				course_name: courseItem?.title,
				phone: userData?.phone || userData?.number,
			});
			setRecordingsAdded(true);
			onRecordingsAddHandler();
		} else if (action === 'skip') {
			Mixpanel.track('click_skip_for_now_bottom_sheet', {
				event_action: 'clicked',
				event_page: 'course_payment_bottom_sheet',
				course_name: courseItem?.title,
				phone: userData?.phone || userData?.number,
			});
		}
	};

	return (
		<>
			<Container className={'course-checkout-container'}>
				<div style={{ width: '100%', maxWidth: '400px' }}>
					<BackButton onClick={onBackArrowClick} />
				</div>

				{loader && (
					<Spinner
						style={{
							marginTop: '64px',
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: COLORS.PRIMARY,
						}}
					/>
				)}

				<span className={'course-checkout-details'}>Course Details</span>
				<div className={'checkout-plan-billing-container'}>
					<div className={'checkout-plan-billing-row-container'}>
						{
							<LazyLoadImage
								src={imageUrl}
								alt='User'
								style={{ width: '85px', height: '64px', borderRadius: '8px' }}
							/>
						}
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginLeft: '16px',
							}}>
							<span
								className={
									'course-checkout-plan-name'
								}>{`${courseItem?.title}`}</span>
							<span
								style={{
									color: '#A4A4A4',
									fontFamily: 'Gordita-Regular',
									fontSize: '14px',
									fontStyle: 'normal',
									fontWeight: 400,
									alignSelf: 'start',
									marginTop: '6px',
									lineHeight: 'normal',
								}}>{`${formattedDate}`}</span>
						</div>
					</div>
				</div>

				{courseItem?.recording_actual_price && <div className='course-checkout-recordings'>
					<div className='course-checkout-recordings-text'>
						<p className='course-checkout-recordings-text-title'>
							Recordings for 6 weeks
						</p>
						<p className='course-checkout-recordings-text-price'>
							<span className='course-checkout-recordings-text-price-offer'>
								INR {courseItem?.recording_offer_price}
							</span>
							<span className='course-checkout-recordings-text-price-actual'>
								INR {courseItem?.recording_actual_price}
							</span>
						</p>
						<p
							onClick={() => {
								Mixpanel.track('click_view_benefits', {
									event_action: 'clicked',
									event_page: 'course_payment_page',
									course_name: courseItem?.title,
									phone: userData?.phone || userData?.number,
								});
								setShowRecordingsModal(true);
								setViewBenefits(true);
							}}
							className='course-checkout-recordings-text-benefits'>
							View Benefits
						</p>
					</div>
					<div className='course-checkout-recordings-cta'>
						{recordingsAdded ? (
							<SecondaryButton
								onClick={onRecordingsRemoveHandler}
								style={{height: '32px', borderRadius: '8px'}}>
								Remove
							</SecondaryButton>
						) : (
							<PrimaryButton
								onClick={() => {
									Mixpanel.track('click_add_recording', {
										event_action: 'clicked',
										event_page: 'course_payment_page',
										course_name: courseItem?.title,
										phone: userData?.phone || userData?.number,
									});
									onRecordingsAddHandler();
								}}
								style={{height: '32px', borderRadius: '8px'}}>
								<svg
									style={{alignSelf: 'center', marginBottom: '2px'}}
									xmlns='http://www.w3.org/2000/svg'
									width='16'
									height='16'
									viewBox='0 0 20 21'
									fill='none'>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M5.63232 10.5576L14.3661 10.5439L5.63232 10.5576ZM9.99229 6.18384L10.006 14.9176L9.99229 6.18384Z'
										fill='white'
									/>
									<path
										d='M5.63232 10.5576L14.3661 10.5439M9.99229 6.18384L10.006 14.9176'
										stroke='white'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
								Add
							</PrimaryButton>
						)}
					</div>
				</div>}
				<span className={'course-checkout-details'}>Payment Details</span>
				{totalKarma >= 40 ? (
					<div className={'apply-karma-points-container'}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignSelf: 'center',
								alignItems: 'center',
							}}>
							<Coins width={24} height={24} />
							<span
								style={{
									color: '#3A3A3A',
									fontFamily: 'Gordita-Medium',
									fontSize: '14px',
									fontStyle: 'normal',
									fontWeight: 500,
									marginLeft: '5px',
									lineHeight: '20px',
								}}>
								Pay using Karma Points
							</span>
						</div>
						<p
							style={{
								color: '#1A1A1A',
								fontFamily: 'Gordita-Regular',
								fontSize: '10px',
								width: '310px',
								fontStyle: 'normal',
								fontWeight: 400,
								marginLeft: '5px',
								lineHeight: '14px',
								marginTop: '2px',
							}}>
							{totalKarma > applicable_karma
								? 'Maximum Karma Point redemption is 10% of the total amount.(1 Karma Point = 1 Rs)'
								: 'Redeem your Karma Points for a discount equivalent to your total points. (1 Karma Point = 1 Rs)'}
						</p>
						<NumberPickerSlider
							min={0}
							max={applicable_karma}
							backgroundImage={karma_slider_badge}
							onSliderChangeHandler={onSliderChangeHandler}
						/>

						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								alignSelf: 'center',
								marginTop: '4px',
							}}>
							<p
								style={{
									color: '#2A2A2A',
									fontFamily: 'Gordita-Medium',
									fontSize: '12px',
									fontStyle: 'normal',
									fontWeight: 500,
									lineHeight: 'normal',
								}}>
								0{' '}
								<span
									style={{
										color: '#2A2A2A',
										fontFamily: 'Gordita-Regular',
										fontSize: '10px',
										fontStyle: 'normal',
										textAlign: 'center',
										fontWeight: 400,
										alignSelf: 'center',
										lineHeight: 'normal',
									}}>
									points
								</span>
							</p>
							<p
								style={{
									color: '#2A2A2A',
									fontFamily: 'Gordita-Medium',
									fontSize: '12px',
									fontStyle: 'normal',
									fontWeight: 500,
									lineHeight: 'normal',
								}}>
								{applicable_karma}{' '}
								<span
									style={{
										color: '#2A2A2A',
										fontFamily: 'Gordita-Regular',
										fontSize: '10px',
										fontStyle: 'normal',
										fontWeight: 400,
										alignSelf: 'center',
										lineHeight: 'normal',
									}}>
									points
								</span>
							</p>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								alignSelf: 'center',
							}}>
							<span
								style={{
									color: '#2A2A2A',
									fontFamily: 'Gordita-Medium',
									fontSize: '12px',
									fontStyle: 'normal',
									fontWeight: 500,
									lineHeight: '16px',
								}}>
								Redeem Points{' '}
							</span>
							<label className='switch'>
								<input
									type='checkbox'
									checked={isToggled}
									onChange={handleToggle}
								/>
								<span className='slider round'></span>
							</label>
						</div>
					</div>
				) : (
					<div className={'apply-karma-points-container'}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignSelf: 'center',
								alignItems: 'center',
							}}>
							<Coins width={24} height={24} />
							<span
								style={{
									color: '#3A3A3A',
									fontFamily: 'Gordita-Medium',
									fontSize: '14px',
									fontStyle: 'normal',
									fontWeight: 500,
									marginLeft: '5px',
									lineHeight: '20px',
								}}>
								Pay using Karma Points
							</span>
						</div>
						<p
							style={{
								color: '#1A1A1A',
								fontFamily: 'Gordita-Regular',
								fontSize: '10px',
								width: '310px',
								fontStyle: 'normal',
								fontWeight: 400,
								marginLeft: '5px',
								lineHeight: '14px',
								marginTop: '2px',
							}}>
							Looks like you do not have enough karma points to redeem for
							additional discount.
						</p>
					</div>
				)}

				<svg
					style={{ marginTop: totalKarma >= 40 ? '24px' : '10px' }}
					xmlns='http://www.w3.org/2000/svg'
					width='345'
					height='2'
					viewBox='0 0 345 2'
					fill='none'>
					<path
						d='M1 1H344'
						stroke='#E4E4E4'
						stroke-width='0.64'
						stroke-linecap='round'
						stroke-dasharray='4 4'
					/>
				</svg>

				<div className={'course-checkout-plan-price-container'}>
					<div
						className={'course-checkout-plan-details-container'}
						style={{ marginTop: '0px' }}>
						<span className={'course-checkout-plan-start-date'}>
							{'Course Price'}
						</span>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<span className={'course-checkout-plan-start-date-value'}>{`INR ${
								courseItem?.batch[0]?.offers_valid
									? courseItem?.batch[0]?.course_offer_price
									: courseItem?.batch[0]?.price
							}`}</span>

							{courseItem?.batch[0]?.offers_valid && (
								<span
									className='plan-fixed-bottom-price'
									style={{
										color: '#A4A4A4',
										fontSize: '10px',
										marginTop: '-6px',
										textAlign: 'right',
										textDecoration: 'line-through',
									}}>{`INR ${courseItem?.batch[0]?.price}`}</span>
							)}
						</div>
					</div>
					{isToggled && (
						<div className={'course-checkout-plan-details-container'}>
							<span className={'course-checkout-plan-start-date'}>
								{'Karma Points Discount'}
							</span>
							<span
								className={
									'course-checkout-plan-price-discount'
								}>{`- INR ${appliedKarma}`}</span>
						</div>
					)}
					{recordingsAdded && (
						<div
							className={'course-checkout-plan-details-container'}
							style={{ marginTop: '12px' }}>
							<span className={'course-checkout-plan-start-date'}>
								Course Recordings (6 weeks)
							</span>
							<span
								className={
									'course-checkout-plan-start-date-value'
								}>{`INR ${courseItem?.recording_offer_price}`}</span>
						</div>
					)}
				</div>

				<RiskFree style={{ marginTop: '24px', marginBottom: '92px' }} />
				<div className='plan-fixed-bottom-container'>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span className='plan-fixed-bottom-price'>{`₹ ${
							isToggled ? Number(discountedAmountToPay) : Number(amountToPay)
						}`}</span>
					</div>
					<button className='plan-fixed-bottom-button' onClick={onPayNowClick}>
						{'Pay Now'}
					</button>
				</div>
				<ToastContainer />
			</Container>
			{courseItem?.recording_offer_price && (
				<RecordingsModal
					viewBenefits={viewBenefits}
					recordingsAdded={recordingsAdded}
					duration={courseItem?.duration}
					offerPrice={courseItem?.recording_offer_price}
					actualPrice={courseItem?.recording_actual_price}
					showRecordingsModal={showRecordingsModal}
					onHideRecordingsModal={onHideRecordingsModal}></RecordingsModal>
			)}
		</>
	);
};

export default CourseCheckoutPage;
