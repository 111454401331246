import React, { CSSProperties } from 'react';
import './BottomContainer.css';

interface BottomContainerProps {
	text?: string;
	onButtonClick: () => void;
	label: string;
	buttonStyle?: CSSProperties;
}

const BottomContainer: React.FC<BottomContainerProps> = ({
	text,
	onButtonClick,
	label,
	buttonStyle,
}) => {
	return (
		<div className='fixed-bottom-container'>
			<button
				className='fixed-bottom-button'
				onClick={onButtonClick}
				style={buttonStyle}>
				{label}
			</button>
			{text && (
				<span className='fixed-bottom-text'>
					Become a part of{' '}
					<span style={{ fontFamily: 'Gordita-Medium', fontWeight: '500' }}>
						10K+
					</span>{' '}
					Seekers.
				</span>
			)}
		</div>
	);
};

export default BottomContainer;
