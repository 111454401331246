import { createBrowserRouter, Navigate } from 'react-router-dom';
import App from '../App';
import React from 'react';
import Login from '../screens/login/Login';
import SignUp from '../screens/signup-form/SignUp';
import LandingPageOne from '../screens/LandingPageOne';
import KarmaPoints from '../screens/karma-points/KarmaPoints';
import Leaderboard from '../screens/leaderboard/Leaderboard';
import AppLayout from '../screens/AppLayout';
import Referral from '../screens/referral/Referral';
import ReferralHistory from '../screens/referral/ReferralHistory';
import Plan from '../screens/plans/Plan';
import Founders from '../screens/founders/Founders';
import SampleClasses from '../screens/sample-classes/SampleClasses';
import AccountCreated from '../screens/home/components/AccountCreated';
import OnBoarding from '../screens/signup-form/OnBoarding';
import UpcomingSessions from '../screens/live-class/UpcomingSessions';
import HowToJoinLiveClass from '../screens/HowToJoinLiveClass';
import ChangeTimeSlotsPage from '../screens/signup-form/ChangeTimeSlots';
import ManageReminders from '../screens/live-class/ManageReminders';
import VimeoVideoPlayer from '../screens/plans/components/VimeoVideoPlayer';
import UpcomingCourseDescriptionPage from '../screens/course/UpcomingCourseDescriptionPage';
import SeekCommunity from '../screens/home/components/SeekCommunity';
import UpcomingCourseClass from '../screens/course/UpcommingCourseClass';
import CourseHomework from '../screens/course/CourseHomework';
import GoldenThemeAppLayout from '../screens/GoldenThemeAppLayout';
import HomeContainer from '../screens/home-v2/HomeContainer';
import FavouriteClasses from '../screens/sample-classes/FavouriteClasses';
import PostOBScreen from '../screens/home/components/PostOBScreen';
import GreyAppLayout from '../screens/GreyAppLayout';
import CompleteProfile from '../screens/home/components/CompleteProfile';
import PocLandingPage from '../screens/poc-counselling/PocLandingPage';
import PlanPaymentSuccess from '../screens/plans/PaymentSuccessScreen';
import ManageNotification from '../screens/live-class/ManageNotification';
import PrivacyPolicy from '../screens/profile/PrivacyPolicy';
import TermsConditions from '../screens/profile/TermsConditions';

const userString = window.localStorage.getItem('user');

const user = JSON.parse(userString);
// console.log("isAuthenticated user",user);

const ProtectedRoute = ({ element }) => {
	if (user) {
		return element;
	} else {
		return <Navigate to='/login' />;
	}
};

export const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<AppLayout>
				<App />
			</AppLayout>
		),
	},
	{
		path: '/landing-page-one',
		element: (
			<AppLayout>
				<LandingPageOne />
			</AppLayout>
		),
	},
	{
		path: '/login',
		element: (
			<GreyAppLayout>
				<Login />
			</GreyAppLayout>
		),
	},
	{
		path: '/onboarding',
		element: (
			<GoldenThemeAppLayout>
				<OnBoarding />
			</GoldenThemeAppLayout>
		),
	},
	{
		path: '/sign-up-successful',
		element: (
			<GoldenThemeAppLayout>
				<AccountCreated />
			</GoldenThemeAppLayout>
		),
	},
	{
		path: '/seek-counselling',
		element: (
			<AppLayout>
				<PocLandingPage />
			</AppLayout>
		),
	},
	{
		path: '/get-started',
		element: (
			<GoldenThemeAppLayout>
				<PostOBScreen />
			</GoldenThemeAppLayout>
		),
	},

	{
		path: '/complete-your-profile',
		element: (
			<GreyAppLayout>
				<CompleteProfile />
			</GreyAppLayout>
		),
	},


	{
		path: '/sign-up',
		element: (
			<GreyAppLayout>
				<SignUp />
			</GreyAppLayout>
		),
	},
	{
		path: '/home',
		element: (
			<AppLayout>
				<HomeContainer />
			</AppLayout>
		),
	},
	{
		path: '/live-classes',
		element: (
			<AppLayout>
				<HomeContainer />
			</AppLayout>
		),
	},
	{
		path: '/profile',
		element: (
			<AppLayout>
				<HomeContainer />
			</AppLayout>
		),
	},
	{
		path: '/courses',
		element: (
			<AppLayout>
				<HomeContainer />
			</AppLayout>
		),
	},
	{
		path: '/karma',
		element: (
			<AppLayout>
				<KarmaPoints key={'KarmaPoints'} />
			</AppLayout>
		),
	},
	{
		path: '/leaderboard',
		element: (
			<AppLayout>
				<Leaderboard key={'Leaderboard'} />
			</AppLayout>
		),
	},
	{
		path: '/referral',
		element: (
			<AppLayout>
				<Referral key={'Referral'} />
			</AppLayout>
		),
	},
	{
		path: '/referralHistory',
		element: (
			<AppLayout>
				<ReferralHistory key={'ReferralHistory'} />
			</AppLayout>
		),
	},
	{
		path: '/plan',
		element: (
			<AppLayout>
				<Plan key={'plan'} />
			</AppLayout>
		),
	},
	{
		path: '/seek-founders',
		element: (
			<AppLayout>
				<Founders key={'founders'} />
			</AppLayout>
		),
	},
	{
		path: '/sample-classes',
		element: (
			<AppLayout>
				<SampleClasses key={'founders'} />
			</AppLayout>
		),
	},
	{
		path: '/upcoming-sessions',
		element: (
			<AppLayout>
				<UpcomingSessions key={'UpcomingSessions'} />
			</AppLayout>
		),
	},
	{
		path: '/joining-instruction',
		element: (
			<AppLayout>
				<HowToJoinLiveClass key={'HowToJoinLiveClass'} />
			</AppLayout>
		),
	},
	{
		path: '/change-slots',
		element: (
			<AppLayout>
				<ChangeTimeSlotsPage key={'ChangeTimeSlotsPage'} />
			</AppLayout>
		),
	},
	{
		path: '/manage-reminders',
		element: (
			<AppLayout>
				<ManageReminders key={'ManageReminders'} />
			</AppLayout>
		),
	},
	{
		path: '/manage-notification',
		element: (
			<AppLayout>
				<ManageNotification key={'ManageReminders'} />
			</AppLayout>
		),
	},

	{
		path: '/live-streaming',
		element: (
			<AppLayout>
				<VimeoVideoPlayer key={'VimeoVideoPlayer'} />
			</AppLayout>
		),
	},
	{
		path: '/upcoming-course/:courseId',
		element: (
			<AppLayout>
				<UpcomingCourseDescriptionPage key={'UpcomingCourseDescriptionPage'} />
			</AppLayout>
		),
	},
	{
		path: '/privacy-policy',
		element: (
			<AppLayout>
				<PrivacyPolicy key={'Policy'} />
			</AppLayout>
		),
	},
	{
		path: '/terms-conditions',
		element: (
			<AppLayout>
				<TermsConditions key={'Terms&Conditions'} />
			</AppLayout>
		),
	},
	{
		path: '/seek-community',
		element: (
			<AppLayout>
				<SeekCommunity key={'SeekCommunity'} />
			</AppLayout>
		),
	},
	// {
	// 	path: '/Kundalini-for-beginners/live-session',
	// 	element: (
	// 		<AppLayout>
	// 			<UpcomingCourseClass key={'UpcomingCourseClass'} />
	// 		</AppLayout>
	// 	),
	// },

	{
		path: '/:courseName/live-session',
		element: (
			<AppLayout>
				<UpcomingCourseClass key={'UpcomingCourseClass'} />
			</AppLayout>
		),
	},

	// {
	// 	path: '/Kundalini-for-beginners',
	// 	element: (
	// 		<AppLayout>
	// 			<CourseHomework key={'CourseHomework'} />
	// 		</AppLayout>
	// 	),
	// },

	{
		path: '/:courseName',
		element: (
			<AppLayout>
				<CourseHomework key={'CourseHomework'} />
			</AppLayout>
		),
	},

	{
		path: '/plan/payment-successful',
		element: (
			<AppLayout>
				<PlanPaymentSuccess key={'PlanPaymentSuccess'} />
			</AppLayout>
		),
	},
	{
		path: '/favourite-classes',
		element: (
			<AppLayout>
				<FavouriteClasses key={'FavouriteClasses'} />
			</AppLayout>
		),
	},
]);
