import React, { useState, useEffect } from 'react';
import './TextAnimation.css'; // Import CSS file for styles

const TextAnimation = ({startAnimation,title,index,onAnimationComplete,style}:any) => {
    // const text='India\'s first ever wellness platform, where we provide you what suits you.'
    const [readIndexTitle, setReadIndexTitle] = useState(0);

    useEffect(() => {
        if(startAnimation){
            const interval = setInterval(() => {
                setReadIndexTitle(prevIndex => prevIndex + 1);
            }, 50); // Adjust the speed here (milliseconds per character)

            // Clear interval when all characters are animated
            return () =>  clearInterval(interval);
        }
    }, [startAnimation]); // Include readIndex and text.length in the dependency array




    useEffect(()=>{
        if (readIndexTitle === title?.length) {
            onAnimationComplete(index);
        }
    },[readIndexTitle])

    return (
        <div style={{marginTop:index === 2?'8px':'0px'}} className="text-container">
            <div className="text">
                {title?.split('').map((char:any, index:number) => (
                    <span key={index}  className={index <= readIndexTitle ? 'animation-read' : 'animation-unread'} style={{...style}}>{char}</span>
                ))}
            </div>
        </div>
    );
};

export default TextAnimation;
