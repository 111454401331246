import React, {useState} from 'react';

interface NumberPickerProps {
    min: number;
    max: number;
    backgroundImage: string;
    onSliderChangeHandler: any;
    opacity?: number,
    disable?:boolean,
}

const NumberPickerSlider: React.FC<NumberPickerProps> = ({
                                                             opacity,
                                                             min,
                                                             max,
                                                             disable=false,
                                                             backgroundImage,
                                                             onSliderChangeHandler
                                                         }) => {


    const [value, setValue] = useState(min);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value, 10);
        setValue(newValue);
        onSliderChangeHandler(newValue);
    };


    const backgroundStyle: React.CSSProperties = {
        backgroundImage: `url(${backgroundImage})`,
        width: '32px',
        height: '32px',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        textAlign: 'center',
        paddingBottom: '12px',
        marginBottom: '-8px',
        paddingRight: '6px',
        color: '#ffffff',
        fontFamily: 'Gordita-medium',
        fontWeight: '500',
        fontSize: '12px',
        position: 'relative', // Position set to relative
        left: `${((value - min + ((max||10) / 25)) / ((max||10) - min + ((max||10) / 15))) * 100}%`, // left adjusted dynamically
        transform: 'translateX(-50%)', // Center the div horizontally
    };

    return (
        <div style={{position: 'relative', width: '100%', marginTop: '8px', opacity: opacity ? opacity : 1}}>
            <div style={backgroundStyle}>{value}</div>
            <input
                disabled={disable}
                type="range"
                min={min}
                max={max}
                value={value}
                onChange={handleChange}
                style={{
                    width: '100%',
                    appearance: 'none',
                    height: '6px',
                    borderRadius: '5px',
                    background: disable?'var(--Light-Grey-2, #E4E4E4)':'linear-gradient(to right, #FAA53D 0%, #FAA53D ' + ((value - min) / (max - min)) * 100 + '%, #fae0c2 ' + ((value - min) / (max - min)) * 100 + '%, #fae0c2 100%)',
                }}
            />
        </div>
    );
};

export default NumberPickerSlider;
