import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import {ReactComponent as BackArrow} from "../../../assets/ic_back.svg";

interface VimeoVideoPlayerProps {
    videoId: string;
    style?:any;
    onBackClickHandler:any;
}

const VimeoVideoPlayer: React.FC<VimeoVideoPlayerProps> = ({ videoId,style,onBackClickHandler }) => {
    const [loader, setLoader] = useState(true);
    // console.log("VimeoVideoPlayer", videoId);

    useEffect(() => {
        if (videoId) {
            setLoader(false);
        }
    }, [videoId]);

    if (loader) {
        return <Loader />;
    }

    const divStyle = {
        width: '100%',
        height: '100%',
    };

    return (
        <div style={divStyle}>
            <BackArrow onClick={onBackClickHandler} width={36} height={36}
                       style={{position: 'absolute', top: '24', left: '24', zIndex: 5}}/>
            <iframe
                title="Vimeo Video"
                // src={`https://vimeo.com/event/${videoId}/embed/interaction?playsinline=0&autoplay=0&portrait=0&fullscreen=1`}
                // src="https://player.vimeo.com/video/910450245?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                src={`https://vimeo.com/event/${videoId}/embed/a104ab6df4`}
                frameBorder="0"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            ></iframe>
        </div>
    );
};

export default VimeoVideoPlayer;
