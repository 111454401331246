import React from 'react';
import './FullScreenPopup.css'; // Assume you have a CSS file for styling

interface FullScreenPopupProps {
    title: string;
    subtitle: string;
    body: string;
    phone: string;
    email: string;
    onClose: () => void;
}

const FullScreenPopup: React.FC<FullScreenPopupProps> = ({ title, subtitle, body, phone, email, onClose }) => {
    return (
        <div className="full-screen-popup">
            <div className="popup-content">
                <div className="popup-header">
                    <h2 style={{
                        marginTop:'12px',
                        color: '#1A1A1A',
                        fontFamily: 'Poppins',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                    }}>{title}</h2>
                    <p style={{
                        marginTop:'12px',
                        color: '#1A1A1A',
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 300,
                    }}>{subtitle}</p>
                </div>

                <div className="popup-body">
                    <div className="scrollable-content">
                        <p style={{
                            marginTop:'12px',
                            color: '#757575',
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontStyle: 'normal',
                        }}>{body}</p>
                        {phone && <p style={{
                            marginTop:'12px',
                            color: '#757575',
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontStyle: 'normal',
                        }}>Phone: {phone}</p>}
                        {email && <p style={{
                            marginTop:'12px',
                            color: '#757575',
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontStyle: 'normal',
                        }}>Email: {email}</p>}
                    </div>
                </div>

                <div className="popup-footer">
                    <button className={'popup-button'} onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default FullScreenPopup;
