import React, {useEffect} from 'react'
import {ReactComponent as BackArrow} from "../../../assets/arrow-back.svg";
import {Container} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import './SeekCommunity.css'
import {ExploreCommunityPoints} from "../../../utils/utils";
import {ReactComponent as BestOfThisClassIcon} from "../../../assets/best_of_this_class.svg";
import fb_gif from "../../../assets/animations/FB.gif"
import insta_gif from "../../../assets/animations/Instagram.gif"
import youtube_gif from "../../../assets/animations/YouTube.gif"
import whatsapp_gif from "../../../assets/animations/WhatsApp.gif"
import insta_community_banner from "../../../assets/webp-images/insta_community_banner.webp";
import whatsApp_community_banner from "../../../assets/webp-images/whatsApp_community_banner.webp";
import facebook_community_banner from "../../../assets/webp-images/fb_community_banner.webp";
import youtube_community_banner from "../../../assets/webp-images/youtube_community_banner.webp";
import {Mixpanel} from "../../../analytics/Mixpanel";


interface Props {
    name: string;
    index: number
}


const BestOutOfItIconWithName: React.FC<Props> = ({name, index}) => {
    return (
        <div style={{
            width: '345px',
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '12px',
            alignSelf: 'center',
            marginTop: index === 0 ? '16px' : '8px',
            // background: '#FFF'
        }}>
            <BestOfThisClassIcon width={24} height={24} style={{marginRight: '8px', alignSelf: 'start'}}/>
            <span style={{
                color: 'var(--grey-900, #1A202C)',
                fontFamily: 'Poppins',
                width: '311px',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px'
            }}>{name}</span>
        </div>
    );
};


const SeekCommunity = () => {
    const navigate = useNavigate();

    const onCardClick = (link: string) => {
        console.log("onCardClick",link);
        window.open(link, '_blank');
        if(link === 'https://bit.ly/49bJOSZ'){
            Mixpanel.track('join_whatsapp_community_banner', {
                event_action: 'clicked',
                event_page: 'join_our_community_page',
            });
        }else if(link === 'https://bit.ly/49vCxgK'){
            Mixpanel.track('join_youtube_community_banner', {
                event_action: 'clicked',
                event_page: 'join_our_community_page',
            });
        }else if(link === 'https://bit.ly/49nmAJL'){
            Mixpanel.track('join_instagram_community_banner', {
                event_action: 'clicked',
                event_page: 'join_our_community_page',
            });
        }else if(link === 'https://bit.ly/3xoSHvk'){
            Mixpanel.track('join_facebook_community_banner', {
                event_action: 'clicked',
                event_page: 'join_our_community_page',
            });
        }
    }


    useEffect(()=>{
        Mixpanel.track('view_join_our_community_page', {
            event_action: 'viewed',
            event_page: 'join_our_community_page',
        });
    },[])


    return (
        <Container className={'community-container'}>
            <div className={'horizontal-container'} style={{justifyContent: 'space-between', width: '345px'}}>
                {/*<BackArrow onClick={navigate.bind(null, -1)} width={24} height={24}/>*/}
                <div/>
                <span className={'community-toolbar-title'}>{'Join the Seek Community'}</span>
                <div/>
            </div>
            <span className={'community-toolbar-subtitle'}>🌟Don’t miss out</span>
            {ExploreCommunityPoints?.map((value: any, index: number) => (
                <BestOutOfItIconWithName key={index} index={index} name={value}/>
            ))}

            <img src={whatsapp_gif} style={{width: '272px', height: 'auto', marginTop: '24px'}} alt="Your GIF"/>

            <img
                src={whatsApp_community_banner}
                alt="Join WhatsApp Community Banner"
                onClick={onCardClick.bind(null, 'https://bit.ly/49bJOSZ')}
                style={{
                    position: 'relative',
                    alignSelf: 'center',
                    marginTop: '16px',
                    cursor: 'pointer',
                    width: '343px',
                    height: '56px'
                }}
            />

            <img src={youtube_gif} style={{width: '272px', height: 'auto', marginTop: '24px'}} alt="Your GIF"/>

            <img
                src={youtube_community_banner}
                alt="Join WhatsApp Community Banner"
                onClick={onCardClick.bind(null, 'https://bit.ly/49vCxgK')}
                style={{
                    position: 'relative',
                    alignSelf: 'center',
                    marginTop: '16px',
                    cursor: 'pointer',
                    width: '343px',
                    height: '56px'
                }}
            />

            <img src={insta_gif} style={{width: '272px', height: 'auto', marginTop: '24px'}} alt="Your GIF"/>

            <img
                src={insta_community_banner}
                alt="Join WhatsApp Community Banner"
                onClick={onCardClick.bind(null, 'https://bit.ly/49nmAJL')}
                style={{
                    position: 'relative',
                    alignSelf: 'center',
                    marginTop: '16px',
                    cursor: 'pointer',
                    width: '343px',
                    height: '56px'
                }}
            />

            <img src={fb_gif} style={{width: '272px', height: 'auto', marginTop: '24px'}} alt="Your GIF" />

            <img
                src={facebook_community_banner}
                alt="Join WhatsApp Community Banner"
                onClick={onCardClick.bind(null, 'https://bit.ly/3xoSHvk')}
                style={{
                    position: 'relative',
                    alignSelf: 'center',
                    marginTop: '16px',
                    marginBottom: '72px',
                    cursor: 'pointer',
                    width: '343px',
                    height: '56px'
                }}
            />


        </Container>
    )
}

export default SeekCommunity;
