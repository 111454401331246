import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty, STREAMING_PLAN_RECURRENCE_TYPE } from '../utils/utils';
import { enrollUserToLiveStreamingClass } from '../services/Firebase';
import { Mixpanel } from '../analytics/Mixpanel';
import { COLORS } from '../utils/colors';
import { setReloadPlan } from '../store/features/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

function loadScript(src: any) {
	return new Promise((resolve) => {
		const script = document.createElement('script');
		script.src = src;
		script.onload = () => {
			resolve(true);
		};
		script.onerror = () => {
			resolve(false);
		};
		document.body.appendChild(script);
	});
}

interface RazorpayHookProps {
	setPaymentFailedPopup: (show: boolean) => void;
	setPaymentSuccessPopup: (show: boolean) => void;
	setShowLoginPopup: (show: boolean) => void;
	user: {
		uid?: string;
		name?: string;
		email?: string;
		phoneNumber?: string;
		phone?: string;
	} | null;
	userData: {
		uid?: string;
		name?: string;
		email?: string;
		phoneNumber?: string;
		phone?: string;
	} | null;
	selectedPlanVariant: any; // Adjust the type according to your selected plan variant
	prevScreenName?: string; // Adjust as per your project setup
}

// TODO update mixpanel event's event_page name for different different pages

const useRazorpay = ({
	setPaymentFailedPopup,
	setPaymentSuccessPopup,
	user,
	userData,
	selectedPlanVariant,
	prevScreenName,
	setShowLoginPopup,
}: RazorpayHookProps) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const userPlan = useSelector((state: any) => state.plan?.userPlan);

	console.log(
		'userPlan razorpay',
		userPlan?.activeStreamingPlan?.active_plan_name
	);
	console.log(
		'userPlan razorpay',
		userPlan?.activeStreamingPlan?.currently_active_variant
	);

	const displayRazorpay = async (price: number, name: string) => {
		setPaymentFailedPopup(false);
		const currentDate = moment().format('DD MMM YYYY');
		let endDate = moment(currentDate);
		if (selectedPlanVariant?.type === STREAMING_PLAN_RECURRENCE_TYPE.MONTHLY) {
			endDate.add(1, 'months');
		} else if (
			selectedPlanVariant?.type === STREAMING_PLAN_RECURRENCE_TYPE.QUARTERLY
		) {
			endDate.add(3, 'months');
		} else if (
			selectedPlanVariant?.type === STREAMING_PLAN_RECURRENCE_TYPE.HALF_YEARLY
		) {
			endDate.add(6, 'months');
		} else if (
			selectedPlanVariant?.type === STREAMING_PLAN_RECURRENCE_TYPE.YEARLY
		) {
			endDate.add(12, 'months');
		}

		if (!userData && isEmpty(user)) {
			setShowLoginPopup(true);
			Mixpanel.track('view_login_popup_checkout_page', {
				event_action: 'viewed',
				plan_amount: price,
				plan_name: name,
				startDate: currentDate,
				endDate: endDate?.format('DD MMM YYYY'),
				event_page: prevScreenName
					? prevScreenName
					: 'payment_screen/payment_one',
			});
			return;
		}
		if (!selectedPlanVariant) {
			toast.error(`Please select a plan type`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
				hideProgressBar: true,
			});
			return;
		}

		// Load Razorpay SDK
		const res = await loadScript(
			'https://checkout.razorpay.com/v1/checkout.js'
		);

		if (!res) {
			toast.error(`Razorpay SDK failed to load. Are you online?`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
				hideProgressBar: true,
			});
			return;
		}

		enrollUserToLiveStreamingClass(user?.uid || userData?.uid, {
			...selectedPlanVariant,
			discounted_price: price,
		}).then((updatedUser) => {
			// Handle enrollment success
		});

		// price = 1

		const options = {
			key: 'rzp_live_cJ3YCKf6Ayf4ab', // Replace with your Razorpay key
			amount: price * 100, // Amount in smallest currency unit (here, in paisa for INR)
			currency: 'INR',
			name: 'Seeker plan for live streaming',
			description: 'Daily Live Class',
			image:
				'https://firebasestorage.googleapis.com/v0/b/seek-c3955.appspot.com/o/seek-logo-icon-blue.png?alt=media&token=e73fb5ae-3f19-42d0-afe8-1b76a89cf66f',
			handler: async function (response: any) {
				if (response.razorpay_payment_id) {
					// Payment success
					try {
						Mixpanel.track('view_payment_successful', {
							event_action: 'viewed',
							plan_amount: price,
							plan_name: name,
							phone:
								user?.phoneNumber ||
								user?.phone ||
								userData?.phoneNumber ||
								userData?.phone,
							userId: user?.uid || userData?.uid,
							event_page: 'payment_screen/payment_one',
						});
					} catch (e) {}
					navigate('/plan/payment-successful', {
						state: {
							selectedPlanVariant: selectedPlanVariant,
							prevScreenName: prevScreenName,
							currently_active_variant:
								userPlan?.activeStreamingPlan?.currently_active_variant,
						},
						replace: false,
					});
					dispatch(setReloadPlan(true));
				} else {
					// Payment failure
					setPaymentFailedPopup(true);
					try {
						Mixpanel.track('payment_failure', {
							event_action: 'viewed',
							plan_amount: price,
							plan_name: name,
							phone:
								user?.phoneNumber ||
								user?.phone ||
								userData?.phoneNumber ||
								userData?.phone,
							event_page: 'payment_screen/payment_one',
						});
					} catch (e) {
						// Handle Mixpanel tracking error, if needed
					}
				}
			},
			prefill: {
				name: user?.name || userData?.name,
				email: 'contact@seekapp.co',
				contact:
					user?.phoneNumber ||
					user?.phone ||
					userData?.phoneNumber ||
					userData?.phone ||
					'9959637700',
			},
			theme: { color: COLORS.PRIMARY },
			modal: {
				ondismiss: function (response: any) {
					// Handle modal dismiss
					setPaymentFailedPopup(true);
					try {
						Mixpanel.track('payment_failure', {
							event_action: 'viewed',
							plan_amount: price,
							plan_name: name,
							phone:
								user?.phoneNumber ||
								user?.phone ||
								userData?.phoneNumber ||
								userData?.phone,
							event_page: 'payment_screen/payment_one',
						});
					} catch (e) {
						// Handle Mixpanel tracking error, if needed
					}
				},
			},
		};
		const paymentObject = (window as any).Razorpay(options);
		paymentObject.open();
	};
	return { displayRazorpay };
};

export default useRazorpay;
