import React, {ChangeEvent, useState} from 'react';
import './MasterLoginSheet.css';
import PhoneInput from "../phoneInput/PhoneInput";
import Button from "../button/Button";
import {COLORS} from "../../utils/colors";

interface MasterLoginSheetProps {
    onSubmitMasterLogin: (masterPhone: string) => void,
    onSkip: () => void;
}

const MasterLoginSheet: React.FC<MasterLoginSheetProps> = ({onSubmitMasterLogin, onSkip}) => {
    const [phone, setPhone] = useState<string>('');
    const [error, setError] = useState<string>('');

    const handlePhoneChange = (value: string, country: string, e: ChangeEvent<HTMLInputElement>, formattedValue: string): void => {
        setError('')
        setPhone(value);
    };
    const customInputStyle: React.CSSProperties = {
        // width: '300px',
        marginTop: '10px',
        height: '52px',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid #D6D6D6',
    };
    return (
        <div className={`bottom-sheet-container-master  open`}>
            <div className="master-backdrop" onClick={onSkip}></div>
            <div className="bottom-sheet-master">
                <div className="bottom-sheet-content-master">
                    <div style={{
                        width: '400px',
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <span style={{width: '100%', textAlign: 'center', color: COLORS.PRIMARY, marginLeft: '-36px'}}>Enter user number</span>
                        <span style={{width: '100%', textAlign: 'center', color: COLORS.PRIMARY, marginRight: '-24px'}}
                              onClick={onSkip}>SKIP</span>
                    </div>
                    <PhoneInput
                        phoneNumber={phone}
                        defaultCountry="in"
                        onPhoneChange={handlePhoneChange}
                        inputStyle={customInputStyle}
                    />
                </div>
                <Button isSticky={true} style={{
                    alignSelf: 'center',
                    backgroundColor: COLORS.PRIMARY,
                    marginBottom: '24px'
                }} onClick={onSubmitMasterLogin.bind(null, phone)} label={'Master Access'}></Button>
            </div>
        </div>
    );
};

export default MasterLoginSheet;
