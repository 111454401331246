import React from "react";
import {ReactComponent as BestOfThisClassIcon} from "../../assets/best_of_this_class.svg";

interface Props {
    name: string;
    index: number
}

const BestOutOfItIconWithName: React.FC<Props> = ({name, index}) => {
    if(!name) return null;
    return (
        <div style={{
            width: '95%',
            height: 'auto',
            display: 'flex',
            maxWidth:'354px',
            alignItems: 'center',
            borderRadius: '12px',
            alignSelf: 'center',
            // border: '1.6px solid #E4E4E4',
            marginTop: index === 0 ? '12px' : '8px',
            // background: '#FFF'
        }}>
            <BestOfThisClassIcon width={24} height={24} style={{marginRight: '8px', alignSelf: 'start'}}/>
            <span style={{
                color: 'var(--grey-900, #1A202C)',
                fontFamily: 'Gordita-Regular',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                width:'90%',
                lineHeight: '24px'
            }}>{name}</span>
        </div>
    );
};

export default BestOutOfItIconWithName;
