import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import './QuestionnairePage.css'
import {ReactComponent as BackArrow} from "../../../assets/left_arrow_black.svg";
import signup_question_one from "../../../assets/relaxing-mind.svg"
import signup_question_two from "../../../assets/pilates.svg"
import ProgressBar from "../components/ProgressBar";
import {Mixpanel} from "../../../analytics/Mixpanel";
import {useDispatch, useSelector} from "react-redux";
import {updateUserPreference} from "../../../store/features/AuthSlice";
import {ProgressSteps} from "../../../utils/utils";


interface QuestionnairePageProps {
    onQuestionnairePageContinueHandler: (questionState: any) => void;
    onBackArrowClick: () => void;
    progress: any,
    questionState:any,
    setQuestionState:any,

}



const QuestionnairePage: React.FC<QuestionnairePageProps> = ({
                                                                 questionState,
                                                                 setQuestionState,
                                                                 progress,
                                                                 onQuestionnairePageContinueHandler,
                                                                 onBackArrowClick
                                                             }) => {

    const [index, setIndex] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [index]);

    const dispatch = useDispatch();
    const userPreferences = useSelector((state:any) => state.auth.userPreferences);
    const [yesSelectedIndex0, setYesSelectedIndex0] = useState(userPreferences?.onboarding_questionnaire?.[0]?.option === true);
    const [noSelectedIndex0, setNoSelectedIndex0] = useState(userPreferences?.onboarding_questionnaire?.[0]?.option === false);
    const [yesSelectedIndex1, setYesSelectedIndex1] = useState(userPreferences?.onboarding_questionnaire?.[1]?.option === true);
    const [noSelectedIndex1, setNoSelectedIndex1] = useState(userPreferences?.onboarding_questionnaire?.[1]?.option === false);



    useEffect(()=>{
        if(questionState?.length === 2){
            setIndex(1);
        }
    },[])

    const onBackArrowClickListener = () =>{
        onBackArrowClick()
        // if(index === 1){
        //     decreaseBy11();
        //     setIndex(0);
        // }else if(index ===0){
        //     decreaseBy11();
        //     onBackArrowClick()
        // }
    }


    const onQuestionSelectHandler = (option: boolean) => {
        if (index === 0) {
            if (option) {
                setYesSelectedIndex0(true);
                setNoSelectedIndex0(false);
            } else {
                setYesSelectedIndex0(false);
                setNoSelectedIndex0(true);
            }
        } else if (index === 1) {
            if (option) {
                setYesSelectedIndex1(true);
                setNoSelectedIndex1(false);
            } else {
                setYesSelectedIndex1(false);
                setNoSelectedIndex1(true);
            }
        }

        const updatedQuestions = [...questionState];
        if (index === 0) {
            Mixpanel.track('onboarding_questionnaire_info', {
                event_action: 'clicked',
                page_heading: '1. Knowing your preferences',
                event_page: 'Do you agree with the following statement?',
                question:'Every person is different and there is no one technique of meditation or yoga that suits all.?',
                answer:option,
            });

            // Mixpanel.track('onboarding_questionnaire_info', {
            //     event_action: 'viewed',
            //     page_heading: '1. Knowing your preferences',
            //     event_page: 'Do you agree with the following statement?',
            //     question:'Doing the same practice everyday whether it is a physical or mental exercise is boring & rarely works.?',
            // });


            updatedQuestions[index] = {question: 'Every person is different and there is no one technique of meditation or yoga that suits all.', option: option};
            setQuestionState(updatedQuestions);

            // setTimeout(() => {
            //     setIndex(prevState => prevState + 1);
            //     increaseProgressBy11();
            // }, 1000)
            //

            dispatch(updateUserPreference({ key:'onboarding_questionnaire', value:updatedQuestions}));
            setTimeout(() => {
                onQuestionnairePageContinueHandler(questionState)
            }, 1000)
            // TODO go to the next screen
        }

        // else if (index === 1) {
        //     updatedQuestions[index] = {question: 'Doing the same practice everyday whether it is a physical or mental exercise is boring & rarely works.', option: option};
        //     dispatch(updateUserPreference({ key:'onboarding_questionnaire', value:updatedQuestions}));
        //     setQuestionState(updatedQuestions);
        //     Mixpanel.track('onboarding_questionnaire_info', {
        //         event_action: 'clicked',
        //         page_heading: '1. Knowing your preferences',
        //         event_page: 'Do you agree with the following statement?',
        //         question:'Doing the same practice everyday whether it is a physical or mental exercise is boring & rarely works.?',
        //         answer:option,
        //     });
        //     setTimeout(() => {
        //         // increaseProgressBy4_5();
        //         onQuestionnairePageContinueHandler(questionState)
        //     }, 1000)
        //     // TODO go to the next screen
        // }
    }

    useEffect(()=>{
        Mixpanel.track('onboarding_questionnaire_info', {
            event_action: 'viewed',
            page_heading: '1. Knowing your preferences',
            event_page: 'Do you agree with the following statement?',
            question:'"Every person is different and there is no one technique of meditation or yoga that suits all.?”',
        });
    },[])


    return (
        <Container className={'ques-page-container'}>
            <div className={'ques-header-container'}>
                <BackArrow onClick={onBackArrowClick} width={48} height={48}/>
                <p className={'ques-header-title'}>1.  Knowing your preferences</p>
                <div/>
            </div>
            <div className={'ques-body-container'}>
                <ProgressBar threshold={ProgressSteps.SECTION_ONE} progress={progress}></ProgressBar>
                <p className={'ques-title'}>Do you agree with the following statement?</p>
                {
                    index === 0 && <>
                        <img src={signup_question_one} alt="Your Image"
                             style={{width: '160px', height: '160px',marginTop:'12px'}}/>
                        <p className={'question-on-signup'}>"Every person is different and there is no one technique of meditation or yoga that suits all."</p>
                        <div style={{
                            display: 'flex',
                            width: '327px',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: '24px',
                            justifyContent: 'space-around'
                        }}>
                            <div className={`question-button ${yesSelectedIndex0 ? 'question-button-selected' : ''}`}
                                 onClick={onQuestionSelectHandler.bind(null, true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M19.6275 6.66289C19.2901 6.67036 18.9651 6.79138 18.705 7.00639L9.54446 14.3625L6.44996 10.2345C6.33167 10.077 6.1835 9.94412 6.01392 9.8438C5.84433 9.74347 5.65664 9.67749 5.46156 9.6497C5.26649 9.62192 5.06786 9.63295 4.877 9.68193C4.68614 9.73091 4.5068 9.81688 4.34921 9.93516C4.19163 10.0535 4.05888 10.2017 3.95856 10.3713C3.85823 10.5409 3.7923 10.7285 3.76451 10.9236C3.73672 11.1186 3.74763 11.3174 3.7966 11.5082C3.84558 11.6991 3.93167 11.8783 4.04996 12.0359L8.07296 17.4C8.19362 17.5609 8.34542 17.6959 8.51932 17.797C8.69322 17.8981 8.88568 17.9631 9.08524 17.9883C9.28479 18.0135 9.48738 17.9983 9.68094 17.9436C9.87451 17.889 10.0551 17.7958 10.212 17.6699L20.583 9.34502C20.8313 9.1511 21.0124 8.88388 21.1003 8.58128C21.1882 8.27869 21.1787 7.956 21.0729 7.65916C20.9672 7.36233 20.7706 7.1063 20.5111 6.92747C20.2517 6.74865 19.9425 6.65607 19.6275 6.66289Z"
                                          fill="#11BC59"/>
                                </svg>
                                <p className={'question-select-option'}>{'Yes'}</p>
                            </div>
                            <div className={`question-button ${noSelectedIndex0 ? 'question-button-selected' : ''}`}
                                 onClick={onQuestionSelectHandler.bind(null, false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M8.25587 5.40891C7.95773 5.40924 7.66644 5.49844 7.4192 5.66508C7.17196 5.83171 6.97999 6.06816 6.86779 6.3444C6.75559 6.62063 6.72825 6.92396 6.78927 7.2158C6.85029 7.50763 6.9969 7.77469 7.21037 7.98282L11.2244 11.9969L7.21037 16.0109C7.06711 16.1493 6.95284 16.3148 6.87422 16.4978C6.79561 16.6808 6.75423 16.8776 6.7525 17.0768C6.75077 17.2759 6.78872 17.4734 6.86414 17.6578C6.93956 17.8421 7.05094 18.0096 7.19178 18.1505C7.33262 18.2913 7.5001 18.4026 7.68444 18.4781C7.86879 18.5535 8.06631 18.5915 8.26548 18.5898C8.46464 18.588 8.66147 18.5466 8.84448 18.468C9.02749 18.3894 9.193 18.2751 9.33137 18.1318L13.3454 14.118L17.3624 18.1318C17.5007 18.2751 17.6663 18.3894 17.8493 18.468C18.0323 18.5466 18.2291 18.588 18.4283 18.5898C18.6274 18.5915 18.825 18.5535 19.0093 18.4781C19.1936 18.4026 19.3611 18.2913 19.502 18.1505C19.6428 18.0096 19.7542 17.8421 19.8296 17.6578C19.905 17.4734 19.943 17.2759 19.9412 17.0768C19.9395 16.8776 19.8981 16.6808 19.8195 16.4978C19.7409 16.3148 19.6266 16.1493 19.4834 16.0109L15.4694 11.9969L19.4834 7.98282C19.6998 7.77205 19.8476 7.50097 19.9073 7.20481C19.967 6.90865 19.9359 6.60134 19.818 6.32316C19.7002 6.04497 19.5011 5.80879 19.2468 5.64567C18.9925 5.48254 18.6949 5.40005 18.3929 5.40891C18.0036 5.42041 17.634 5.58286 17.3624 5.86191L13.3454 9.87595L9.33137 5.86191C9.19147 5.71838 9.02421 5.60427 8.83948 5.52646C8.65476 5.44866 8.45632 5.40873 8.25587 5.40891Z"
                                          fill="#EB5757"/>
                                </svg>
                                <p className={'question-select-option'}>{'No'}</p>
                            </div>
                        </div>
                    </>
                }
                {
                    index === 1 && <>
                        <img src={signup_question_two} alt="Your Image"
                             style={{width: '160px', height: '160px'}}/>
                        <p className={'question-on-signup'}>"Doing the same practice everyday whether it is a physical or mental exercise is boring & rarely works."</p>
                        <div style={{
                            display: 'flex',
                            width: '327px',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: '12px',
                            justifyContent: 'space-around'
                        }}>
                            <div className={`question-button ${yesSelectedIndex1 ? 'question-button-selected' : ''}`}
                                 onClick={onQuestionSelectHandler.bind(null, true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M19.6275 6.66289C19.2901 6.67036 18.9651 6.79138 18.705 7.00639L9.54446 14.3625L6.44996 10.2345C6.33167 10.077 6.1835 9.94412 6.01392 9.8438C5.84433 9.74347 5.65664 9.67749 5.46156 9.6497C5.26649 9.62192 5.06786 9.63295 4.877 9.68193C4.68614 9.73091 4.5068 9.81688 4.34921 9.93516C4.19163 10.0535 4.05888 10.2017 3.95856 10.3713C3.85823 10.5409 3.7923 10.7285 3.76451 10.9236C3.73672 11.1186 3.74763 11.3174 3.7966 11.5082C3.84558 11.6991 3.93167 11.8783 4.04996 12.0359L8.07296 17.4C8.19362 17.5609 8.34542 17.6959 8.51932 17.797C8.69322 17.8981 8.88568 17.9631 9.08524 17.9883C9.28479 18.0135 9.48738 17.9983 9.68094 17.9436C9.87451 17.889 10.0551 17.7958 10.212 17.6699L20.583 9.34502C20.8313 9.1511 21.0124 8.88388 21.1003 8.58128C21.1882 8.27869 21.1787 7.956 21.0729 7.65916C20.9672 7.36233 20.7706 7.1063 20.5111 6.92747C20.2517 6.74865 19.9425 6.65607 19.6275 6.66289Z"
                                          fill="#11BC59"/>
                                </svg>
                                <p className={'question-select-option'}>{'Yes'}</p>
                            </div>
                            <div className={`question-button ${noSelectedIndex1 ? 'question-button-selected' : ''}`}
                                 onClick={onQuestionSelectHandler.bind(null, false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M8.25587 5.40891C7.95773 5.40924 7.66644 5.49844 7.4192 5.66508C7.17196 5.83171 6.97999 6.06816 6.86779 6.3444C6.75559 6.62063 6.72825 6.92396 6.78927 7.2158C6.85029 7.50763 6.9969 7.77469 7.21037 7.98282L11.2244 11.9969L7.21037 16.0109C7.06711 16.1493 6.95284 16.3148 6.87422 16.4978C6.79561 16.6808 6.75423 16.8776 6.7525 17.0768C6.75077 17.2759 6.78872 17.4734 6.86414 17.6578C6.93956 17.8421 7.05094 18.0096 7.19178 18.1505C7.33262 18.2913 7.5001 18.4026 7.68444 18.4781C7.86879 18.5535 8.06631 18.5915 8.26548 18.5898C8.46464 18.588 8.66147 18.5466 8.84448 18.468C9.02749 18.3894 9.193 18.2751 9.33137 18.1318L13.3454 14.118L17.3624 18.1318C17.5007 18.2751 17.6663 18.3894 17.8493 18.468C18.0323 18.5466 18.2291 18.588 18.4283 18.5898C18.6274 18.5915 18.825 18.5535 19.0093 18.4781C19.1936 18.4026 19.3611 18.2913 19.502 18.1505C19.6428 18.0096 19.7542 17.8421 19.8296 17.6578C19.905 17.4734 19.943 17.2759 19.9412 17.0768C19.9395 16.8776 19.8981 16.6808 19.8195 16.4978C19.7409 16.3148 19.6266 16.1493 19.4834 16.0109L15.4694 11.9969L19.4834 7.98282C19.6998 7.77205 19.8476 7.50097 19.9073 7.20481C19.967 6.90865 19.9359 6.60134 19.818 6.32316C19.7002 6.04497 19.5011 5.80879 19.2468 5.64567C18.9925 5.48254 18.6949 5.40005 18.3929 5.40891C18.0036 5.42041 17.634 5.58286 17.3624 5.86191L13.3454 9.87595L9.33137 5.86191C9.19147 5.71838 9.02421 5.60427 8.83948 5.52646C8.65476 5.44866 8.45632 5.40873 8.25587 5.40891Z"
                                          fill="#EB5757"/>
                                </svg>
                                <p className={'question-select-option'}>{'No'}</p>
                            </div>
                        </div>
                    </>
                }
            </div>



        </Container>
    );
};

export default QuestionnairePage;
