import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import './ChangeTimeSlots.css'
import {MorningTimeSlots, EveningTimeSlots} from "../../utils/utils";
import {ReactComponent as BackArrow} from "../../assets/arrow-back.svg";
import Button from "../../components/button/Button";

interface IconWithTitleProps {
    slot: string;
    index: number;
    selectedIndex?: number;
    selectOptionHandler: (index: number) => void;
}

const IconWithTitle: React.FC<IconWithTitleProps> = ({slot, index, selectedIndex, selectOptionHandler}) => {
    const isSelected = selectedIndex === index;
    return (
        <div onClick={selectOptionHandler.bind(null, index)}
             className={`slot-icon-with-title ${isSelected ? 'selected-slot-icon-with-title' : ''}`}>
            <div className="title">{slot}</div>
        </div>
    );
};


const ChangeTimeSlotsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [selectedMorningIndex, setSelectedMorningIndex] = useState<number>();
    const [selectedEveningIndex, setSelectedEveningIndex] = useState<number>();

    const selectMorningOptionHandler = (selectedIndex: number) => {
        setSelectedMorningIndex(selectedIndex);
    };
    const selectEveningOptionHandler = (selectedIndex: number) => {
        setSelectedEveningIndex(selectedIndex);
    };


    return (
        <Container className={'slot-page-container'}>
            <div className={'slot-header-container'}>
                <BackArrow onClick={() => {
                    // console.log("back arrow clicked")
                }} width={24} height={24}/>
                <div>Manage Reminders</div>
                {/*<SeekLogo style={{ alignSelf: 'center' }} width={108} height={64} />*/}
                <div/>
            </div>
            <div className={'slot-title'}>What is your preferred time to meditate, we will remind you?</div>
            <div className={'slot-subtitle'}>Morning Batches ☀ (IST))️</div>
            <div className={"slot-body-container"}>
                {MorningTimeSlots?.map((option: any, index: number) => <IconWithTitle
                    selectOptionHandler={selectMorningOptionHandler} selectedIndex={selectedMorningIndex} index={index}
                    slot={option.slot} key={index}/>)}
            </div>
            <div className={'slot-subtitle'} style={{marginTop: '42px'}}>Evening Batches 🌙️ (IST)</div>
            <div className={"slot-body-container"}>
                {EveningTimeSlots?.map((option: any, index: number) => <IconWithTitle
                    selectOptionHandler={selectEveningOptionHandler} selectedIndex={selectedEveningIndex} index={index}
                    slot={option.slot} key={index}/>)}
            </div>

            <div className="slot-bottom-container">
                <div className={'slot-description'}>Don't worry, you can join the live class in any of the above time
                    slots. In fact, <div>20% Seekers</div> join the class multiple times in a day.
                </div>
                <Button isSticky={true} style={{alignSelf: 'center', marginBottom: '24px'}} onClick={() => {
                    console.log("on click slot")
                }} label={'Continue'}></Button>
            </div>
        </Container>
    );
};

export default ChangeTimeSlotsPage;
