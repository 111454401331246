import React, {useEffect, useState} from 'react';
import YouTube from 'react-youtube';
import './YouTubeVideo.css';
import play_icon from "../../assets/play-btn.svg";
import {Mixpanel} from "../../analytics/Mixpanel";

interface YouTubeVideoProps {
    videoId: string | null;
    title: string;
    author: string;
    time: string;
}

const getYouTubeThumbnailUrl = (videoId:any, quality = 'mqdefault') => {
    return `https://img.youtube.com/vi/${videoId}/${quality}.jpg`;
};

const Thumbnail = ({ thumbnailUrl, onClick }: any) => (
    <div onClick={onClick} style={{ position: 'relative', cursor: 'pointer', width: '343px', height: '192px' }}>
        <img width={343} height={192} src={thumbnailUrl} alt="Video Thumbnail" style={{ zIndex: 1 }} />
        <img
            src={play_icon}
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '32px',
                height: '32px',
            }}
            alt="Play Icon"
        />
    </div>
);

const YouTubeVideo: React.FC<YouTubeVideoProps> = ({ videoId, title, author, time }) => {
    const [isVideoReady, setVideoReady] = useState(false);
    const thumbnailUrl = getYouTubeThumbnailUrl(videoId, 'mqdefault');

    const playVideo = () => {
        setVideoReady(true);
        Mixpanel.track('click_seek_archives', {
            event_action: 'clicked',
            activity_Page:'Sample Classes',
            video_title:{
                title,
                author,
                time
            },
        });
    };


    const opts = {
        height: '192',
        width: '343',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    return (
        <div className={'you-tube-container'}>
             {isVideoReady && videoId ? (
                    <YouTube opts={opts}  videoId={videoId} />
                ) : (
                    <Thumbnail thumbnailUrl={thumbnailUrl} onClick={playVideo} />
             )}
            <div className="video-details">
                <div style={{
                    color: '#000',
                    fontFamily: 'Lora-Regular',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '28px',
                }}>{title}</div>
                <div style={{display:'flex',marginTop:'4px',flexDirection:'row',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
                    <div style={{
                        color: '#000',
                        fontFamily: 'Poppins-Regular',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                    }}>{author}</div>
                    <div style={{
                        color: '#000',
                        fontFamily: 'Poppins-Regular',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '18px',
                    }}>{time}</div>
                </div>

            </div>
        </div>
    );
};

export default YouTubeVideo;
