import React, { useCallback, useEffect, useRef, useState } from 'react';
import './CourseCover.css';
import usersIcon from './../../../assets/new-icons/course-users-icon.svg';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../services/Firebase';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Mixpanel } from '../../../analytics/Mixpanel';
import { updateEventsStatus } from '../../../store/features/EventsSlices';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import Shimmer from '../../../components-v2/shimmer/Shimmer';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface CourseCoverProps {
	course: any;
	onClick: () => void;
}

const CourseCover: React.FC<CourseCoverProps> = ({ course, onClick }) => {
	const [coverUrl, setCoverUrl] = useState<string>('');
	const coverUrlRef = useRef<string | null>(null);
	const [loading, setLoading] = useState(true);

	const dispatch = useDispatch();

	const view_upcoming_courses = useSelector(
		(state: any) => state?.events?.eventsLog?.[`view_upcoming_courses_${course?.courseId}`]
	);
	const logViewEvent = useCallback(() => {

		if (!view_upcoming_courses) {
			Mixpanel.track('view_upcoming_courses', {
				event_action: 'viewed',
				event_page: 'courses',
				course_title: course?.title,
				course_id: course?.id,
			});
			dispatch(
				updateEventsStatus({ key: `view_upcoming_courses_${course?.courseId}`, value: true })
			);
		}
	}, [view_upcoming_courses, dispatch, course]);
	const cardRef = useIntersectionObserver(logViewEvent, { threshold: 0.5 });

	useEffect(() => {
		setLoading(true);
		if (!coverUrlRef.current) {
			(async () => {
				try {
					const imageRef = ref(storage, course.batch[0].course_cover);
					const url = await getDownloadURL(imageRef);
					setCoverUrl(url);
					coverUrlRef.current = url;
					setLoading(false);
				} catch (error) {
					setLoading(false);
				}
			})();
		} else {
			setCoverUrl(coverUrlRef.current);
			setLoading(false);
		}
	}, [course]);

	if (loading)
		return (
			<>
				<Shimmer isDark style={{ height: '350px' }} />
			</>
		);

	return (
		<div ref={cardRef} className='course-cover'>
			<LazyLoadImage
				className={'course-cover-image img-fluid'}
				src={coverUrl}
				alt=''
				effect='blur'
				onLoad={() => (
					<Shimmer isDark classname='course-cover-image-placeholder' />
				)}
			/>
			<div className='course-cover-info'>
				<div className='course-cover-info-data'>
					<div className='course-cover-info-data-text'>
						<p className='course-cover-info-data-text-title'>{course?.title}</p>
						<p className='course-cover-info-data-text-subtitle'>
							<img src={usersIcon} alt='' />{' '}
							<span>{course?.batch[0].users_enrolled}+</span> members enrolled
						</p>
					</div>
					<div onClick={onClick} className='course-cover-info-data-button'>
						View Details
					</div>
				</div>
			</div>
		</div>
	);
};

export default CourseCover;
