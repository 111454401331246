import React from 'react';

interface MindFullnessCardProps {
    imageUrl: string;
    name?: string;
    description?: string;
}

const MindFullnessCard: React.FC<MindFullnessCardProps> = ({ imageUrl, name, description }) => {
    return (
        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
           <div style={{ width: '84px', height: '84px', borderRadius: '50%', overflow: 'hidden' }}>
             <img src={imageUrl} alt="Profile Icon" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
           </div>
          <div style={{ color: '#FFF',
              fontFamily: 'Gordita-Bold',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '28px',
              marginTop:'16px',
              textTransform: 'capitalize' }}>{name}</div>
          <div style={{  color: '#FFF',
              textAlign: 'center',
              fontFamily: 'Gordita-Regular',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              width:'80%',
              marginTop:'6px',
              lineHeight: '20px',
              textTransform: 'capitalize'}}>{description}</div>
    </div>
);
};

export default MindFullnessCard;
