import React, { CSSProperties } from 'react';
import {ReactComponent as ErrorIcon} from './../../assets/error-icon.svg';


import './Error.css'

interface ErrorProps {
    message: string;
    customStyles?: CSSProperties;
    onClose?:()=>void;
}

const ErrorComponent: React.FC<ErrorProps> = ({ message, customStyles,onClose }) => {
    const defaultStyles: CSSProperties = {
        color: 'white',
        padding: '12px',
        borderRadius: '8px',
        zIndex: 1000
    };

    const mergedStyles: CSSProperties = { ...defaultStyles, ...customStyles };

    return (
        <div className={'error-container'} style={mergedStyles}>
            <ErrorIcon onClick={onClose} height={36} width={36}/>
            <span style={{paddingLeft:'12px'}}>{message}</span>
        </div>
    );
};

export default ErrorComponent;

