import {createSlice} from "@reduxjs/toolkit";
const initialState = {
    eventsLog:{
        click_on_confirm_email_box:false,
        click_on_email_box:false,
        view_bottom_nav:false,
        view_main_header:false,
        view_upcoming_courses:false,
        view_favourite_classes:false,
        view_upcoming_classes:false,
        view_weekly_leaderboard:false,
        view_post_ob_video:false,
        view_weekly_attendance:false,
        view_plan_banner:false,
        view_manage_whatsapp_reminder:false,
        view_refer_a_friend:false,
        view_account_created_screen:false,
        view_top_card_profile:false,
        view_plan_renewal:false,
        view_course_nudge:false,
        view_session_upcoming_courses:false,
        view_notification_preference_page:false

    }
}


export const eventsSlice = createSlice({
    name:'eventsLog',
    initialState:initialState,
    reducers:{
        updateEventsStatus:(state,action) =>{
            const {key, value} = action.payload;
            state.eventsLog[key] = value;
        }
    }
})

export const {
    updateEventsStatus,
} = eventsSlice.actions;

export default eventsSlice.reducer;


