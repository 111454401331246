import React from 'react';
import { CustomeIconWithTitle } from '../../../components-v2/rows/CourseStructureCard';
import { ReactComponent as DocIcon } from '../../../assets/docs.svg';
import { ReactComponent as PlayIcon } from '../../../assets/new-icons/no-of-class.svg';

interface CourseStructureCardProps {
	title: string;
	name: string;
	no_of_classes: number;
	number_of_self_practices_exercises: number;
	description: string;
}

const CourseStructureCard: React.FC<CourseStructureCardProps> = ({
	title,
	name,
	no_of_classes,
	number_of_self_practices_exercises,
	description,
}) => {
	return (
		<div className='vertical-aligned-component'>
			<span className={'course-title'}>{title}</span>
			<div className='info'>
				<span className='tab-name'>{name}</span>
				<span className='description'>{description}</span>
			</div>
			{/*<CustomeIconWithTitle title="Sample Title" Icon={CustomSVGIcon} />*/}
			<CustomeIconWithTitle
				title={no_of_classes + ''}
				Icon={PlayIcon}></CustomeIconWithTitle>
			<CustomeIconWithTitle
				title={number_of_self_practices_exercises + ''}
				Icon={DocIcon}></CustomeIconWithTitle>
		</div>
	);
};
export default CourseStructureCard;
