import React, { CSSProperties } from 'react';
import {ReactComponent as SuccessIcon} from './../../assets/success-icon.svg';
import './Success.css'

interface SuccessProps {
    message: string;
    customStyles?: CSSProperties;
}

const SuccessComponent: React.FC<SuccessProps> = ({ message, customStyles }) => {
    const defaultStyles: CSSProperties = {
        color: 'white',
        border: '1px solid #ccc',
        padding: '8px',
        borderRadius: '5px',
    };
    const mergedStyles: CSSProperties = { ...defaultStyles, ...customStyles };

    return (
        <div className={'success-container'} style={mergedStyles}>
            <SuccessIcon height={36} width={36}/>
            {message}
        </div>
    );
};

export default SuccessComponent;
