import React from "react";
import './SettingListItem.css'

type SettingsListItemProps = {
    LeftIcon: any; // Type for FontAwesome icon
    RightIcon: any;
    title: string;
    onClick:any;
};

const SettingsListItem: React.FC<SettingsListItemProps> = ({LeftIcon, RightIcon, title,onClick}) => {
    return (
        <div onClick={onClick} className={'setting-list-item-container'}>
            <div className={'horizontal-container'} style={{display:'flex',alignSelf:'center',alignItems:'center',justifyContent:'flex-start'}}>
                <LeftIcon/>
                <span className={'setting-list-item-title'}>{title}</span>
            </div>
            <RightIcon/>
        </div>
    );
};

export default SettingsListItem;
