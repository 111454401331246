import React, { useState, useEffect, useRef } from 'react';
import './Timer.css';

interface TimerProps {
	initialTime: number;
	timerStyles?: React.CSSProperties;
	onTimerComplete?: () => void;
}

const Timer: React.FC<TimerProps> = ({
	initialTime,
	timerStyles,
	onTimerComplete,
}) => {
	const [timeLeft, setTimeLeft] = useState(initialTime);
	const onTimerCompleteRef = useRef(onTimerComplete);

	useEffect(() => {
		onTimerCompleteRef.current = onTimerComplete;
	}, [onTimerComplete]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
		}, 1000);

		if (timeLeft === 0 && onTimerCompleteRef.current) {
			// console.log("onTimerComplete")
			onTimerCompleteRef.current();
		}

		return () => {
			clearInterval(intervalId);
		};
	}, [timeLeft, onTimerComplete]);

	// const formattedTime = new Date(timeLeft * 1000).toISOString().substr(14, 5);
	const formattedTime = timeLeft.toString();

	return (
		<span
			style={{
				color: '#2A2A2A',
				textAlign: 'center',
				fontFamily: 'Gordita-Bold',
				fontSize: '12px',
				fontStyle: 'normal',
				fontWeight: 600,
				marginLeft: '8px',
				textDecoration: 'none',
				lineHeight: 'normal',
			}}>
			{formattedTime} sec
		</span>
	);
};

export default Timer;
