import React from 'react';

interface CarouselItemProps {
    imgUrl: string;
    title?: string;
    subtitle?: string;
}

const HabitCard: React.FC<CarouselItemProps> = ({ imgUrl, title, subtitle }) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <img
                src={imgUrl}
                alt={`Slide ${title}`}
                style={{ width: '200px', height: '190px',marginTop:'16px' }}
            />
            <p style={{
                textAlign: 'center',
                fontFamily: 'Lora-Medium',
                fontSize: '16px',
                fontWeight: 500,
                fontStyle: 'normal',
                alignSelf: 'stretch',
                lineHeight: '24px',
            }}>{title}</p>
            <p style={{
                color: '#3A3A3A',
                textAlign: 'center',
                fontFamily: 'Lora-Regular',
                fontSize: '14px',
                marginTop: '8px',
                marginLeft: '12px',
                marginRight: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                textTransform: 'capitalize',
            }}>{subtitle}</p>
        </div>
    );
};

export default HabitCard;
