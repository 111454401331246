import React, { ChangeEvent, useEffect, useState } from 'react';
import './CourseRegistrationForm.css';
import '../../../styles.css';
import { ReactComponent as BackArrow } from '../../../assets/left_arrow_black.svg';
import { ReactComponent as SeekLogo } from '../../../assets/seek-logo.svg';
import { useNavigate } from 'react-router-dom';
import { RecaptchaVerifier } from 'firebase/auth';
import Button from '../../../components/button/Button';
import { COLORS } from '../../../utils/colors';
import PhoneInput from '../../../components/phoneInput/PhoneInput';
import TextInput from '../../../components/textInput/TextInput';
import ErrorComponent from '../../../components/error/Error';
import Loader from '../../../components/loader/Loader';
import { Mixpanel } from '../../../analytics/Mixpanel';

interface SignUpFormProps {
	onFormSubmitHandler: (fullName: string, phoneNumber: string) => void;
	onBackArrowClick: () => void;
	user: any;
	setState: any;
	loading: boolean;
	name?: string;
	phone?: string;
	course_name: string;
}

interface WindowWithRecaptchaVerifier extends Window {
	recaptchaVerifier?: RecaptchaVerifier;
	confirmationResult?: any;
}

declare let window: WindowWithRecaptchaVerifier;

const CourseRegistrationForm: React.FC<SignUpFormProps> = ({
	onFormSubmitHandler,
	course_name,
	user,
	loading,
	onBackArrowClick,
	name,
	phone,
	// proceedRegistrationWithUserExist
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [fullName, setFullName] = useState<string>(name || '');
	const [error, setError] = useState<string | null>(null);
	const [phoneNumber, setPhoneNumber] = useState<string>(phone || '');
	const [loader, setLoader] = useState(false);
	const navigate = useNavigate();
	const userString = window.localStorage.getItem('user');
	useEffect(() => {
		setLoader(true);
		if (userString) {
			navigate('/home');
			setLoader(false);
		} else {
			setLoader(false);
		}
	}, [userString]);

	const handleFullNameChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setFullName(event.target.value);
	};

	const customInputStyle: React.CSSProperties = {
		// width: '300px',
		marginTop: '10px',
	};

	const handlePhoneChange = (
		value: string,
		country: string,
		e: ChangeEvent<HTMLInputElement>,
		formattedValue: string
	): void => {
		setPhoneNumber(value);
	};

	const customDropdownStyle: React.CSSProperties = {
		// Add your custom dropdown styles here if needed
	};

	const onFormClickHandler = async () => {
		if (!fullName || fullName?.length === 0) {
			setError('Please enter your details.');
			return;
		} else if (phoneNumber === '' || phoneNumber.length < 10) {
			setError('Please enter a valid mobile number.');
			return;
		}
		setLoader(true);
		Mixpanel.track('click_proceed_further_from_register_with_seek', {
			event_action: 'clicked',
			name: fullName,
			phone: phoneNumber,
			course_name: course_name,
			event_page: 'register_with_seek',
		});
		onFormSubmitHandler(fullName, phoneNumber);
	};

	// const onSubmitHandler = async () => {
	//     if (!fullName || fullName?.length === 0) {
	//         setError('Not a valid name')
	//         return;
	//     } else if (phoneNumber === "" || phoneNumber.length < 10) {
	//         setError('Not a valid Mobile Number')
	//         return;
	//     }
	//     setLoader(true)
	//     try {
	//         const checkUserExistOrNotResponse = await checkexistinguserforcourse(phoneNumber);
	//         console.log(checkUserExistOrNotResponse)
	//
	//         if (checkUserExistOrNotResponse?.status === 409 && checkUserExistOrNotResponse?.message === 'User already exist. Login user') {
	//             //TODO user exist show otp screen and login user
	//             console.log("user exist show otp screen and login user", checkUserExistOrNotResponse)
	//             Mixpanel.identify(checkUserExistOrNotResponse.uid);
	//             proceedRegistrationWithUserExist(checkUserExistOrNotResponse?.uid, checkUserExistOrNotResponse?.user);
	//             setLoader(false)
	//             return;
	//         } else if (checkUserExistOrNotResponse?.status === 404) {
	//             //TODO user doesn't exist show otp screen and signup user
	//             console.log("user doesn't exist show otp screen and signup user", checkUserExistOrNotResponse)
	//             // proceedRegistrationWithUserExist(checkUserExistOrNotResponse?.uid)
	//             const nameArray = fullName.split(' ');
	//             const firstName = nameArray[0];
	//             const lastName = nameArray.slice(1).join(' ');
	//             setLoader(false);
	//
	//             onFormSubmitHandler(
	//                 {
	//                     name: fullName,
	//                     firstName,
	//                     lastName,
	//                     // email,
	//                     phone: phoneNumber,
	//                 }
	//             )
	//         }
	//
	//     } catch (e: any) {
	//         setLoader(false)
	//         // console.log("checkUserExistOrNotResponse err",e.message());
	//     }
	// }

	const closeErrorPopup = () => {
		setError('');
	};

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);

	return (
		<div className={'course-registration-container'}>
			{(loader || loading) && <Loader />}
			{error && (
				<ErrorComponent
					onClose={closeErrorPopup}
					customStyles={{
						position: 'fixed',
						justifyContent: 'flex-start',
						alignItems: 'center',
						top: 10,
						left: 10,
						right: 10,
					}}
					message={error}
				/>
			)}

			<div className={'course-registration-header-container'}>
				<BackArrow width={48} height={48} onClick={onBackArrowClick} />
			</div>

			<div style={{ alignSelf: 'center', marginTop: '12px' }}>
				<SeekLogo
					style={{ alignSelf: 'center', width: '100%' }}
					width={92}
					height={48}
				/>
				<div className={'course-registration-title'}>Register with Seek</div>
				{/*<div className={'course-registration-subtitle'}>Please fill the details to get a 6 digit verification*/}
				{/*    code.*/}
				{/*</div>*/}
			</div>

			<div className={'course-registration-form-title margin-top-30px'}>
				Full Name
			</div>
			<TextInput
				value={fullName}
				onChange={handleFullNameChange}
				placeholder='Enter your full name'
				// style={customInputStyle}
			/>

			<div className={'course-registration-form-title margin-top-20px'}>
				WhatsApp Number
			</div>
			<PhoneInput
				defaultCountry='in'
				onPhoneChange={handlePhoneChange}
				phoneNumber={phoneNumber}
				inputStyle={customInputStyle}
				dropdownStyle={customDropdownStyle}
			/>

			<Button
				isSticky={true}
				disabled={loader}
				style={{
					alignSelf: 'center',
					backgroundColor: loader ? COLORS.LIGHT_GREY : COLORS.PRIMARY,
					marginBottom: '64px',
				}}
				onClick={onFormClickHandler}
				label={'Continue'}></Button>
		</div>
	);
};

export default CourseRegistrationForm;
