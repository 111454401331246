import React from 'react'
// import {ReactComponent as ReferralBanner} from "../../../assets/referral-page-image.svg";

import referralBannerImage from "../../../assets/webp-images/referral-page-image.webp"
import './EmptyReferralPage.css'

const  EmptyReferralPage = () => {
    return (
        <div className={'empty-referral-page-container'}>
            <img
                src={referralBannerImage}
                alt="Referral Banner"
                style={{ marginTop: '16px', height: '320px', width: '320px' }}
            />
            <div className={'empty-referral-page-title'}>Refer your Friend!</div>
            <div className={'empty-referral-page-subtitle'}>Looks like you have not referred anyone yet. Let’s add your friends in this meditation journey with you.</div>
        </div>
    )
}

export default EmptyReferralPage
