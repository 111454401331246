import React from 'react';
import Modal from "../../components/model/Model";
import {ReactComponent as PaymentFailed} from "../../assets/alert.svg";
import Button from "../../components/button/Button";
import {COLORS} from "../../utils/colors";
import './Model.css'

interface PlanVariant {
    name: string;
    total_price: number;
}

interface PaymentFailedModalProps {
    paymentFailedPopup: boolean;
    setPaymentFailedPopup: (show: boolean) => void;
    selectedPlanVariant: PlanVariant;
    displayRazorpay: (price: number, name: string) => void;
}

const PaymentFailedModal: React.FC<PaymentFailedModalProps> = ({
                                                                   paymentFailedPopup,
                                                                   setPaymentFailedPopup,
                                                                   selectedPlanVariant,
                                                                   displayRazorpay,
                                                               }) => {
    return (
        <Modal
            show={paymentFailedPopup}
            showHeader={false}
            onHide={() => setPaymentFailedPopup(false)}
            title={''}
            content={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <PaymentFailed width={120} height={120} />
                    <span className={'payment-success-hurray'}>Payment Failed ❌</span>
                    <span className={'payment-success-plan-description'}>
            {`Your payment of INR ${selectedPlanVariant?.total_price} has failed due to some technical reasons.`}
          </span>
                    <Button
                        style={{ alignSelf: 'center', backgroundColor: COLORS.PRIMARY, marginTop: '20px' }}
                        onClick={displayRazorpay.bind(null,selectedPlanVariant?.total_price, selectedPlanVariant?.name)}
                        label={'Retry Payment'}
                    />
                </div>
            }
        />
    );
};

export default PaymentFailedModal;
