import React, { useState } from 'react';
import YouTube from 'react-youtube';
import './InstructorFeatures.css';
import play_icon from '../../../assets/play-btn.svg';
import { Mixpanel } from '../../../analytics/Mixpanel';

interface YouTubeVideoProps {
	videoId: string | null;
}

const getYouTubeThumbnailUrl = (videoId: any, quality = 'mqdefault') => {
	return `https://img.youtube.com/vi/${videoId}/${quality}.jpg`;
};

const Thumbnail = ({ thumbnailUrl, onClick }: any) => (
	<div
		onClick={onClick}
		style={{
			position: 'relative',
			cursor: 'pointer',
			width: '343px',
			height: '192px',
		}}>
		<img
			width={343}
			height={192}
			src={thumbnailUrl}
			alt='Video Thumbnail'
			style={{ zIndex: 1 }}
		/>
		<img
			src={play_icon}
			style={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				width: '32px',
				height: '32px',
			}}
			alt='Play Icon'
		/>
	</div>
);

const InstructorFeatures: React.FC<YouTubeVideoProps> = ({ videoId }) => {
	const [isVideoReady, setVideoReady] = useState(false);
	const thumbnailUrl = getYouTubeThumbnailUrl(videoId, 'mqdefault');

	const playVideo = () => {
		setVideoReady(true);
		Mixpanel.track('click_instructor_recognition', {
			event_action: 'clicked',
			activity_Page: 'Course Landing Page',
		});
	};

	const opts = {
		height: '192',
		width: '343',
		playerVars: {
			autoplay: 1,
		},
	};

	return (
		<div className={'you-tube-container'}>
			{isVideoReady && videoId ? (
				<YouTube opts={opts} videoId={videoId} />
			) : (
				<Thumbnail thumbnailUrl={thumbnailUrl} onClick={playVideo} />
			)}
		</div>
	);
};

export default InstructorFeatures;
