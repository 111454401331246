import React from 'react';

import './TileGalleryVideo.css';

interface TileGalleryVideoProps {
	video: string;
	text: string;
}

const TileGalleryVideo: React.FC<TileGalleryVideoProps> = ({ video, text }) => {
	return (
		<div className='tile-gallery-video'>
			<video
				src={video}
				autoPlay
				playsInline
				loop
				muted
				controls={false}></video>
			<div className='text'>
				<p>{text}</p>
			</div>
		</div>
	);
};

export default TileGalleryVideo;
