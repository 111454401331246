import React, {memo} from 'react';
import './KarmaPointsRow.css';
import {ReactComponent as SilverBadge} from "../../../assets/silver_badge.svg";

interface KarmaPointsRowProps {
    date: string;
    points: number;
}

const KarmaPointsRow: React.FC<KarmaPointsRowProps> = ({ date, points }) => {
    return (
        <div className="karma-points-row">
            <span className="karma-date">{date}</span>
            <div style={{display:"flex"}}>
                <SilverBadge width={32} height={32}/>
                <span className="karma-points-value">{points} <span className="karma-points-surname"> Karma</span></span>
            </div>

        </div>
    );
};

export default memo(KarmaPointsRow);
